
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.no-resource-registered {
  padding: 24px;
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  color: $gray-3;
  font-size: 14px;
}

.no-resource-registered-icon {
  user-select: none;
  margin-bottom: 8px;
  height: 78px;
  width: 89px;
}

