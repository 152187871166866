
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-popover {
  padding: 16px;
  width: 409px;

  .loading {
    width: 409
  }

  .erred {
    font-size: 16px;
  }

  .manufacturer-icon {
    margin-right: 16px;
  }

  .content {
    width: 329px;

    .header {
      margin-bottom: 8px;

      .titles {
        overflow: hidden;
        font-family: $secondary-font;

        .title {
          font-weight: 500;
          font-size: 20px;
        }

        .subtitle {
          font-weight: 400;
          font-size: 18px;
          color: $gray-3;
        }
      }

      .plate {
        margin-left: 4px;
      }
    }

    .info-list {

      > .info + .info {
        margin: 4px 0;
      }

      .category-tag {}

      .fipe-tag {
        ::v-deep label {
          padding-left: 0px;
        }
      }

      .icon-container {
        width: 14px;
        height: 14px;
        margin-right: 8px;

        .icon {
          font-size: 12px;
          color: $gray-3;
        }
      }

      .km {
        font-family: $primary-monospace-font;
        font-weight: 400;
        font-size: 14px;
      }

      .color {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

