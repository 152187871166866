
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.attachment-card {
  .container {
    background-color: $light-gray;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    width: 212px;
    height: 140px;
    overflow: hidden;

    &.uploaded {
      border-top-right-radius: 24px;

      &:hover .content {
        transform: translateY(-48px);
      }
    }

    .content {
      transition: transform .2s ease;
      background-color: $light-gray;
      padding: 8px 16px;
      font-family: $secondary-font;
      font-weight: 400;

      .name-container {
        height: 34px;
        margin-bottom: 8px;

        .name {
          font-size: 14px;
          font-weight: 400;
          color: $dark-gray;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          letter-spacing: 0.3px;

          &.download:hover {
            cursor: pointer;
            color: $orange;
          }
        }
      }

      .size {
        font-size: 12px;
        color: $gray-3;
      }

      .checking {
        margin-top: 10px;
      }

      .progress-bar {
        width: 100%;
        height: 8px;
        background-color: $light-gray-4;
        border-radius: 4px;
        overflow: hidden;
        margin-top: 10px;

        .progress {
          width: var(--progress);
          height: 100%;
          background-color: $orange;
        }
      }

      .actions {
        margin-top: 8px;

        .action {
          cursor: pointer;
          --size: 32px;
          width: var(--size);
          height: var(--size);
          border-radius: var(--size);
          background-color: $white;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

          & + .action {
            margin-left: 8px;
          }

          &.downloading {
            cursor: progress;
            color: $gray-2;
          }
        }
      }
    }
  }

  .description {
    margin-top: 8px;
    width: 212px;
    font-size: 14px;
    font-weight: 300;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

