$height: 40px;

// Label
// ===========================
.label-content {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-family: $secondary-font;
  color: $dark-gray;
  transition: color 0.1s cubic-bezier(.645,.045,.355,1);

  > * + * {
    padding-left: 4px;
  }

  &.focus {
    color: $orange;
  }

  &.error {
    color: $error;
  }
}

.info-icon {
  font-size: 14px;
  cursor: pointer;
}

// ===========================


// Input
// ===========================
.input-wrapper {
  position: relative;
  font-size: 16px;
  display: inline-block;
  width: 100%;
}

.input-inner {
  font-family: $primary-font;
  appearance: none;
  background-color: $white;
  background-image: none;
  border-radius: 4px;
  border: 1px solid $gray;
  box-sizing: border-box;
  color: $gray-3;
  display: inline-block;
  font-size: inherit;
  height: $height;
  line-height: $height;
  outline: none;
  padding: 1px 13px;

  width: 100%;

  &.icon-right {
    padding-right: 40px;

    &:focus {
      padding-right: 39px;
    }
  }

  &.icon-left {
    padding-left: 40px;

    &:focus {
      padding-left: 39px;
    }
  }

  &:hover {
    border-color: $orange;
  }

  &:focus {
    outline: none;
    border-color: $orange;
    border-width: 2px;
    padding: 0 12px;
  }

  &::placeholder {
    font-weight: 300;
    color: $gray-2;
    opacity: 1;
  }
}

// Disabled
.input-inner.disabled {
  background-color: $light-gray-3;
  border-color: $light-gray-4;
  color: $gray-2;
  cursor: not-allowed;
}

// Loading
.input-inner.loading {
  background-color: $light-gray-3;
  border-color: $light-gray-4;
  color: $gray;
  cursor: progress;
}

// Error
.input-inner.error {
  border-color: $error;
}
// ============================


// Icon
// ===========================
.icon {
  position: absolute;
  font-size: 16px;
  top: calc(#{$height} / 2);
  transform: translateY(-50%);
  color: $gray-4;

  &.right {
    right: 12px;
  }

  &.left {
    left: 12px;
  }

  &.clickable {
    cursor: pointer;
  }
}
// ============================


// Error Message
// ===========================
.error-message {
  display: block;
  color: $error;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
