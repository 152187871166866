
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


// XXX: hack para que o v-popover não reduza o tamanho dos filhos.
// sem isso, o campo select-field não vai ocupar 100% da coluna (pai).
::v-deep .v-popover > .trigger {
  width: 100%;
}

.reference-table {
  padding: 14px;

  .title {
    display: block;
    padding: 0px 12px;
    padding-bottom: 8px;

    margin: 0 12px;
    margin-bottom: 8px;
    border-bottom: 1px solid $light-gray-3;

    font-family: $secondary-font;
    font-size:   18px;
    font-weight: 500;
    color: $dark-gray;
  }

  .reference-table-data {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    width: 600px;
    max-height: 230px;

    padding: 4px 20px;

    font-size: 14px;

    .item {
      padding: 2px 4px;
    }

    .value {
      font-family: $primary-monospace-font;
      font-weight: 500;
      padding-right: 4px;
    }

    .label {
      font-family: $primary-font;
      font-weight: 300;
    }
  }
}

