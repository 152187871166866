
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.action {
  color: $gray-2;
  font-size: 14px;
}

.header {
  .heading {
    display: inline-block;

    font-size: 18px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-family: $secondary-font;
  }

  .icon {
    color: $dark-gray;
    font-size: 20px;
  }
}


.body {
  .info:not(:last-of-type) {
    margin-right: 24px;
  }

  .section {
    padding: 16px 24px;
  }

  .section + .section {
    border-top: 1px solid $light-gray-3;
  }
}

.date {
  width: 280px;
}

.icon {
  margin-right: 8px;
}

.overlap-error {
  background-color: $light-warning;
  border-radius: 4px;
  margin-top: 24px;
  padding: 4px 8px;
  color: $dark-warning;
  font-size: 14px;

  .message {
    font-family: $secondary-font;
    font-weight: 500;
  }
}

