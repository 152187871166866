
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.content-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &.authenticated {
    height: calc(100% - #{$navbar-height});
  }
}

#content {
  width: 100%;
  padding: 40px;
  overflow: auto;

  > .view-context,
  > .view {
    max-width: 1200px;
    margin: 0 auto;
  }
}

