
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.movida-modal {
	
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 995;

	.mb-4 {
		margin-bottom: 30px;
	}
	
	.backdrop {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .8);
	}
	
	.container {
		position: relative;
		padding: 30px;
		width: 50%;
		min-width: 300px;
		min-height: 100px;
		height: auto;
		background: #fff;
		border-radius: 4px;
		box-shadow: rgba(0, 0, 0, .85) 0 0 10px;
		box-sizing: border-box;
		
		hr {
			border: none;
			border-bottom: #eee 1px solid;
			display: block;
			margin: 25px 0;
		}
		
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 20px;
			height: 20px;
			cursor: pointer;
			color: #333;
			background-size: cover;
		}
		
		.form-row {
			margin-bottom: 15px;
		}
		
		.loader-icon {
			margin: 0 20px;
		}
	}
}
