
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.not-found {
  min-height: 100%;
  width: 100%;
  background: $white;
}

.img {
  margin-top: 10vh;
  margin-bottom: 32px;
  width: 70%;

  @media only screen and (min-width: 1600px) {
    width: 1200px;
  }
}

.button-content {
  display: block;
  width: 200px;
  padding-bottom: 20px;
}

