
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


svg {
  width: 32px;
  height: 14px;
}

.outside {
  fill: $orange;
}

.inside {
  fill: $white;
}

