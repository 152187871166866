
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.button-icon {
  font-size: 20px;
}

.remove-button {
  margin-left: 6px;
  color: $gray-2;

  &:hover {
    color: $gray-3;
  }
}

.day-label {
  min-width: 130px;
}

.time-field + .time-field {
  margin-left: 4px;
}

.time-field {
  width: 80px;
}

.button-content {
  width: 26px;
}

