
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-contacts {
  .title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
    font-family: $secondary-font;
    font-weight: 500;
  }

  .app-list-header {
    margin-top: 8px;
  }

  .empty {
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    padding: 20px;

    &.no-filters {
      margin-top: 41px;
    }

    img {
      width: 92px;
      height: 86px;
    }

    .message {
      font-size: 14px;
      margin-top: 14px;
      color: $gray-3;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border: 1px solid $light-gray-3;
    border-collapse: collapse;
    // border-radius: 4px; // XXX não funciona com border-collapse: collapse

    table tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }

    table tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }

    th {
      font-size: 14px;
      font-weight: 500;
      font-family: $secondary-font;
      color: $dark-gray;
      height: 48px;
      background: $light-gray-2;

      .label > *:not(:last-child) {
        margin-right: 8px;
      }
    }

    .icon {
      font-size: 16px;
    }

    .name-column .label {
      margin-left: 16px;
    }

    .email-column {
      width: 390px;
    }

    .phone-column {
      width: 260px;
    }

    .action-column {
      width: 48px;
    }
  }
}

