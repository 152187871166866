
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.show {
  padding-bottom: 24px;

  .header {
    padding-top: 18px;

    .discarded-icon {
      margin-right: 8px;
    }

    .name {
      font-size: 30px;
      font-weight: bold;
      word-break: normal;
      overflow-wrap: break-word;
    }

    .id-tag {
      margin: 0 8px;
    }

    .actions {
      .btn + .btn {
        margin-left: 8px;
      }

      .icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }

    .description {
      display: block;
      padding-top: 8px;
    }
  }

  .tabs {
    margin-bottom: 24px;
  }

  .section + .section {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $light-gray-3;
  }
}

