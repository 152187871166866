
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-issue-row {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  height: 70px;

  & + .vehicle-issue-row {
    border-top: 1px solid transparent;
  }

  .id-column {
    width: 60px;
    margin-right: 24px;
  }

  .content-column {
    overflow: hidden;

    .vehicle-part,
    .symptom {
      font-family: $secondary-font;
      font-size: 18px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      margin-right: 8px;
    }

    .diamond-icon {
      margin: 0 8px;
      font-size: 4px;
      color: $gray-3;
    }
  }
}

