
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-row {
  display: grid;
  grid-template-columns: var(--grid-columns);
  padding: 8px 0;
  border-bottom: 1px solid $light-gray-3;
  min-height: 64px;

  &.loading {
    .column {
      .text,
      .empty,
      .service-order,
      .small-text,
      .icon-container .icon {
        color: $light-gray-4;
      }

      .icon-container.big.action {
        cursor: progress;

        &:hover,
        &:active,
        &:focus {
          background-color: unset;

          .icon {
            color: $light-gray-4;
          }
        }

        .icon {
          color: $light-gray-4;
        }
      }
    }
  }

  .column {
    padding: 0 12px;
    overflow: hidden;

    .icon-container {
      position: relative;

      &.big {
        width:  40px;
        height: 40px;

        &.action {
          cursor: pointer;

          &:hover {
            background-color: $light-gray-2;
            border-radius: 4px;

            .icon {
              color: $dark-orange;
            }
          }

          &:active,
          &:focus {
            background-color: $light-gray-3;
            border-radius: 4px;

            .icon {
              color: $dark-orange-2;
            }
          }

          .icon {
            color: $orange;
          }

          &.loading {
            cursor: progress;

            &:hover,
            &:active,
            &:focus {
              background-color: unset;

              .icon {
                color: $light-gray-4;
              }
            }

            .icon {
              color: $light-gray-4;
            }
          }
        }
      }

      .icon {
        color: $gray-2;
      }

      .empty-icon {
        font-size: 12px;
      }

      .at-supplier,
      .issued {
        color: $purple;
      }

      .changes {
        color: $dark-warning;
      }

      .reviewed {
        color: $info;
      }
    }

    &.service-order-column {
      > * + * {
        margin-top: 4px;
      }
    }

    .id {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
    }

    .icon.complementary {
      color: $gray-2;
      margin-right: 6px;
      font-size: 12px;
    }

    .text {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;

      &.word-break {
        word-break: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .state {
      margin-top: 4px;
      width: 85px;
    }

    &.vehicle-column {
      .tags {
        margin-top: 6px;
      }

      .license-plate {
      }

      .vehicle-category-tag {
        margin-top: 4px;
      }
    }

    .diamond-icon {
      font-size: 4px;
      color: $gray-3;
      margin: 0 4px;
    }

    .empty {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }

    .small-text {
      font-size: 12px;
      font-weight: 400;
      color: $gray-3;

      &.word-break {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .address {
      margin-top: 4px;
    }
  }

  .service-order-column,
  .vehicle-column,
  .supplier-column {
    cursor: pointer;
  }

  .counter-badge {
    position: absolute;
    top:   2px;
    right: 2px;

    background-color: $purple;
    padding: 2px 4px;
    border: 1px solid $white;
    border-radius: 6px;

    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;

    color: $white;
  }

}

