
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.rich-textarea-field {
  .label-content {
    font-size: 14px;
    font-weight: 500;
    font-family: $secondary-font;
    margin-bottom: 8px;

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }
  }

  .input {
    border: 1px solid $gray;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;

    &:hover {
      border-color: $orange;
    }

    &:focus-within {
      border-color: $orange;
      border-width: 2px;
    }

    &.error {
      border-color: $error;
    }

    .trix-content {
      height: 100%;
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

