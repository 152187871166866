
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.table-header {
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;
  padding: 0 12px;

  .column {
    font-size: 12px;

    .all-label {
      color: $gray-2;
      margin-left: 16px;

      &.active {
        color: $dark-gray;
        font-weight: 500;
      }
    }
    .selected {
      margin-right: auto;
    }

    &.checkbox-column {
      margin-right: 16px;
    }

    &.selected-column {
      margin-right: auto;
    }

    .toggle-button {
      margin-right: 24px;
      font-family: $secondary-font;
      font-size: 14px;
      color: $gray-2;
      font-weight: normal;

      .icon {
        margin-right: 8px;
      }

      &.active {
        color: $dark-gray;
        font-weight: 400;
      }
    }

    .label {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 500;
    }

    .selected-badge {
      margin-left: 8px;
      height: 14px;
      padding: 0 5px;
      border-radius: 12px;
      border: 1px solid $light-gray-3;

      .badge-label {
        vertical-align: super;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

