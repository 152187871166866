
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-package-option {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;
  min-height: initial;
  line-height: initial;

  * {
    cursor: pointer;
  }

  .remove-icon-content {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;
    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.id-tag-column {
  min-width: 72px;
}

.name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;

  &.active {
    color: $primary;
  }

  &.focus {
    color: $dark-primary-2;
  }
}

.description {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 700px;
}

.count-column + .count-column {
  margin-left: 16px;
}

.count-column {
  display: block;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  color: $gray-4;
}

.icon {
  margin-right: 8px;
  color: $gray-2;
}

.content-column {
  flex-grow: 1;
}

.actions-column {
  min-width: 42px;
  margin-left: 12px;
}
