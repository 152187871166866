
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.heading {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 400;
}

.supplier-deal-products-data-load {
  padding: 24px;

  .action-link {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    color: $dark-gray;

    &:hover {
      color: $gray-3;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .back {
    padding: 4px 0;
  }

  .row + .row {
    padding-top: 16px;
    border-top: 1px solid $light-gray-3;
  }

  .top-row,
  .middle-row {
    height: 54px;
  }

  .top-row {
    .file-icon {
      width: 24px;
      height: 24px;
      margin-right: 24px;

      .icon {
        font-size: 24px;
      }
    }

    .name {
      font-family: $secondary-font;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .download {
      padding: 0 8px;
    }
  }

  .middle-row {
    .info {
      margin-right: 24px;

      .icon {
        margin-right: 8px;
        color: $gray-3;
      }
    }
  }

  .bottom-row {
    .status {
      .icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        margin-right: 16px;

        &.success {
          color: $dark-success;
        }

        &.error {
          color: $error;
        }
      }
    }

    .error-messages {
      background-color: $light-gray;
      border-radius: 4px;
      margin-top: 16px;
      font-weight: 400;
      padding: 12px;
      color: $error;
      max-height: 400px;
      overflow-y: auto;

      .error-message {
        .icon {
          font-size: 4px;
          margin: 0 8px;
        }
      }

      .error-line + .error-line {
        margin-top: 8px;
      }
    }
  }
}

