
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-model-row {
  padding: 12px;
  cursor: pointer;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  line-height: initial;

  * {
    cursor: pointer;
  }

  & + .vehicle-model-row {
    border-top: 1px solid transparent;
  }

  .content-column {
    overflow: hidden;
    padding-right: 12px;
    font-size: 14px;
    font-weight: 400;

    .diamond-icon {
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
      width: 4px;
      height: 4px;
      margin: 4px;
    }
  }

  .actions-column {
    min-width: 42px;
    margin-left: 12px;

    .remove-icon-content {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .actions-column {
      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }
}

