
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.row + .row {
  padding-top: 24px;
}

.section {
  border-bottom: 1px solid $light-gray-3;
  padding-bottom: 24px;
}

.label {
  display: block;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 4px;;
}

.name-preview  {
  .name {
    margin: 0;
    font-size: 24px;
    font-weight: normal;

    .unavailable {
      font-style: italic;
      color: $gray-3;
    }
  }
}

.kind-field {
  margin-top: 34px;
}

