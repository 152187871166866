
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-supports {
  .header {
    margin-top: 24px;
    margin-bottom: 8px;

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .cards {
    .card {
      margin-right: 24px;
      margin-top: 24px;
    }
  }
}

