
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.loading {
  align-items: center;
  color: white;
  display: flex;
  font-size: 32px;
  height: 100%;
  justify-content: center;
}
