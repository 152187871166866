
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-row {
  padding: 12px;
  color: $dark-gray;

  .position-badge {
    height: 24px;
    width: 24px;
    border-radius: 80px;
    background-color: $light-gray-3;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .position {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
  }

  .content {
    overflow: hidden;

    .supplier-info {
      overflow: hidden;

      .header {
        margin-bottom: 8px;

        .best-supplier-badge {
          margin-bottom: 8px;
        }

        .trading-name {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 20px;
          line-height: 19px;
        }

        .address-and-hierarchy {
          font-size: 14px;

          .hierarchy-icon {
            margin-right: 4px;

            ::v-deep .icon {
              font-size: 14px;
            }
          }

          .address {
            overflow: hidden;
          }
        }
      }
    }

    .icon {
      color: $gray-3;
      font-size: 16px;
      font-weight: 900;
      line-height: 16px;
      margin-right: 8px;
    }

    .label {
      color: $gray-3;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
    }

    .diamond-icon {
      --size: 4px;
      font-weight: 900;
      font-size: var(--size);
      color: $gray-3;
      width: var(--size);
      height: var(--size);
      margin: 0 8px;
    }

    .available-date {
      margin-left: 16px;
    }

    .actions {
      margin: 0 24px 0 100px;

      ::v-deep .movida-common__app-button.primary {
        min-width: unset;
        height: 24px;
      }
    }
  }
}

