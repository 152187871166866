
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.header {
  .heading {
    font-size: 18px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-family: $secondary-font;
  }

  .icon {
    color: $dark-gray;
    font-size: 16px;
    margin-right: 8px;
  }
}

.amendment {
  .body {
    .info + .info {
      margin-left: 72px;
    }

    .section {
      padding: 16px 24px;
    }

    .section + .section {
      border-top: 1px solid $light-gray-3;
    }
  }

  .icon-content {
    .icon {
      margin-right: 8px;
    }
  }
}

