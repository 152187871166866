
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.subtitle {
  font-weight: bold;
  font-size: 20px;
  font-family: $secondary-font;
  font-weight: 500;
}

.section-message {
  display: block;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 22px;
}

.section {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.row:not(:first-of-type) {
  padding-top: 24px;
}

