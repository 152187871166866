
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.products {
  .title {
    margin-bottom: 16px;

    .icon-container {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      .icon {
        font-size: 14px;
      }
    }

    .diamond-icon {
      color: $gray-3;
      font-size: 4px;
      margin: 0 8px;
    }

    .count {
      font-family: $secondary-font;
      font-weight: 400;
      color: $gray-3;
    }
  }

  .table {
    gap: 8px;

    .header {
      grid-template-columns: var(--grid-columns);
      gap: 8px;
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      color: $gray-3;

      .icon {
        font-size: 14px;
        color: $gray-2;
      }

      .diamond-spacer {
        font-size: 8px;
        color: $gray-2;
      }
    }

    .footer {
      grid-template-columns: 1fr 136px;
      gap: 8px;
      height: 40px;

      .subtotal {
        background-color: $light-gray-2;
        border-radius: 4px;
        padding: 0 12px;
        font-family: $primary-monospace-font;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

