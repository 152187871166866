
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.fields,
.hierarchy {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.submit-actions {
  .button + .button {
    margin-left: 16px;
  }
}

.id-tag {
  margin: 0 8px;
}

.supplier-info {
  border: unset;
}

.loading-lines {
  margin-bottom: 16px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

