
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit {
  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      margin-top: 24px;
      padding-top: 24px;
    }

    &.header {
      .left {
        margin-bottom: 8px;

        .id-tag {
          margin: 0 8px;
        }

        .title,
        .subtitle {
          font-family: $secondary-font;
          font-weight: 500;
        }

        .subtitle {
          font-size: 20px;
        }

        .title {
          font-size: 38px;
        }
      }

      .services-badge {
        margin-right: 16px;
      }
    }

    &.footer {
      .confirm {
        margin-right: 18px;
      }

      .back {
        margin-right: 24px;

        .icon {
          margin-right: 8px;
        }
      }

      .next .icon {
        margin-left: 8px;
      }
    }
  }
}

