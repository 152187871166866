// colors
// ---

// black, white and gray
$black:  #000000;
$white:  #FFFFFF;
$gray:   #C4C4C4;
$gray-2: #959595;
$gray-3: #666666;
$gray-4: #525252;
$light-gray:   #FAFAFA;
$light-gray-2: #F6F6F6;
$light-gray-3: #EAEAEA;
$light-gray-4: #D4D4D4;
$dark-gray: #333333;

// blue
$blue:         #3FA2F7;
$light-blue:   #D9EEFF;
$dark-blue:    #0077DC;

// green
$green:         #56C568;
$light-green:   #F1FFEB;
$light-green-2: #CAF7D1;
$dark-green-2:  #1F5228;
$dark-green:    #3BB14E;

// red
$red:          #EB5757;
$light-red:    #FFEBEB;
$dark-red:     #D33F3F;

// yellow
$yellow:       #FFC400;
$light-yellow: #FFF6D9;
$dark-yellow:  #FFA800;

// orange
$orange:         #F47920;
$light-orange:   #FFF5EC;
$light-orange-2: #FFCFA3;
$dark-orange-2:  #DD4A26;
$dark-orange:    #EE6A28;

// purple
$purple:         #622279;
$light-purple:   #F2E5F6;
$light-purple-2: #C291D3;
$light-purple-3: #C262E4;
$dark-purple:    #43144A;

// aqua
$aqua:       #66DFE7;
$light-aqua: #D9F8FF;
$dark-aqua:  #1EAFB8;



// color aliases
// ---

// info
$info:          $blue;
$light-info:    $light-blue;
$dark-info:     $dark-blue;

// success
$success:         $green;
$light-success:   $light-green;
$light-success-2: $light-green-2;
$dark-success:    $dark-green;
$dark-success-2:  $dark-green-2;

// error
$error:       $red;
$light-error: $light-red;
$dark-error:  $dark-red;

// warning
$warning:       $yellow;
$light-warning: $light-yellow;
$dark-warning:  $dark-yellow;

// brand and brand-alt
$brand:         $orange;
$light-brand:   $light-orange;
$light-brand-2: $light-orange-2;
$dark-brand:    $dark-orange;
$dark-brand-2:  $dark-orange-2;

$brand-alt:         $purple;
$light-brand-alt:   $light-purple;
$light-brand-alt-2: $light-purple-2;
$light-brand-alt-3: $light-purple-3;
$dark-brand-alt:    $dark-purple;

// primary (~brand)
$primary:         $brand;
$light-primary:   $light-brand;
$light-primary-2: $light-brand-2;
$dark-primary:    $dark-brand;
$dark-primary-2:  $dark-brand-2;


// --------------

// utils.vue
$co-loading-color:      $brand;
$co-loading-dark-color: $dark-brand-2;


