
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-part-show {
  padding-bottom: 24px;
}

.header {
  padding-top: 24px;
}

.title {
  font-family: $secondary-font;
  font-size: 30px;
  font-weight: bold;
  word-break: normal;
  overflow-wrap: break-word;
}

.column + .column {
  margin-left: 8px;
}

.id-tag {
  margin: 0 8px;
}

.subtitle-icon {
  margin-right: 16px;
  font-size: 24px;
}

.list {
  padding-top: 24px;
}

.symptoms{
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.parts-tag.app-tag {
  margin-bottom: 8px;
  font-family: $secondary-font;
  font-size: 16px;
  display: flex;

  ::v-deep .app-tag-label{
    padding: 8px 16px;
  }
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.description-content {
  margin-top: 8px;

  .description {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}

.subtitle {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding: 24px 0;
}

.section {
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.margin-bottom{
  margin-bottom: 8px;
}

.footer {
  margin-top: 24px;
}
