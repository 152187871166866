
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.ticket-details-step {
  .header {
    margin-bottom: 24px;

    .title,
    .subtitle {
      font-family: $secondary-font;
      font-weight: 500;
    }

    .title {
      font-size: 38px;
    }

    .subtitle {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      padding-top: 24px;
      margin-top: 24px;
    }

    &.body {
      .banner {
        margin-bottom: 24px;
      }

      .half {
        width: 566px;
      }

      .section-title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 18px;
      }

      .row {
        margin-top: 16px;
      }

      .vehicle-section {
        .field {
          width: 100%;

          & + .field {
            margin-left: 24px;
          }
        }

        .occurrence {
          margin-top: 16px;
          padding: 16px;
          background-color: $light-gray;
          border-radius: 8px;
        }
      }
    }

    &.footer {
      .cancel {
        color: $gray-3;

        &:hover {
          color: $orange;
        }
      }

      .back {
        margin-right: 24px;

        .icon {
          margin-right: 8px;
        }
      }

      .search .icon {
        margin-left: 8px;
      }
    }
  }
}

