
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.loading-lines {
  width: 320px;
}

.email {
  cursor: pointer;
  height: 32px;
  padding: 0 8px;
  border-radius: 5px;
  width: max-content;

  &:hover {
    color: $primary;
    background-color: $light-gray-2;
  }

  .status {
    font-size: 12px;
    margin-right: 8px;
  }

  .chevron {
    font-size: 10px;
    margin-left: 8px;
  }
}

.popover-content {
  min-width: 555px;

  .row,
  .link {
    font-size: 14px;
  }

  .row {
    height: 40px;
    padding: 0 16px;
    font-weight: 300;

    .icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
      margin-right: 16px;
    }
  }

  .email-address {
    font-weight: 500;
    margin-left: 4px;
    margin-right: 16px;
  }

  .row + .row,
  .sent-emails {
    border-top: 1px solid $light-gray-3;
  }
}

.unregistered {
  font-style: italic;
}

.loading-lines {
  cursor: progress;
}

.icon {
  color: $gray-2;

  &.success {
    color: $dark-success;
  }

  &.error {
    color: $error;
  }

  &.erred {
    color: $warning;
  }
}

.loading {
  height: 40px;
  padding: 0 16px;
}

.loading + .loading {
  border-top: 1px solid $light-gray-3;
}

.email-history {
  height: 32px;
}

::v-deep .trigger {
  width: 100%;
}

.sent-emails {
  max-height: 400px;
  overflow: auto;
}

