
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-model-group {
  .header {
    margin-bottom: 16px;

    .discarded-icon {
      margin-right: 4px;
    }

    .name {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .description {
      margin-top: 4px;
      font-size: 14px
    }
  }
}

