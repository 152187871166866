
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.attachments {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  padding: 0;
}

.attachment {
  list-style: none;
}

// List layouts
.attachments {
  &.col-3 {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);

    .attachment{
      .card {
        width: 100%;
      }
    }
  }
}
