
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.store-info-card {
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  height: 115px;

  .initials-content {
    margin-right: 20px;
    margin-left: 4px;
    text-align: center;
  }

  .loading-lines + .loading-lines {
    margin-top: 4px;
  }

  .address {
    margin-right: 8px;
  }

  .name {
    font-family: $secondary-font;
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 4px;
  }
}

.content {
  overflow: hidden;
  flex-grow: 1;
}

.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.name,
.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.secondary-content {
  font-weight: normal;
}

.initials {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: 500;
  font-size: 20px;
  font-family: $secondary-font;
}

.initials-loading {
  width: 82px;
}

