
.pac-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid $gray;
  border-top: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding-bottom: 3px;
}

.pac-logo {
  &:after {
    margin: 4px 8px;
    background-size: 120px 14px;
  }
}

.pac-item {
  height: 40px;
  padding: 0 12px;
  align-items: center;
  font-family: $secondary-font;
  justify-content: space-between;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid $light-gray-3;
    margin-bottom: 4px;
  }

  &:hover {
    background: $light-gray-2;
    color: $primary;

    .pac-item-query {
      color: $dark-primary-2;
    }
  }
}

.pac-icon-marker {
  display: none;
}

.pac-item-query {
  font-size: 14px;
  // padding-right: 3px;
  font-family: $secondary-font;
  font-weight: 300;
  line-height: 40px;
  color: $dark-gray;
}

span.pac-matched {
  font-weight: 600;
}
