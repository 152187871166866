
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.table-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
    }

    .service-description {
      padding-bottom: 2px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      color: $gray-2;
    }

    .vehicle-solutions-count {
      margin-left: 4px;
    }
  }
}

