
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit-store-settings {
  .header {
    margin-top: 32px;
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: 500;
    }
  }

  .fields {
    .field {
      + .field {
        margin-top: 16px;
      }

      .checkbox-label {
        margin-right: 4px;
      }
    }
  }

  .footer {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $light-gray-3;
  }
}

