
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-popover-content {
  // width: 409px;

  > .header {
    padding: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title {
      color: $dark-gray;
      font-family: $secondary-font;
      font-size: 18px;
      font-weight: 500;
    }

    .id-tag {
      color: $gray-3;
      margin-left: 8px;

      ::v-deep .content {
        font-size: 16px !important;
      }
    }

    .complementary-state {
      margin-left: auto;
    }
  }

  > .body {
    .separator {
      padding: 16px;

      > .icon {
        font-size: 14px;
        color: $gray-2;
      }
    }

    .section {
      padding: 16px;

      & + .section {
        border-top: 1px solid $light-gray-3;
      }
    }

    .tree {
      overflow-x: auto;
    }

    .list {
      > .header {
        padding-bottom: 8px;
      }

      > .body {
        > .item {
          padding: 4px 0;

          > .label {
            font-family: $secondary-font;
            font-size:   12px;
            font-weight: 400;
            line-height: 150%;

            color: $gray-3;
          }

          > .value {
            font-family: $secondary-font;
            font-size:   14px;
            font-weight: 400;
            line-height: 150%;

            color: $dark-gray;
          }
        }
      }
    } // .list
  } // .body

  .provider-icon,
  .gmf-icon {
    // width: 44px;

    > .badge {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px 4px;

      width: 51px;
      height: 24px;

      /* Brand/Light brand 2 */

      background: #FFCFA3;
      border-radius: 4px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;

      /* Secondary/Paragraph/Medium 16px */

      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      /* Monochrome/Dark Grey */
      color: #333333;
    }

  }

  .gmf-icon {
    > .badge {
      background: white;
      color: $orange;
    }
  }


}

