
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


@import "@/assets/styles/field";

.suffix {
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  color: $gray-3;
  top: 12px;
  right: 0;
  display: block;
  padding: 0 8px;
  user-select: none;
  pointer-events: none;
}

