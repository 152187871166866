
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.new-supplier {
  padding-bottom: 24px;
}

.row + .row {
  margin-top: 24px;
}

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.fields {
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.actions {
  margin-top: 24px;
}

.submit-actions {
  .button + .button {
    margin-left: 16px;
  }
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 4px;
  font-family: $secondary-font;
  font-weight: 500;
}

.subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.hierarchy-radio {
  height: 100%;
}

