
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.file-selector {
  .empty-card {
    border: 1px dashed $gray-3;
    border-radius: 8px;
    width: 212px;
    height: 140px;
    cursor: pointer;
    color: $gray-2;

    .icon {
      font-size: 20px;
    }

    &:hover {
      border-color: $orange;
      color: $orange;
    }

    &:active {
      border-width: 2px;
    }
  }

  &.dragover {
    .empty-card {
      background: $light-orange;
    }
  }
}

