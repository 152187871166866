
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.copy-icon {
  cursor: pointer;

  &:hover {
    color: $orange;
  }
}

