
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form {
  width: 100%;
  gap: 8px;

  .row {
    gap: 16px;

    &:first-child {
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) 40px;
    }
  }

  .submit-button {
    min-width: revert; // XXX: remove regra de min-width do app-button
  }
}

