
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-part-row-skeleton {
  display: grid;
  grid-template-columns: 70px 280px 1fr 120px 64px;

  .column + .column {
    margin-left: 24px
  }
}

