
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.phone-nested-fields {
  .fields + .fields {
    margin-top: 12px;
  }
}

