
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-info {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px 24px;

  .info-block {
    .icon-container {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      .icon {
        font-size: 12px;
        color: $gray-3;
      }
    }

    .id,
    .text {
      font-size: 14px;
      font-weight: 400;
    }

    .text {
      font-family: $secondary-font;
    }

    .subtext {
      font-size: 12px;
      font-weight: 400;
      color: $gray-3;

      padding: 2px 0;
    }

    .diamond-icon {
      color: $gray-3;
      font-size: 4px;
      margin: 0 4px;
    }

    .info-content {
      overflow: hidden;
    }

    .license-plate,
    .business-unit-tag {
      margin-right: 8px;
    }
  }
}

