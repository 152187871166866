
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.header {
  padding-bottom: 16px;
}

.icon {
  margin-right: 8px;
}

.error-message {
  display: block;
  color: $error;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.actions {
  .btn + .btn {
    margin-left: 32px;
  }
}

.count {
  font-size: 14px;
  color: $gray-3;
}

