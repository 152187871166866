
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.symptom-empty {
  text-align: center;
  font-size: 14px;
  padding: 20px 0;
}

.symptoms-row {
  font-family: $secondary-font;
  border: 1px solid $light-gray-3;
  border-bottom: none;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: normal;
  min-height: 64px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom: 1px solid $light-gray-3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.name {
  display: block;
  font-size: 18px;
}

.description {
  font-family: $primary-font;
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

.icon {
  margin-right: 24px;
  font-size: 20px;
}

.results {
  font-size: 14px;
  font-weight: 300;
  color: $gray-3;
  margin: 24px 0px 4px 0px;
}


