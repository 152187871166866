
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.fields {
  grid-template-columns: var(--grid-columns);
  gap: 8px;

  .action-container {
    height: 40px;
    border-radius: 50%;
    cursor: pointer;

    &.attachment {
      color: $orange;

      ::v-deep .extra-data-indicator .icon {
        color: $orange;
      }

      &:active,
      &:focus {
        background-color: $light-gray-4;

        ::v-deep .extra-data-indicator .icon {
          color: $dark-orange-2;
        }
      }

      &.disabled {
        background-color: transparent;

        .extra-data-indicator {
          cursor: not-allowed;

          ::v-deep .icon {
            color: $gray-3;
          }
        }
      }
    }

    &:hover {
      background-color: $light-gray-3;
      color: $orange;
    }

    &:active,
    &:focus {
      background-color: $light-gray-4;
      color: $dark-orange-2;
    }

    &.disabled {
      background-color: transparent;
      color: $gray-3;
      cursor: not-allowed;
    }
  }

  .add-container {
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid $orange;
    color: $orange;

    &:hover {
      color: $dark-orange;
      border-color: $dark-orange;
    }

    &:active,
    &:focus {
      color: $dark-orange-2;
      border-color: $dark-orange-2;
    }

    &.disabled {
      color: $gray-3;
      border-color: $gray-3;
      cursor: not-allowed;
    }
  }
}

