
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.deal-preview {
  .header {
    .ongoing-state {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
    }

    .expires-soon-warning {
      margin-top: 8px;

      .icon {
        margin-right: 4px;
        color: $dark-yellow;
        font-size: 14px;
      }

      .text {
        font-family: $primary-font;
        color: $dark-yellow;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .title {
      padding-top: 6px;

      .id-tag {
        margin-left: 8px;
      }
    }
  }

  .content {
    margin-top: 12px;

    .text {
      display: block;

      font-family: $primary-font;
      color: $dark-gray;
      font-size: 14px;
      font-weight: 300;
    }

    .text + .text {
      margin-top: 2px;
    }
  }

  .actions {
    margin-top: 12px;
  }
}

