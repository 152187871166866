
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


@import "@/assets/styles/field";

.label-content {
  display: flex;
  align-items: center;

  .mandatory-icon {
    font-size: 4px;
    color: $orange;
  }
}

.input-inner {
  resize: vertical;
  height: initial;
  line-height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 80px;

  &:focus {
    padding: 7px 12px;
  }
}

