
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.button-menu.disabled {
  color: $gray-2;
  background: $light-gray-2;

  &:hover,
  &:active,
  &:visited {
    color: $gray-2;
    background: $light-gray-2;
  }
}

