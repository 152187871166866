
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-row {
  display: grid;
  grid-template-columns: 130px 40px 129px 99px 1fr 93px 124px 100px 64px;

  &.loading {
    .column {
      color: $gray-2;

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;
          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .id-tag,
      .store,
      .link ::v-deep .app-span,
      .link ::v-deep .code .app-span {
        color: $gray-2;
      }

      .link:hover,
      .code:hover ::v-deep .app-span {
        color: $orange;
      }
    }
  }
  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    &.type-column {
      .button {
        overflow: hidden;
      }

      .code-row {
        width: 100%;
      }

      .type-row {
        width: 100%;

        .icon {
          font-size: 12px;
          margin-left: 4px;
        }
      }
    }

    &.supplier-column {
      .supplier {
        width: 100%;
      }
    }

    &.store-column {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
      color: $gray-3;
    }

    &.total-column {
      .text {
        font-family: $secondary-font;
      }
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      text-align: initial;
      color: $dark-gray;
    }

    .link ::v-deep .code .app-span {
      color: $purple;
    }

    .content:hover {
      .link,
      .facilities,
      .code ::v-deep .app-span{
        color: $orange;
      }
    }
  }
}

