
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.body {
  min-height: 490px;
  height: 490px;

  .section {
    padding: 16px 32px;
    border-bottom: 1px solid $light-gray-3;

    .selected-option {
      padding-right: 0;
    }

    .global-price {
      margin-right: 24px;
      height: 40px;
      background-color: $light-gray-2;

      .icon {
        margin-left: 12px;
        margin-right: 16px;
        font-size: 20px;
        color: $gray-2;
      }

      .text {
        font-family: $secondary-font;
        font-size: 16px;
        color: $dark-gray;
        font-weight: normal;
      }
    }

    .option {
      border-bottom: 1px solid $light-gray-3;
    }

    .maintenance-package-select-field {
      ::v-deep .options-wrapper {
        max-height: 300px;
      }
    }
  }
}

