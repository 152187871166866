
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-details-popover-content {
  width: 444px;
  padding: 16px 0;

  .row {
    margin-bottom: 16px;

    .info-box {
      flex: 1 1 0;
      padding-left: 16px;

      + .info-box {
        border-left: 1px solid $light-gray-3;
      }

      .label {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 14px;
        color: $dark-gray;
      }

      .value {
        font-weight: 300;
        font-size: 16px;
        color: $black;

        .empty {
          font-style: italic;
        }
      }
    }
  }

  .link {
    font-size: 14px;
    padding-left: 16px;

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }
}

