
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.maintenance-table-settings-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
}

.input-fields {
  --h-gap: 24px;
  --cols: 2;
  display: flex;
  gap: 16px var(--h-gap);
  flex-wrap: wrap;

  > .field {
    width: calc((100% - (var(--h-gap) * (var(--cols) - 1))) / var(--cols));
  }

  .field.required ::v-deep {
    label span::after,
    .label::after {
      background: $primary;
      border-radius: 2px;
      content: ' ';
      display: inline-block;
      height: 4px;
      margin: 2px 4px;
      width: 4px;
    }
  }
}

.alert {
  align-items: flex-start;
  background: $light-gray;
  border-radius: 8px;
  color: $dark-gray;
  display: flex;
  font-family: $primary-font;
  font-weight: 400;
  font-size: 14px;
  gap: 16px;
  line-height: 1.5;
  padding: 16px;

  .icon {
    flex-shrink: 0;
    font-size: 16px;
    width: 16px;
  }

  .message {
    flex-grow: 1;
  }

  // ALERT THEMES
  &.alert-info {
    background: $light-info;

    .icon {
      color: $info;
    }
  }
}

