
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.new {
  padding: 16px;

  .title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 18px;
  }

  .subtitle {
    font-family: $primary-font;
    font-weight: 300;
    font-size: 14px;
  }

  .field,
  .footer {
    margin-top: 16px;
  }

  .description {
    ::v-deep .input-wrapper {
      display: flex;
      flex-grow: 1;
    }
  }

  .cancel {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    color: $dark-gray;

    &:hover {
      color: $orange;
    }

    &:active,
    &:focus {
      color: $dark-orange-2;
    }
  }
}

