
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-classification-badge {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 22px;
  justify-content: center;
  width: 22px;

  &.icon-cmm {
    width: 45px;
  }
}

