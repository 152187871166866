
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.text {
  font-size: 16px;
}

.icon {
  margin-right: 8px;
}

.checked {
  color: $light-gray-4;

  .text {
    text-decoration: line-through;
  }
}

.info-icon {
  margin-left: 8px;
}

