
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.tree-node {
  display: flex;
  flex-direction: row;
  align-items: center;  // vertical-align

  gap: 8px;
  padding: 4px 8px;

  border: 1px solid $light-gray-4;
  border-radius: 8px;

  cursor: pointer;

  &.selected {
    background: $light-purple;
  }

  > .icon-frame {
    display: flex;
    align-items: center;      // vertical-align
    justify-content: center;  // horizontal-align

    width: 20px;
    height: 20px;
  }

  .id-tag {
    > .hashtag,
    > .value {
      font-family: $primary-monospace-font;
      font-style:  normal;
      font-weight: 400;
      font-size:   14px;
      line-height: 150%;
    }

    > .hashtag {
      user-select: none;
    }
  }

  .subtext {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $dark-gray;
  }
}

