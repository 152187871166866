
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.occurrence-info {
  overflow: hidden;
  font-size: 14px;

  .header {
    margin-bottom: 4px;
    .id-tag {
      margin-right: 4px;
    }

    .status-badge {
      height: 20px;
      padding: 0 4px;
      border-radius: 12px;
      background-color: $light-gray-3;

      .status-label {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .label {
    .kind {
      margin-right: 2px;
    }

    .diamond-icon {
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
      width: 4px;
      height: 4px;
      margin: 0 4px;
    }
  }

  .description {
    margin-top: 4px;
  }
}

