
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form-fields {
  .section {
    padding: 16px 24px;
    gap: 16px;  // vertical

    & + .section {
      border-top: 1px solid $light-gray-3;
    }

    .row {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 24px;  // horizontal
    }
  }
}

