
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.app-finder {
  position: relative;

  &:not(.disabled):focus-within {
    .combobox {
      outline: 2px solid $primary;
      outline-offset: -2px;
    }
  }
}

.combobox {
  align-items: center;
  background: #fff;
  border: 1px solid $gray;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1px 13px;
  width: 100%;

  .caret {
    color: $dark-gray;
    transition: ease-in-out 200ms transform;
  }

  .error & {
    border-color: $error;
  }

  .disabled & {
    background: $light-gray-3;
    cursor: not-allowed;

    .caret {
      display: none;
    }
  }

  &[aria-expanded=true] {
    .caret {
      transform: rotate(-180deg)
    }
  }

  &[aria-expanded=true],
  .app-finder:not(.disabled) &:hover,
  .app-finder:not(.disabled) &:focus {
    border-color: $primary;

    .caret {
      color: $primary;
    }
  }
}

.clear-button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 24px;
  color: $dark-gray;
  display: flex;
  font-size: 16px;
  height: 24px;
  justify-content: center;
  margin: 8px 7px;
  position: absolute;
  right: 25px;
  top: 0;
  width: 24px;

  .disabled & {
    display: none;
  }

  &:hover {
    background: $light-gray-3;
    color: $primary;
  }
}

.selected-label,
.placeholder {
  font-family: $primary-font;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  margin-right: 0;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;

  .clearable.selected & {
    margin-right: 31px;
  }
}
.selected-label {
  color: $gray-3;

  .disabled & {
    color: $gray-2;
  }
}
.placeholder {
  color: $gray;
  font-weight: 300;

  .disabled & {
    color: $gray;
  }
}

.listbox {
  --listbox-margin: 4px;
  border: 1px solid $light-gray-4;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.25);
  background: #fff;
  display: flex;
  flex-direction: column;
  margin: var(--listbox-margin);
  overflow: hidden;
  position: absolute;
  z-index: 1000;
}

.search-bar {
  border-bottom: 1px solid $light-gray-4;
  padding: 8px;

  input {
    border: 1px solid $gray;
    border-radius: 4px;
    color: $gray-4;
    font-family: $primary-font;
    font-size: 16px;
    line-height: 36px;
    padding: 1px 13px;
    width: 100%;
  }
}

.option-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: auto;
}

.option {
  padding: 12px;

  &:not(:first-of-type) {
    border-top: 1px solid $light-gray-4;
  }

  .default-option-slot {
    display: flex;

    .label {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .remove-button {
      align-items: center;
      border-radius: 24px;
      display: none;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: center;
      height: 24px;
      width: 24px;
    }
  }

  &:not(.empty) {
    cursor: pointer;

    &[aria-selected=true] {
      background: $light-gray-2;
      color: $primary;

      .default-option-slot {
        .remove-button {
          color: $gray-2;
          display: flex;
        }
      }
    }

    &:hover,
    &:focus,
    &.current-focus {
      background: $light-gray-2;
      color: $dark-primary-2;

      .default-option-slot {
        .remove-button {
          color: inherit;
          background: $light-gray-3;
        }
      }
    }
  }
}

/* THEMES */
/* THEME: borderless */
.app-finder.borderless {
  .combobox {
    background: none;
    border: none;
    padding: 8px 12px 8px 24px;
  }
  &.disabled .combobox {
    .caret {
      color: $gray;
      display: block;
    }
  }

  .selected-label,
  .placeholder {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    margin-right: 4px;
  }
  .selected-label {
    color: $dark-gray;
  }
  &.disabled .selected-label {
    color: $gray;
  }
}
