
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-model-group-option {
  padding-left: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  .name {
    padding-top: 5px;
    font-size: 14px;
  }

  min-height: initial;
  line-height: initial;

  overflow-x: hidden; // XXX: overflow horizontal

  * {
    cursor: pointer;
  }

  .remove-icon-content {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.id-tag-column {
  margin-right: 10px;
}

.name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;

  &.active {
    color: $primary;
  }

  &.focus {
    color: $dark-primary-2;
  }
}

.description {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 700px;
}

.vehicles-count-column {
  flex-shrink: 0;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  color: $gray-4;

  .fa-car-mechanic {
    margin-right: 8px;
    color: $gray-2;
  }
}

.content-column {
  flex-grow: 1;
}

.actions-column {
  min-width: 42px;
  margin-left: 12px;
}

