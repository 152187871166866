
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.tree {
  padding: 16px;
  width:   400px;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .content {
    gap: 16px;

    > .icon-frame {
      width:  20px;
      height: 20px;

      display: flex;
      align-items: center;      // vertical-align
      justify-content: center;  // horizontal-align

      > .icon {
        font-weight: 900;
        font-size:   14px;
        line-height: 14px;
        color: $gray-3;
      }
    }
  }

  .empty {
    .empty-message {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }
  }
}


