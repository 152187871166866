
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


// .form-fields {
//
// }

.field {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.terminate-all {
  ::v-deep .checkbox-label {
    font-weight: 400!important;
    line-height: 1.5;
  }
}
