
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.mobiauto-version-status-icon {
  font-size: 20px;
  width: 25px;
  text-align: center;
}

.success {
  color: $dark-success;
}

.warning {
  color: $dark-warning;
  font-weight: 900;
}

