
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.index-table-skeleton {
  .row {
    display: grid;
    grid-template-columns: var(--grid-columns);

    & + .row {
      margin-top: 24px;
    }

    .column + .column {
      margin-left: 24px
    }
  }
}

