
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.type-icon {
  margin-right: 8px;
  font-size: 18px;
}

.type-text {
  font-size: 14px;
  font-weight: normal;
}

.type-col {
  width: 165px;
}

.name-col {
  .discarded-icon {
    margin-right: 4px;
  }
}

.price-col {
  text-align: right;
  width: 150px;
}

.price  {
  &.free {
    text-decoration-line: line-through;
    color: $gray-2;
  }
}

