
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.photo-content {
  margin-right: 20px;
  margin-left: 4px;
  text-align: center;
}

.supplier-row {
  padding: 16px 12px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
}

.supplier-row + .supplier-row {
  border-top: 1px solid transparent;
}

.content {
  flex-grow: 1;
  overflow: hidden;
}

.main-content {
  margin-bottom: 4px;
}

.fa-check-circle {
  color: $dark-green;
}

.fa-times-circle  {
  color: $dark-warning;
}

.name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.link {
    &:hover {
      color: $primary;
    }
  }
}

.cnpj {
  font-family: $primary-monospace-font;
}

.secondary-text {
  font-size: 14px;
}

.divisor {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.contacts {
  margin-right: 36px;
}

.contact-icon {
  margin-right: 8px;
}

.status {
  margin: 0 26px 0 14px;

  .icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}

.schedule {
  margin-left: 8px;
  overflow: hidden;
}

