
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.template-download-button {
  user-select: none;

  .download-area {
    padding: 16px;
    border: 1px solid $light-gray-3;
    border-radius: 16px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      background-color: $light-gray;
    }

    .circle {
      width: 72px;
      height: 72px;
      background-color: $light-gray-2;
      border-radius: 50%;

      .icon {
        font-size: 36px;
        font-weight: 100;
        color: $gray-3;
      };
    }

    .name {
      margin-top: 8px;
      font-family: $secondary-font;
      font-weight: 400;
    }
  }

  .progress-content {
    margin-top: 24px;
    width: 100%;

    .progress-bar {
      height: 8px;
      background-color: $light-gray-3;
      border-radius: 4px;
      margin-right: 8px;
      width: var(--progress);
    }

    .percentage {
      font-family: $secondary-font;
      font-size: 12px;
      font-weight: 400;
      width: 30px;
      text-align: right;
    }
  }
}

