
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-service-support-maintenance-vehicle-model-group {
  .lines {
    padding: 24px;
  }

  .header {
    padding: 24px 24px 0  24px;

    .discarded-icon {
      margin-right: 4px;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 4px;
    }
  }

  .body {
    overflow-y: auto;
  }
}

