
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.form {
  align-items: center;
  background: white;
  border: 1px solid $gray;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 4px 12px;

  &:hover,
  &:focus-within {
    border-color: $primary;
  }

  &:focus-within {
    outline: 2px solid $primary;
    outline-offset: -2px;
  }
}

.input {
  background: transparent;
  border: none;
  color: $gray-3;
  flex-grow: 1;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 400;
  height: 22px;
  width: 3ch;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: $gray-2;
    font-weight: 300;
  }
}

.btn {
  background: transparent;
  border: none;
  color: $dark-gray;
  cursor: pointer;
  height: 20px;
  width: 20px;

  &:hover {
    color: $primary;
  }

  &:active {
    color: $dark-primary-2;
  }

  &:disabled {
    color: $gray;
    cursor: not-allowed;
  }
}
