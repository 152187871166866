
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.compact {
  .photo-content {
    margin-right: 8px;
  }

  .name {
    font-size: 18px;
  }
}

.photo-content {
  margin-right: 20px;
  margin-left: 4px;
  text-align: center;
}

.loading-lines + .loading-lines {
  margin-top: 4px;
}

.address {
  margin-right: 8px;
  font-weight: 300;
}

.name {
  font-size: 30px;
  font-weight: normal;
  font-family: $secondary-font;
  margin-bottom: 4px;
}

.hierarchy {
  margin-left: 8px;
}

.content {
  overflow: hidden;
  flex-grow: 1;
}

.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.address,
.head-office-name,
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.classification-badge {
  margin-left: 8px;
}

.cnpj {
  font-size: 16px;
  font-weight: 300;
  font-family: $primary-monospace-font;
  white-space: nowrap;
}

.secondary-content {
  font-weight: normal;
}

.head-office {
  margin-top: 4px;
  font-size: 14px;
}

.small {
  font-size: 14px;
}

.data {
  overflow: hidden;
}

.actions {
  margin-left: 12px;
}

