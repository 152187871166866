
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-card {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 16px;

  .name {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 18px;
  }

  .diamond-icon {
    font-size: 4px;
    color: $gray-3;
    margin: 0 8px;
  }

  .cnpj {
    font-family: $primary-monospace-font;
    font-weight: 400;
    color: $gray-3;
  }

  .icon-container {
    height: 16px;
    width: 16px;
    margin-right: 8px;

    .icon {
      color: $gray-2;
      font-size: 12px;
    }
  }

  .text {
    font-size: 14px;
  }

  .copy {
    margin-left: 8px;
    font-weight: 400;
    color: $orange;
  }
}

