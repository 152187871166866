
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.product {
  padding-bottom: 24px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.header {
  padding-top: 18px;

  .discarded-icon {
    margin-right: 8px;
  }

  .name {
    font-size: 30px;
    font-weight: bold;
    word-break: normal;
    overflow-wrap: break-word;
  }

  .tags {
    margin-left: 8px;

    .id-tag {
      margin-top: 4px;
    }

    * + * {
      margin-left: 8px;
    }
  }
}


.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

