
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-info-tag {
  width: 542px;
  height: 72px;
  background-color: $light-gray;
  border-radius: 8px;

  .manufacturer-icon-wrapper {
    padding: 12px;
  }

  .vehicle-info {
    .name,
    .year {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 16px;
    }

    .diamond-icon {
      --size: 6px;
      font-size: var(--size);
      width: var(--size);
      height: var(--size);
      color: $gray-3;
      margin: 10px;

      &.small {
        --size: 4px;
      }
    }

    .row {
      .vehicle-plate {
        padding: 2px 4px;
        margin-right: 8px;

        .value {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .info {
      margin-right: 8px;
      font-family: $secondary-font;
      font-weight: 400;
      color: $gray-4;

      .icon-container {
        height: 20px;
        width: 20px;
        margin-right: 2px;
        font-size: 14px;
        color: $gray-2;
      }

      .label {
        font-weight: 400;
        font-size: 14px;
      }

      .fipe {
        font-family: $primary-monospace-font;
      }
    }
  }
}

