
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form {
  width: 100%;
  gap: 8px;

  .row {
    gap: 16px;

    &.first {
      grid-template-columns: minmax(0, 1fr) max-content 40px;
    }

    &.second {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &.third {
      grid-template-columns: repeat(2, minmax(0, 2fr)) repeat(2, minmax(0, 1fr))
    }

    &.fourth {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  .submit-button {
    min-width: revert;
  }

  .collapse-group {
    gap: 8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 200ms ease-in-out;

    &.show {
      max-height: 140px;
      overflow: visible;
    }
  }

  .clear-filters {
    display: flex;

    .collapse-button {
      color: $dark-gray;
      display: block;
      margin-left: auto;

      .icon {
        font-size: 14px;
        margin-right: 4px;
        transition: transform 200ms ease-in-out;
      }

      .text {
        font-size: 14px;
        font-weight: 400;
      }

      &.active {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}

