
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


::v-deep .container {
  border-color: $dark-warning;

  .header .title {
    color: $dark-warning
  }

  .description .movida-common__info-label-message {
    line-height: unset;
  }
}

.icon {
  margin-right: 8px;
}

.row {
  margin-top: 16px;
}

.info:not(:last-of-type) {
  margin-right: 72px;
}

.error-message {
  margin-right: 4px;
}

