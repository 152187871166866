
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.approval.new {

  .content {
    padding: 18px 24px;

    .confirmation-message {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
    }
  }

}

