
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.app-daterange-picker {
  position: relative;
  width: 100%;
}

.input {
  border: 1px solid $gray;
  border-radius: 4px;
  color: $gray-3;
  font-family: $primary-font;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 1px 62px 1px 13px;
  width: 100%;

  &::placeholder {
    color: $gray;
    font-weight: 300;
  }

  &.active,
  &:hover,
  &:focus {
    border-color: $primary;
  }
  &.active {
    outline: 2px solid $primary;
    outline-offset: -2px;
  }

  &:disabled {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-2;
    cursor: not-allowed;
  }
  .error .flatpickr-wrapper > & {
    border-color: $error;
  }
}

.clear-button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 24px;
  color: $dark-gray;
  display: flex;
  font-size: 16px;
  height: 24px;
  justify-content: center;
  margin: 7px;
  position: absolute;
  right: 25px;
  top: 0;
  width: 24px;

  &:hover {
    background: $light-gray-3;
    color: $primary;
  }
}

.icon {
  color: $gray-4;
  margin: 11px;
  position: absolute;
  right: 0;
  top: 0;
}

::v-deep .flatpickr-wrapper {
  width: 100%;

  .flatpickr-calendar {
    border: none;
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    font-family: $secondary-font;
    padding: 16px;
  }

  .inputs {
    align-items: flex-end;
    display: flex;
    font-family: $secondary-font;
    justify-content: space-between;
    margin-bottom: 16px;

    .input {
      @extend .input;
    }
  }

  .range-input-field {
    flex-grow: 1;

    .label {
      color: $dark-gray;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: left;
    }
  }

  .dash {
    color: $dark-gray;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    margin: 0 16px;
  }

  .flatpickr-months {
    .flatpickr-prev-month,
    .flatpickr-next-month {
      height: 16px;
      padding: 8px;
      position: static;
    }

    .flatpickr-month:first-of-type {
      margin-right: 42px;
    }
    .flatpickr-month:last-of-type {
      margin-left: 42px;
    }
  }

  .flatpickr-weekdays {
    background: transparent;

    .flatpickr-weekday {
      font-size: 14px;
      line-height: 1.5;
    }
  }
  .flatpickr-weekdaycontainer {
    &:not(:first-child) {
      padding-left: 12px;
    }
    &:not(:last-child) {
      padding-right: 12px;
    }
  }

  .flatpickr-current-month {
    color: $gray-4;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;

    .cur-month,
    .cur-year {
      font-weight: 500;
    }

    .numInputWrapper {
      min-width: 6ch;
    }
  }

  .dayContainer {
    box-shadow: none;
    padding: 0;

    &:not(:first-child) {
      padding-left: 12px;
    }
    &:not(:last-child) {
      padding-right: 12px;
    }

    .flatpickr-day {
      border: none;
      color: $dark-gray;

      &.inRange {
        background: $light-brand;
        box-shadow: -5px 0 0 $light-brand, 5px 0 0 $light-brand;

      }

      &.startRange,
      &.endRange {
        background: $brand;
        color: white;
      }
      &.startRange {
        border-radius: 8px 0 0 8px;
      }
      &.endRange {
        border-radius: 0 8px 8px 0;
      }
      &.startRange.endRange {
        border-radius: 8px;
      }

      &.nextMonthDay,
      &.prevMonthDay {
        background: transparent;
        box-shadow: none;
        color: $gray;
        visibility: visible;
      }
    }
    &:nth-child(n+1) {
      .flatpickr-day:nth-child(7n+1) {
        &.inRange {
          box-shadow: -2px 0 0 $light-brand, 5px 0 0 $light-brand !important;
        }
      }
      .flatpickr-day:nth-child(7n+7) {
        &.inRange {
          box-shadow: -5px 0 0 $light-brand !important;
        }
      }
      .flatpickr-day:nth-child(7n+1),
      .flatpickr-day:nth-child(7n+7) {
        &.nextMonthDay,
        &.prevMonthDay {
          box-shadow: none !important;
        }
      }
    }
  }

  .footer {
    display: flex;
    gap: 24px;
    margin-top: 16px;

    .preset-range-button {
      background: white;
      border: 1px solid $gray;
      border-radius: 4px;
      color: $dark-gray;
      font-family: $primary-font;
      font-size: 16px;
      line-height: 22px;
      padding: 8px 16px;

      &:hover {
        border-color: $primary;
        color: $primary;
      }
    }
  }
}
