
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.download-button {
  .link {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    color: $dark-gray;

    &:hover {
      color: $gray-3;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }
}

