
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.attach-narrow-card {
  align-items: center;
  background: white;
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 8px;
  height: 40px;
  padding: 0 8px;
  transition: box-shadow 200ms ease-in-out;
  width: 212px;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  }
}

.icon {
  flex-shrink: 0;
}

.label {
  color: $dark-gray;
  cursor: pointer;
  flex-grow: 1;
  flex-shrink: 1;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 100ms;
  white-space: nowrap;

  :hover > &:not(.disabled) {
    color: $primary;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.actions {
  background: $light-gray-2;
  border-radius: 2px;
  display: none;
  flex-shrink: 0;
  height: 30px;

  :hover > & {
    display: flex
  }
}

.action {
  align-items: center;
  background: transparent;
  border: none;
  color: $dark-gray;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 30px;
  transition: color 100ms;
  width: 30px;

  &:hover {
    color: $dark-primary-2;
  }
}
