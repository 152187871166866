
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.contact-row-loading {
  height: 60px;
  border-top: 1px solid $light-gray-3;

  td {
    &:first-of-type .loading-lines {
      margin-left: 16px;
    }

    &:last-of-type .loading-lines {
      margin-right: 16px;
    }
  }
}

