
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.forbidden-in-internal-facilities-toggle {
  $size: 24px;

  .loading {
    width: $size;
  }

  .checkbox-input {
    appearance: none;
    width: $size;
    height: $size;
    border-radius: 50%;
    margin: 0;
    position: relative;
    cursor: pointer;

    &:after {
      height: $size;
      width: $size;
      content: '';
      font-weight: 300;
      color: $gray-3;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $gray-3;
      mask-image: var(--svg-icon);
      mask-repeat: no-repeat;
      mask-position: center;
    }

    &:active::after {
      background-color: $light-orange-2;
    }

    &:checked {
      background-color: $light-orange;

      &:after {
        background-color: $orange;
        mask-image: var(--svg-icon-selected);
      }

      &:active::after {
        background-color: $dark-orange-2;
      }
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

