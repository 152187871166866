
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.settings {
  .header {
    margin-bottom: 32px;

    .title {
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: 500;
    }
  }

  // .menu {
  //   margin-right: 16px;
  // }
}

