
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.table-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    .button {
      overflow: hidden;
    }

    .text {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }

    .vehicle-solutions-count {
      margin-left: 4px;
    }
  }
}

