
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.maintenance-package-option {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.maintenance-package-option + .maintenance-package-option {
  border-top: 1px solid transparent;
}

.id-tag {
  margin-right: 8px;
}

.content-column {
  flex-grow: 1;
}

.maintenance-package-name {
  font-size: 18px;
  font-weight: normal;
  color: $gray-3;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;
}

.maintenances-amount-column {
  flex-shrink: 0;
  margin-right: 16px;
  font-weight: normal;
  color: $gray-3;

  .product-icon {
    margin-right: 6px;
    font-size: 17px;
  }
}

.actions-column {
  min-width: 42px;
  margin-left: 12px;
}

.remove-icon-content {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50px;
  color: $gray-2;
  transition: all 100ms linear;
}

.price-column {
  flex-shrink: 0;
  display: block;
  padding-right: 24px;
}


.products-amount-column {
  flex-shrink: 0;
  font-weight: normal;
  color: $gray-3;

  .product-icon {
    margin-right: 6px;
    font-size: 17px;
  }
}
