
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-row {
  display: grid;
  grid-template-columns: 40px 185px 291px 38px;
  padding: 12px 0;

  .type,
  .id,
  .supplier-name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .unknown {
    font-style: italic;
  }

  .date,
  .address {
    font-weight: 300;
    font-size: 12px;
  }

  .service-order-info {
    .identifier {
      .id {
        margin: 0 4px;
      }
    }
  }
}

