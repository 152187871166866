
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-popover {
  width: 420px;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .loading,
  > .erred {
    padding: 16px;
  }
}


.content {
  .row {
    display: table;
    width: 100%;

    > .col {
      display: table-cell;
      vertical-align: middle; // top, bottom

      padding: 16px;

      &.icon {
        padding-right: 20px;

        font-size: 16px;
        color: $gray-3;

        width: 52px;
        text-align: center;
      }

      &.data {
        padding-left: 0;
      }
    }
  }

  > .row + .row {
    border-top: 1px solid $light-gray-3;
  }

  .title {
    font-weight: 600;
    font-size: 16px;

    word-wrap: break-word;
    white-space: normal;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 500;
  }

  .cnpj,
  .mono {
    font-family: $primary-monospace-font;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal;
  }


  .copy-icon {
    display: inline-block;
    padding-left: 8px;

    color: $orange;
    cursor: pointer;
  }

  .copy-container {
    cursor: pointer;

    .copy-icon {
      display: none;
    }

    &:hover {
      // background-color: $light-gray;

      .copy-icon {
        display: inline-block;
      }
    }
  }

  .contact-list {

    > .item {
      display: table;
      // width: 100%;

      > * {
        display: table-cell;
        vertical-align: middle; // top, bottom
      }

      .value {
        font-family: $primary-monospace-font;
        font-size: 14px;
      }

      .label {
        font-size: 12px;
        color: $gray-2;
      }

      .diamond-icon {
        color: $gray-2;
        font-size: 6px;
        padding: 0 8px;
      }

      .unlabeled {
        color: $gray;
        font-style: italic;
      }
    }

    > .item + .item {
      margin-top: 8px;
    }
  }

}

