
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.app-button.action {
  color: $gray-2;
  font-size: 14px;
}

.header {

  .heading {
    font-size: 18px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-family: $secondary-font;
  }

  .icon {
    color: $dark-gray;
    font-size: 20px;
  }
}

.icon {
  margin-right: 8px;
}

