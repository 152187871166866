
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.repair {
  .section {
    padding: 24px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    & + .section {
      border-top: 1px solid $light-gray-3;
    }

    .breadcrumbs {
      margin-bottom: 32px;
    }

    .edit-button {
      .icon {
        margin-right: 8px;
      }
    }

    .name {
      margin-bottom: 4px;

      .state-icon {
        margin-right: 8px;
      }

      .title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 30px;
      }

      .tag {
        margin-left: 8px;
      }
    }

    .section-title {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 16px;
    }

    .divisor {
      margin: 0 8px;
      font-size: 4px;
      color: $gray-3;
    }
  }
}

