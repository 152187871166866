
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.service-order-state {
  background-color: $light-gray-2;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 12px;

  &.canceled {
    background-color: $error;
    color: $white;
  }

  &.vetor {
    background-color: $light-yellow;
    color: $dark-gray;
  }
}
