
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.index {
  .header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .add {
      font-size: 14px;

      .icon {
        margin-right: 4px;
      }
    }

    .count {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .retry {
    margin-left: 4px;
  }

  .empty {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 24px 0;

    .illustration {
      --size: 48px;
      width: var(--size);
      height: var(--size);
    }

    .empty-message {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
      color: $gray-3;
    }
  }

  .attachments {
    margin: -24px 0 0 -24px;

    .attachment-card,
    .empty-card {
      margin-left: 24px;
      margin-top: 24px;
    }
  }
}

