
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.maintenance-package-option {
  padding: 0 12px;
  height: 56px;
  line-height: normal;

  cursor: pointer;

  * {
    cursor: pointer;
  }

  &.active {
    background: $light-gray-2;

    .name {
      color: $primary;
    }
  }

  &.focus {
    background: $light-gray-2;

    .name {
      color: $dark-primary-2;
    }

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }

  .container {
    overflow: hidden;

    .icon {
      font-size: 20px;
      margin-right: 8px;
    }

    .content {
      overflow: hidden;

      .top {
        overflow: hidden;

        .name {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 18px;
        }

        .tag {
          margin-left: 8px;
        }
      }

      .bottom {
        .description {
          font-size: 14px;
        }
      }
    }
  }

  .actions-column {
    min-width: 42px;
    margin-left: 12px;

    .remove-icon-content {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }
  }
}

