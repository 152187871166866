
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.destroy-ticket-action {
  .destroy {
    color: $gray-3;

    &:hover {
      color: $orange;
    }
  }
}

