
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


//  .edit-supplier-deal-info {}

.header {
  margin: 24px 0;
}

.title {
  font-family: $secondary-font;
  font-size: 30px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.row {
  margin-top: 24px;
}

