
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.first-column {
  padding: 0 16px;
}

.maintenance-table-row-checkbox-field {
  border-bottom: 1px solid $light-gray-3;

  &:first-child {
    border-top: 1px solid $light-gray-3;
  }
}

.vehicle-model-group-maintenance-table-row.row {
  padding-left: 0;

  ::v-deep.id-column {
    margin-right: 8px;
  }
}

.info {
  overflow: hidden;
}

