
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.labor-cost-linked-indicator {
  .icon {
    font-size: var(--size);
  }

  .text {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 500;

    margin-left: 8px;
  }
}

