
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.app-modal {
  $modal-z-index: 400 !default;

  $padding-y: 16px;
  $padding-x: 24px;


  position: fixed;
  z-index: $modal-z-index;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($dark-gray, 0.7);
  display: table;
  transition: opacity 0.3s ease;

  .wrapper {
    content: table-cell;
    z-index: $modal-z-index + 1;
  }

  .frame {
    display: flex;
    flex-direction: column;

    position: relative;
    /*
      TODO definir largura do modal. Entender como reusar componente tanto
      para desktop quanto para phone.
      ex: width: 300px;
    */
    width: 94%;

    // Altura total menos margin
    max-height: calc(100vh - 50px); // era: - 40px);
    max-width:  calc(100vw - 50px); // era: - 40px);

    margin: 20px auto;
    margin-top: 40px;
    overflow: auto;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba($black, 0.33);
    transition: all 0.3s ease, width 0ms;

    &.full-height {
      height: 100%;
    }

    .close-btn {
      z-index: $modal-z-index + 2;
      border: 0;
      outline: 0;
      padding: 0;
      margin: 0;
      position: absolute;
      right: $padding-x;
      top: $padding-y;
      width: 32px;
      height: 32px;
      font-size: 20px;
      color: $dark-gray;
      background-color: transparent;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: $black;
      }
    }

    > .header,
    ::v-deep > .header {
      color: $primary;
      margin-top: 0;
      padding: $padding-y $padding-x;
      border-bottom: 1px solid $light-gray-3;

      .heading {
        font-size: 18px;
        font-weight: 500;
        color: $dark-gray;
        min-height: 32px;
        line-height: 32px;
        font-family: $secondary-font;
      }
    }

    .content {
      max-height: calc(100vh - 40px);
      overflow-y: auto;

      > .body,
      ::v-deep > .body {
        overflow: auto;
        flex-grow: 1;

        padding: $padding-y $padding-x;
      }
    }

    > .footer,
    ::v-deep > .footer {
      display: flex;
      justify-content: center;
      justify-content: space-between;

      padding: $padding-y $padding-x;

      border-top: 1px solid $light-gray-3;
    }
  } // .frame



  /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */
  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: scale(1.1);
  }

} // .app-modal

