
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.show {
  .service-order-content {
    .service-order-title {
      margin-bottom: 24px;

      .unknown {
        font-style: italic;
      }

      .service-order-kind {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 30px;
        margin-left: 8px;
        margin-right: 8px;
      }

      .tag {
        margin-right: 16px;
      }
    }

    .half {
      width: 50%;

      .card {
        width: 100%;
        margin-bottom: 24px;
      }

      .info-label {
        margin-bottom: 24px;

        .info-icon {
          font-size: 12px;
          margin-left: 4px;
        }

        .label-icon {
          font-size: 20px;
          margin-right: 20px;
        }

        .sap-icon {
          margin-right: 16px;
        }

        .label {
          font-family: $secondary-font;
          font-weight: 500;
          font-size: 14px;
        }

        .value {
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
        }
      }
    }

    .services,
    .products {
      margin-bottom: 24px;
    }

    .total-price-row,
    .description-row {
      margin-bottom: 24px;

      .label {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 20px;
      }
    }

    .total-price-row {
      padding: 16px 12px;
      background-color: $light-gray-3;

      .currency {
        font-family: $primary-monospace-font;
      }
    }

    .description-row {
      .description-title {
        margin-bottom: 16px;
      }
    }
  }
}

