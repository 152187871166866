
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form-fields {
  .row + .row {
    padding-top: 24px;
  }

  .identification-section {
    border-bottom: 1px solid $light-gray-3;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  .services-section {
    margin-bottom: 24px;
  }
}

