
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.service-order-toolbar {
  display: flex;
  gap: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}


.action {
  min-width: unset;
  width: 40px;
}
