
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-row {
  border-top: 1px solid $light-gray-3;
  height: 69px;

  &:hover {
    background-color: $light-gray;
  }
}

.deleting {
  margin-left: 24px;
}

.side-label {
  width: 8px;
  height: 100%;
  background-color: $light-gray-2;

  &.active {
    background-color: $success;
  }

  &.disabled {
    background-color: $light-error;
  }
}

.spacer {
  width: 48px;
  height: 100%;
}

.status-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: $gray-2;

  &.active {
    color: $dark-success;
  }

  &.disabled {
    color: $light-gray-3;
  }
}

.content-column {
  overflow: hidden;

  .content {
    overflow: hidden;
    margin-left: 12px;
    margin-right: 24px;

    .service-name {
      font-size: 16px;
      font-weight: normal;
      color: $dark-gray;
      word-break: normal;
      overflow-wrap: break-word;
      margin-bottom: 4px;
      display: block;
    }

    .description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      font-size: 12px;
    }
  }
}

.right-side {
  .service-kind {
    color: $gray-2;
    margin-right: 24px;
    user-select: none;

    .kind-icon {
      margin-right: 4px;

      ::v-deep.service-kind-icon {
        background-color: unset;
      }
    }

    .service-kind-name {
      font-style: italic;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .status-tag {
    margin-right: 12px;
  }

  .activate {
    margin-right: 24px;
    padding: 8px;

    &:hover {
      background-color: $light-gray-3;
      color: $orange;
    }

    .text {
      font-size: 14px;
    }

    .icon {
      margin-right: 8px;
    }
  }

  .action-menu {
    margin-right: 12px;

    ::v-deep .app-button:hover {
      background-color: $light-gray-3;
    }
  }
}

