
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.products-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty{
    text-align: center;
    font-style: italic;
    padding: 32px 0;
    width: 100%;

    color: $gray-3;
  }

  .list {
    margin-top: 16px;
  }
}

