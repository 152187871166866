
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.last-tickets-modal {
  padding: 16px 24px;

  .back-button {
    font-size: 14px;
    margin-bottom: 8px;

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .tickets-table {
    margin-top: 24px;
  }
}

