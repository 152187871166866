
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.best-supplier-badge {
  display: flex;
  align-items: center;
  background: $orange;
  border-radius: 32px;
  width: 127px;
  height: 22px;
  padding: 0 8px 0 8px;

  ::v-deep .inside {
    fill: $white;
  }

  .icon {
    margin-right: 4px;
    width: 26px;
    height: 8px;
  }

  .text {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 400;
    color: $white;
  }
}

