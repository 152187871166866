
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.icon {
  margin-right: 8px;
}

.progress-bar  {
  width: 100%;
  height: 10px;
  background-color: $light-gray-3;
  border-radius: 4px;
  padding-top: 1px;
  margin-right: 8px;
}

.inner-bar {
  width: 25%;
  height: 8px;
  background-color: $primary;
  border-radius: 4px;
}

.percentage {
  font-size: 12px;
}

