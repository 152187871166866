
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.extra-data-indicator {
  cursor: pointer;
  position: relative;

  .icon {
    color: $orange;
  }

  .data-indicator {
    width: 17px;
    height: 14px;
    background-color: $error;
    position: absolute;
    padding: 0 2px;
    border: 1px solid $white;
    border-radius: 24px;
    top: -4px;
    right: -8px;

    .count {
      font-family: $primary-monospace-font;
      font-weight: 700;
      font-size: 10px;
      color: $white;
      user-select: none;
    }
  }
}

