
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.app-outdated-banner {
  height: $outdated-banner-height;
  background-color: $orange;
  color: $white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.app-outdated-banner-enter-active {
  animation: app-outdated-banner-height .5s;
}

.app-outdated-banner-leave-active {
  animation: app-outdated-banner-height .5s reverse;
}

@keyframes app-outdated-banner-height {
  0% {
    height: 0;
  }

  100% {
    height: $outdated-banner-height;
  }
}


