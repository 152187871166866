
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.info-label + .info-label {
  margin-left: 40px;
}

.target-icon {
  color: $success;
  font-size: 24px;
}

.type-icon {
  margin-right: 8px;
}

