
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-repair {
  .header {
    border-bottom: 1px solid $light-gray-4;
    padding-bottom: 32px;
    margin-bottom: 32px;

    .context {
      margin-bottom: 8px;

      .edit-button {
        .icon {
          margin-right: 8px;
        }
      }
    }

    .sap-sync {
      margin-bottom: 8px;
    }

    .title-content {
      margin-bottom: 4px;

      .state-icon {
        margin-right: 8px;
      }

      .title {
        font-size: 30px;
        font-weight: 500;
        font-family: $secondary-font;
      }

      .tags {
        .tag {
          margin-left: 8px;
        }
      }
    }
  }

  .section {
    padding-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid $light-gray-3;
    .section-header {
      font-family: $secondary-font;
      margin-bottom: 16px;

      .section-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .section-subtitle {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .diamond-icon {
    margin: 0 8px;
    font-size: 4px;
    color: $gray-3;
  }

  .column {
    width: calc(50% - 8px);
    margin-right: 8px;

    + .column {
      margin-right: 0px;
      margin-left: 8px;
    }

    .tag {
      width: 100%;
      margin-top: 24px;
    }
  }

  .info {
    margin-bottom: 24px;

    .info-label + .info-label {
      margin-top: 24px;
    }

    .info-label {
      .pickup-service-tag {
        background: $light-gray-3;
        border-radius: 32px;
        font-family: $secondary-font;
        margin-top: 4px;
        padding: 4px 8px;
        width: 121px;
        height: 30px;

        .icon {
          margin-right: 4px;
          font-size: 14px;
          color: $gray-3;
        }

        .label {
          font-weight: 400;
        }
      }

      ::v-deep {
        .movida-common__info-label-content {
          align-items: flex-start;
        }

        .movida-common__info-label-info-content {
          width: 100%;
        }
      }

      .icon {
        color: $dark-gray;
        font-size: 20px;
        width: 20px;
        text-align: center;
        margin-right: 16px;
      }

      .facilities {
        margin-right: 8px;
      }
    }
  }

  .relations {
    margin-top: 24px;

    .relation {
      border: 1px solid $light-gray-3;
      border-radius: 4px;
      padding: 16px;
      height: 100%;
    }
  }

  .free-of-charge {
    .free-tag {
      margin-right: 8px;
    }
  }

  .note-section {
    .note-icon {
      margin-right: 8px;
    }

    .note {
      padding: 12px;
      font-size: 16px;
      font-weight: 300;
      white-space: pre;
    }
  }
}

