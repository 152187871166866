
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.occurrence-option {
  font-size: 14px;
  padding: 12px;

  &.active,
  &.focus {
    background-color: $light-gray-2;
  }

  &.focus {
    cursor: pointer;

    .actions-column {
      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }

  .actions-column {
    min-width: 42px;
    margin-left: 12px;

    .remove-icon-content {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }
  }
}

