
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-section {
  .photo {
    margin-right: 16px;
  }

  .content {
    overflow: hidden;

    .address,
    .name-loading {
      margin-bottom: 16px;
    }

    .name {
      font-size: 20px;
      font-weight: normal;
      font-family: $secondary-font;
      margin-bottom: 4px;
    }

    .diamond-icon {
      margin: 0 8px;
      font-size: 4px;
      color: $gray-3;
    }

    .address {
      .icon {
        color: $gray-2;
        font-size: 14px;
        margin-right: 8px;
      }
    }

    .contact {
      ::v-deep .movida-common__info-label-info-content {
        overflow: hidden;
      }

      .copy-icon {
        margin-left: 4px;
      }
    }
  }
}

