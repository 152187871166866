
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.id-tag {
  font-family: $primary-monospace-font;
  display: inline-block;
  color: $purple;

  &.crop {
    width: 100%;
  }

  .content {
    &.small {
      font-size: 12px;
    }

    &.normal {
      font-size: 14px;
    }

    &.big {
      font-size: 24px;
    }

    .text {
      font-family: $primary-monospace-font;
      font-weight: 400;
    }
  }
}

