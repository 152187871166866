
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.product-option {
  padding: 0 12px;
  height: 56px;
  line-height: normal;
  border-bottom: 1px solid $light-gray-3;

  &:last-child {
    border-bottom: unset;
  }

  .remove-icon-content {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.icon {
  min-width: 32px;
  margin-right: 8px;
}

.product-name {
  font-size: 18px;
  font-weight: normal;
  color: $gray-3;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;

  &.active {
    color: $primary;
  }

  &.focus {
    color: $dark-primary-2;
  }
}

.tag {
  margin-left: 8px;
}

.actions-column {
  min-width: 42px;
  margin-left: 12px;
}

