
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.actions {
  margin-bottom: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.nationwide-support,
.round-the-clock {
  .icon {
    margin-right: 16px;
  }
}

.column + .column {
  margin-left: 200px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

label {
  display: block;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.item + .item {
  margin-top: 16px;
}

.icon {
  &.disabled {
    color: $light-gray-3;
  }
}

.city {
  margin-bottom: 8px;
}

.city-section {
  margin-bottom: 16px;
}

.subtitle {
  font-family: $secondary-font;
  font-weight: normal;
}

.facilities-tag {
  margin-left: 8px;
}

.shift-container {
  margin-top: 32px;
  margin-left: 166px;
  margin-bottom: 8px;

  .shift {
    min-width: 150px;
    font-weight: 500;
    font-size: 12px;
    font-family: $secondary-font;
  }

  .shift + .shift {
    margin-left: 24px;
  }
}

