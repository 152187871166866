
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-support-info {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;

  .fetch-error {
    .retry {
      margin-left: 4px;
    }
  }

  .top-row {
    margin-bottom: 16px;

    &.active {
      margin-bottom: 8px;
    }

    .back {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;

      &.link {
        color: $gray-3;

        &:hover {
          color: $orange;
        }
      }

      .icon {
        margin-right: 4px;
      }
    }

    .actions {
      .action {
        font-size: 14px;

        +.action {
          margin-left: 24px;
        }

        .icon {
          margin-right: 4px;
        }
      }
    }
  }

  .bottom-row {
    .info {
      .service-type-icon {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }

      .name {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 30px;
      }

      .status {
        margin-left: 16px;
        height: 32px;
        font-weight: 500;
        font-size: 18px;

        ::v-deep .icon {
          font-size: 18px;
        }
      }

      .capacities {
        margin-left: 16px;

        .diamond-icon {
          margin: 0 8px;
          font-size: 4px;
          color: $gray-3;
        }
      }
    }

    .activate {
      .icon {
        margin-right: 8px;
      }
    }
  }
}

