
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.cancellation {
  .section {
    padding: 16px 24px;

    &:not(:first-child) {
      border-top: 1px solid $light-gray-3;
    }
  }

  .description {
    margin-top: 16px;

    ::v-deep .input-inner {
      height: 110px;
    }
  }
}

.success {
  padding: 64px 24px;

  .icon-container {
    width: 64px;
    height: 64px;

    .icon {
      font-size: 36px;
    }
  }

  .message {
    margin-top: 16px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 38px;
  }
}

