
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.mobiauto-version-row {
  display: grid;
  grid-template-columns: 50px 150px 1fr 150px 110px 100px 200px 50px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .alert-icon {
      font-size: 14px;
      color: $dark-yellow;
      font-weight: 300;
      margin-right: 4px;
    }

    .diamond-icon {
      margin: 0 4px 0 4px;
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
    }

    .discarded-icon {
      margin-right: 6px;
    }

    .icon-model {
      margin-right: 8px;
    }

    .text-secondary {
      font-family: $primary-monospace-font;
      font-size: 14px;
      font-weight: 400;
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }

      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .content:hover {
      .link,
      .diamond-icon {
        color: $orange;
      }
    }
  }
}

