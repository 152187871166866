
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-support-card {
  width: 271px;
  height: 185px;
  border: 1px solid $light-gray-3;
  border-radius: 16px;
  padding: 24px;

  &.active {
    border-color: $dark-green;
    box-shadow: 0px 2px 6px 0px $gray-2;
  }

  .top {
    margin-bottom: 24px;

    ::v-deep .menu-button {
      height: 24px;
      padding: 4px;
    }

    .status-loading {
      width: 74px;
    }

    .action-loading {
      width: 24px;
    }
  }

  .middle {
    .service-type-icon {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .service-name {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
      overflow-wrap: anywhere;
      color: $dark-gray;

      .name {
        height: 40px;
      }

      &:hover {
        color: $orange;
      }
    }
  }

  .bottom {
    height: 32px;

    .capacity {
      line-height: 16px;

      .total,
      .hourly {
        display: inline-block;
      }
    }

    .disabled {
      padding: 4px 0;
      font-style: italic;
    }

    .show-button {
      color: $gray-2;

      &:hover {
        color: $orange;
      }
    }
  }
}

