
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit-quote {
  .back{
    color: $dark-gray;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      color: $orange;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .load {
    margin-top: 18px;
  }

  .title {
    margin-top: 18px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 30px;
  }

  .cards {
    margin-top: 18px;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .history {
    margin-top: 16px;
  }

  .section {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $light-gray-4;

    &.footer {
      .save {
        margin-left: 24px;
        color: $dark-gray;

        &:hover {
          color: $orange;
        }
      }
    }

    .section-row {
      grid-template-columns: minmax(728px, 1fr) 448px;
      gap: 24px;

      .section-title,
      .subsection-title {
        font-family: $secondary-font;
        font-weight: 500;
      }

      .section-header {
        * + * {
          margin-left: 8px;
        }

        .section-title {
          font-size: 24px;
        }

        .id {
          ::v-deep .content {
            font-size: 20px;
          }
        }

        .pending {
          background-color: $light-gray-2;
          padding: 4px 12px;
          border-radius: 24px;
          font-size: 14px;
          font-weight: 500;
          font-family: $secondary-font;
        }
      }

      .date {
        font-size: 14px;

        .icon {
          font-size: 12px;
          color: $purple;
          margin-right: 8px;
        }
      }

      .wave-separator {
        height: 16px;
        border-radius: 4px;
        margin-top: 16px;

        &.quote-separator {
          background: var(--purple-waves-background)
        }

        &.service-order-separator {
          background: var(--orange-waves-background)
        }
      }

      .subsection-header {
        margin-bottom: 8px;

        .subsection-title {
          font-size: 16px;
        }
      }

      .icon-container {
        width: 16px;
        height: 16px;
        margin-right: 8px;

        .icon {
          font-size: 14px;
        }
      }

      .diamond-icon {
        font-size: 4px;
        color: $gray-3;
        margin: 0 8px;
      }

      .count {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 16px;
        color: $gray-3;
      }

      .description {
        font-size: 14px;
      }

      .summary {
        height: 60px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 0 16px 0 8px;

        .subtotal {
          width: 114px;
        }

        .summary-label {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 12px;
        }

        .summary-value {
          font-family: $primary-monospace-font;
          font-weight: 400;
          font-size: 12px;

          &.total {
            font-weight: 500;
            font-size: 18px;
          }
        }

        .total-container {
          .icon {
            margin-right: 4px;

            &.cheaper {
              color: $success;
            }

            &.more-expensive {
              color: $error;
            }
          }
        }
      }

      .attachments {
        grid-template-columns: minmax(212px, 1fr) minmax(212px, 1fr) minmax(212px, 1fr);
        gap: 8px 24px;
      }

      .empty {
        background-color: $light-gray-3;
        padding: 8px;
        border-radius: 4px;
        text-align: center;
        font-weight: 300;
        font-size: 12px;
        font-style: italic;
      }

      .quote-column {
        padding: 16px 0;

        .summary {
          background: var(--purple-light-waves-background), $light-gray;
        }
      }

      .service-order-column {
        padding: 0 16px;
        background-color: $light-gray;

        .section-title,
        .subsection-title {
          color: $gray-3;
        }

        .container {
          padding: 16px 0;
        }

        .summary {
          background: var(--orange-light-waves-background), $white;
        }
      }

      & + .section-row {
        .quote-column {
          border-top: 1px solid $light-gray-4;
        }

        .service-order-column {
          .container {
            border-top: 1px solid $light-gray-3;
          }
        }
      }

      &:last-child {
        .quote-column {
          padding: 16px 0 0 0;
        }
      }
    }
  }
}

