
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.contact-row {
  height: 60px;
  border-top: 1px solid $light-gray-3;

  &.error {
    border: 1px solid $error;
  }

  .initial {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $white;
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
    margin: 0 16px;
  }

  .info {
    margin-right: 16px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 500;
  }

  .name,
  .email,
  .phone,
  .area {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .area,
  .kind {
    font-weight: 300;
  }

  .kind {
    font-size: 12px;
    color: $gray-3;
  }

  .icon,
  .copy-icon {
    font-size: 14px;
    color: $gray-2;
  }

  .icon {
    margin-right: 8px;
  }

  .copy-icon,
  .count {
    margin-left: 8px;
  }

  .diamond-icon {
    margin: 0 8px;
    font-size: 4px;
    color: $gray-3;
  }

  .count {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $light-info;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 12px;
  }

  .destroyed {
    background-color: $light-gray-2;
    text-align: center;
    font-family: $secondary-font;
    font-weight: 400;

    .undo {
      font-weight: normal;
      margin-left: 8px;
    }
  }
}

