
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.deal-maintenance-packages-table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 130px 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .column {
    font-size: 12px;
    padding: 0 12px;

    .icon {
      color: $gray-2;
      font-size: 14px;

      & + .text {
        margin-left: 8px;
      }
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

