
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.deal-state-history {
  .initializing,
  .error {
    padding-top: 24px;
  }

  .error {
    .refetch,
    .retry {
      margin-left: 8px;
    }
  }
}

