
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.router-tab {
  $border-width: 4px;

  position: relative;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 16px;
  color: $gray-4;
  border: $border-width solid transparent;
  padding: 16px 0;
  cursor: pointer;
  user-select: none;

  &::after {
    content: "";
    position: absolute;
    left: -$border-width;
    bottom: -$border-width;
    width: calc(100% + #{$border-width * 2});
    display: block;
    height: $border-width;
    border-radius: 4px 4px 0 0;
  }

  &.error {
    color: $error;

    &.router-link-active,
    &.router-link-exact-active {
      color: $error;

      &::after {
        background-color: $error;
      }
    }
  }

  .error-badge {
    position: absolute;
    top: 12px;
    height: 5px;
    width: 5px;
    border-radius: 50px;
    background: $error;
  }

  &:hover {
    color: $primary;
  }

  &.router-link-active,
  &.router-link-exact-active {
    color: $primary;

    &::after {
      background-color: $primary;
    }
  }

  &.disabled {
    color: $light-gray-4;
    cursor: not-allowed;

    &.router-link-active,
    &.router-link-exact-active {
      &::after {
        background-color: $light-gray-4;
      }
    }
  }
}

.router-tab + .router-tab {
  margin-left: 32px;
}

