
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.id-tag {
  margin-left: 8px;
}

.app-button.deal-title {
  font-family: $secondary-font;
  font-weight: normal;
  font-size: 24px;
  color: $dark-gray;

  &:hover {
    color: $orange;
  }
}

