
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.user-button {
  height: $navbar-height;
  background-color: $white;

  &:hover {
    background-color: $light-brand;
    ::v-deep .button .border {
      border-color: $orange;
    }
  }

  &:active {
    background-color: $light-brand-2;
  }
}

::v-deep .button {
  border-left: 1px solid $light-gray-4;
  border-radius: 0;
  height: $navbar-height;
  padding: 0 24px 0 16px;

  .lines {
    width: 124px;
    margin-left: 8px;
  }

  .name {
    color: $text;
    font-weight: bold;
  }

  &:hover {
    .name, .role {
      color: $primary;
    }
  }

  .info {
    text-align: left;
    margin-left: 8px;
  }

  .role {
    font-size: 14px;
    display: block;
    color: $text;
  }
}

::v-deep.user-modal {
  .user-info {
    text-align: center;
    margin-top: 16px;
  }

  .icon {
    margin-right: 8px;
  }

  .action-button {
    margin-top: 16px;
  }

  .role {
    font-size: 14px;
    display: block;
    color: $gray-2;
  }
}

