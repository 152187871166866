
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.select-search-content {
  display: flex;
  flex-direction: column;

  &.left {
    align-items: flex-end;
  }

  &.right {
    align-items: flex-start;
  }

  .select-field-input {
    &:not(.open) {
      width: 100%;
    }

    &.open {
      min-width: 100%;
    }
  }
}

.tooltip-icon {
  margin-left: 8px;
}

.menu-wrapper {
  position: relative;
}

.menu-wrapper {
  width: var(--select-field-width);
  min-width: 100%;
}

.menu-content {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  border-radius: 5px;
  border: 1px solid $gray;
  z-index: 1000;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.input-wrapper {
  padding: 8px;
  border-bottom: 1px solid $gray;
  background: white;
  top: 0;
  position: relative;

  input {
    font-size: 16px;
    height: 40px;
    width: 100%;
    border: 1px solid $gray;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 35px;
  }

  .search-icon {
    position: absolute;
    top: 21px;
    right: 20px;
    color: $gray-2;
  }
}

.options-wrapper {
  max-height: 200px;
  overflow-y: auto;
}

.options-content {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.option {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray-3;
    cursor: pointer;

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }

    &.active {
      color: $primary;
      background: $light-gray-2;
    }

    &.focus {
      background: $light-gray-2;
      color: $dark-primary-2;

      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }
}

.hidden {
  opacity: 0;

  &, & > * {
    height: 0;
    border: 0;
    padding: 0;
    z-index: -1;
  }
}

.input {
  font-size: 0px;
}

.input-button {
  cursor: pointer;
  position: relative;
  background-color: $white;
  background-image: none;
  border-radius: 4px;
  border: 1px solid $gray;
  border-style:solid;
  box-sizing: border-box;
  color: $gray-3;
  display: inline-block;
  font-size: 16px;
  min-height: 40px;
  line-height: 36px;
  outline: none;
  padding: 1px 13px;
  padding-right: 68px;
  width: 100%;
  text-align: left;
  font-weight: 400;

  &:hover {
    border-color: $orange;

    .arrow-icon {
      color: $primary;
    }
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 2px;
    padding: 0 12px;
    padding-right: 67px;

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }

  &:focus,
  &.focus {
    border-style:solid;
    outline: none;
    border-color: $orange;
  }

  &.disabled {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-3;
    cursor: not-allowed;

    .value {
      color: $gray-2;
    }

    .arrow-icon {
      color: $light-gray-4;
    }

    &:hover {
      .arrow-icon {
        color: $light-gray-4;
      }
    }
  }
}

.placeholder {
  font-weight: 300;
  color: $gray;
}

.arrow-icon {
  position: absolute;
  width: 24px;
  height: 38px;
  right: 6px;
  top: calc(50% - 19px);
  font-size: 12px;
  text-align: center;
  transition: transform .2s ease;
  color: $dark-gray;

  &.open {
    height: 34px;
    color: $primary;
    top: calc(50% - 17px);
    transform: rotate(180deg);
  }
}

.clear-button {
  position: absolute;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  width: 24px;
  height: 38px;
  right: 36px;
  top: calc(50% - 19px);
  font-size: 16px;
  padding: 0;
  text-align: center;
  transition: transform .2s ease;
  color: $dark-gray;


  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 26px;
    border-radius: 50px;
    transition: all 100ms linear;
  }

  &.open {
    right: 35px;
    height: 36px;
    top: calc(50% - 18px);
  }

  &:hover {
    color: $primary;

    .remove-icon-content {
      background: $light-gray-3;
    }
  }
}

.input-button.error {
  border-color: $error;
}

.input-button.loading {
  background-color: $light-gray-3;
  border-color: $light-gray-4;
  color: $gray-3;
  cursor: progress;

  input {
    cursor: progress;
  }

  .arrow-icon {
    color: $light-gray-4;
  }

  &:hover {
    .arrow-icon {
      color: $light-gray-4;
    }
  }
}

.error-message {
  display: block;
  color: $error;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.label-content {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-family: $secondary-font;
  color: $dark-gray;
  transition: color 0.1s cubic-bezier(.645,.045,.355,1);

  > * + * {
    padding-left: 4px;
  }

  &.focus {
    color: $orange;
  }

  &.error {
    color: $error;
  }

  .mandatory-icon {
    font-size: 4px;
    color: $orange;
  }
}

.info-icon {
  font-size: 14px;
  cursor: pointer;
}

.value, .placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

