
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-preview-icon {
  .section {
    .icon {
      font-size: 14px;
      margin-right: 4px;

      &.check {
        color: $green;
      }

      &.scheduled {
        color: $yellow;
      }

      &.blocked {
        color: $red;
        cursor: pointer;
      }
    }
  }
}

.popover {
  padding: 16px;
}

