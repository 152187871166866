
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-attachments {
  .retry {
    margin-left: 4px;
  }

  .subsection-header {
    margin-bottom: 8px;

    span.refresh-button {
      cursor: pointer;

      &:hover {
        i, span {
          color: $dark-orange;
        }
      }
    }

    .icon-container {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      .icon {
        font-size: 14px;
      }
    }

    .subsection-title {
      font-size: 16px;
      font-family: $secondary-font;
      font-weight: 500;
      color: $gray-3;
    }

    .diamond-icon {
      font-size: 4px;
      color: $gray-3;
      margin: 0 8px;
    }

    .count, 
    .refresh-text {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 16px;
      color: $gray-3;
    }

    .add-attachment {
      font-size: 14px;
      margin-left: auto;
    }
  }

  .attachments {
    grid-template-columns: minmax(196px, 1fr) minmax(196px, 1fr);
    gap: 8px 24px;
  }

  .empty {
    background-color: $light-gray-3;
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    font-style: italic;
  }
}

