
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.checkbox-field {
  width: 40px;
  height: 40px;
  overflow: hidden;

  .loading-lines {
    height: 40px;
    display: flex;
    align-items: center;

    ::v-deep .loading-line {
      width: 100%;
    }
  }

  .checkbox {
    display: flex;
    height: 100%;

    .checkbox-input {
      appearance: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      border: 1px solid;
      border-color: $light-gray-3;
      border-radius: 4px;
      cursor: pointer;

      &:after {
        content: '\f2f7';
        font-family: 'Font Awesome 6 Pro';
        font-size: 14px;
        color: $gray-3;
        font-weight: 900;
      }

      &:focus {
        border-width: 2px;
        border-color: $orange;

        &:after {
          color: $dark-gray;
        }
      }

      &:hover {
        border-color: $orange;

        &:after {
          color: $orange;
        }
      }

      &:active {
        border-width: 2px;
        border-color: $dark-orange-2;
        background-color: $light-orange;

        &:after {
          color: $dark-orange-2;
        }
      }


      // checked
      // ---

      &:checked {
        border-color:     $light-orange;
        background-color: $light-orange;

        &:after {
          color: $orange;
        }

        &:focus {
          border-width: 2px;
          border-color: $orange;

          &:after {
            color: $orange;
          }
        }

        &:hover {
          border-color: $orange;

          &:after {
            color: $orange;
          }
        }

        &:active {
          border-width: 2px;
          border-color: $dark-orange-2;
          background-color: $light-orange;

          &:after {
            color: $dark-orange-2;
          }
        }
      } // :checked



      &.disabled {
        background-color: $light-gray-3;
        border-color: $light-gray-3;
        border-width: 1px;
        cursor: not-allowed;

        &:after {
          font-weight: 300;
          color: $gray-2;
        }

        &:checked:after {
          font-weight: 900;
        }
      }
    } // .checkbox-input
  } // .checkbox

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

