
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.monitoring {
  .header {
    .title {
      font-size: 30px;
      font-weight: 500;
      font-family: $secondary-font;

      .icon {
        font-size: 24px;
        margin-right: 18px;
      }
    }
  }

  .separator {
    border: none;
    border-top: 1px solid $light-gray-3;
    margin: 24px 0;
  }

  .list {
    .table {
      margin-top: 16px;
    }
  }
}

