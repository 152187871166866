
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-model-group-maintenance-tables {
  .section-header {
    // 25px para compensar a falta de label no campo de busca textual
    margin-bottom: calc(16px + 25px);

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .search {
    margin-bottom: 32px;
    margin-right: 40px;
  }

  .actions {
    text-align: right;
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .footer {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $light-gray-3;

    .icon {
      margin-right: 8px;
    }
  }
}

.row {
  border-bottom: 1px solid $light-gray-3;

  &:first-child {
    border-top: 1px solid $light-gray-3;
  }
}

.app-button + .app-button {
  margin-left: 24px;
}

