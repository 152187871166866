
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-issue-empty {
  text-align: center;
  font-size: 14px;
  padding: 20px 0;
}

.vehicle-issue-row {
  border: 1px solid $light-gray-3;
  border-bottom: none;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: normal;

  + .vehicle-issue-row {
    border-top: 1px solid $light-gray-3;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom: 1px solid $light-gray-3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.name {
  display: block;
}

.description {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

::v-deep .vehicle-issue-row.disabled {
  background-color: $light-gray-3;
}

