
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-timeline {

  > .timeline {
    padding: 8px 0;

    .stage {
      position: relative;

      border-radius: 8px;
      border: 1px solid $light-gray-3;

      width:  26px;
      height: 26px;

      cursor: pointer;
      background: white;
      color: $gray;

      .icon {
        font-weight: 400;  // font-awesome outline
        font-size: 14px;
        color: $gray;

        &.stacked {
          --stacked-icon-size: 12px;
          position: absolute;
          right: calc(-1 * var(--stacked-icon-size) / 3);
          top:   calc(-1 * var(--stacked-icon-size) / 3);
          font-size: var(--stacked-icon-size) !important;
          border-radius: var(--stacked-icon-size);
          padding: 2px;
          background: white;

          &.warning {
            color: $dark-yellow;
          }
        }
      }

      &.past {
        border: 1px solid $light-purple-3;
        color: $purple;

        .icon {
          font-weight: 900;  // font-awesome solid
          color: $purple;
        }

        &:hover {
          border-color: $purple;
        }
      }

      &.current {
        border: 1px solid $light-purple;

        width:  40px;
        height: 40px;

        background: $purple;

        .icon {
          font-weight: 900;  // font-awesome solid
          font-size: 16px;
          color: $white;
        }

        &:hover {
          border-color: $light-purple-2;
        }

        &:active {
          background: $dark-purple;
        }
      }

      &.warning {
        background: $dark-yellow;

        .icon {
          font-weight: 900; // font-awesome solid
          color: $white;
        }

        &:active {
          // TODO: $dark-yellow-2
          background: $dark-yellow;
        }
      }

      &.canceled {
        background: $gray-2;

        .icon {
          font-weight: 900; // font-awesome solid
          color: $white;
        }

        &:active {
          background: $gray-4;
        }
      }
    } // .stage

    .connector {
      width: 16px;
      border-top: 1px solid $light-gray-3;

      &.past,
      &.current {
        border-color: $light-purple-3;
      }
    }
  } // .timeline


  > .description {
  } // .description

  .text {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;

    vertical-align: center;

    &.label {
      color: $brand-alt;
    }

    &.tip {
      color: $dark-gray;
    }

    &.canceled {
      color: $dark-gray;
      padding-left: 6px;
    }
  }

}

