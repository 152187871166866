
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.service-order-section-layout {
  color: $dark-gray;
  display: flex;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  gap: 16px;
  line-height: 1.5;
}

.icon {
  align-items: center;
  color: $gray-3;
  display: flex;
  flex-shrink: 0;
  font-size: 16px;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.content {
  flex-grow: 1;
}

.header {
  align-items: baseline;
  display: flex;
  margin-bottom: 10px;
  min-height: 24px;

  .title {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  .separator {
    align-self: center;
    margin: 0 8px;
  }
}

.empty {
  background: $light-gray;
  border-radius: 4px;
  color: $gray-3;
  font-size: 12px;
  font-style: italic;
  padding: 8px;
}
