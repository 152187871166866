
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-search-form {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 32px 0 16px 0;

  .car-icon {
    height: 100%;
    margin-bottom: 8px;
  }

  .message {
    font-family: $secondary-font;
    font-weight: 400;
    color: $gray-3;
  }

  .search-field {
    width: 448px;
    margin-top: 16px;

    ::v-deep input:not(:placeholder-shown) {
      text-transform: uppercase;
    }
  }

  .error {
    margin-top: 16px;

    .not-found-icon {
      margin-right: 8px;
    }
    .message {
      font-size: 16px;
      color: $error;
    }
  }
}

