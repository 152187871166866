
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.fipe-pre {
  font-family: $primary-monospace-font;
  margin: 0 2px 0 0;
}

