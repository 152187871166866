
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.facilities-tag {
  background: #FFEEDF;
  border-radius: 32px;
  font-family: $secondary-font;

  &.normal {
    padding: 0px 4px;
    height: 19px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    .icon {
      font-size: 12px;
    }
  }

  &.big {
    padding: 2px 8px;
    height: 26px;
    font-weight: 500;
    font-size: 16px;
  }

  &.disabled {
    color: $gray-2;
    background-color: $light-gray-2;
  }
}

.icon {
  width: 20px;
  text-align: center;
  margin-right: 4px;
}

.external {
  background: $light-green;
}

