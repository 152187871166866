
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.code-col {
  width: 130px;
}

.type-icon {
  margin-right: 8px;
  height: 18px;
}

.type-text {
  font-size: 14px;
  font-weight: normal;
}

.type-col {
  width: 250px;
}

.price-col {
  text-align: right;
  width: 150px;
}

.price  {
  &.free {
    text-decoration-line: line-through;
    color: $gray-2;
  }
}

.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

