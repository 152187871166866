
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.service-orders-table-paginator {
  background: $light-gray;
  gap: 24px;
  height: 48px;
}

::v-deep .paginator {
  background: transparent;

  .loading & {
    width: 100%;
    max-width: 400px;
  }

  .btn {
    background: transparent;
    margin: 0 8px;

    &:not(:first-child):not(:last-child) {
      font-weight: 500;
      height: 24px;
      width: 24px;
    }

    &.current {
      background: $light-gray-3;
    }
  }
}

.page-input {
  width: 90px;
}
