
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.search-form {
  .form {
    width: 100%;

    .row + .row {
      margin-top: 8px;
    }
  }
}

