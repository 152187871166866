
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.store-service-supports {
  .header {
    margin-top: 32px;
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: 500;
    }
  }

  .cards {
    margin-bottom: -24px;

    .card {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
}

