
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.label {
  color: $dark-gray;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 8px;
}
.finder-field:focus-within .label {
  color: $primary;
}
.has-error .label {
  color: $error;
}

.error-message {
  color: $error;
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}
