
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.tag {
  font-family: $primary-monospace-font;

  .icon {
    margin-right: 4px;
  }

  .normal {
    .icon {
      font-size: 12px;
    }
  }

  .big {
    .icon {
      font-size: 18px;
    }
  }

  .small {
    .icon {
      font-size: 10px;
    }
  }
}

.disabled {
  ::v-deep .app-tag-label {
    background-color: $light-gray-4;
    color: $gray-3;
  }
}

