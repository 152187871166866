
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.popover {
  .popover-title {
    &.active {
      cursor: pointer;
    }

    .icon {
      color: $gray-2;
      margin-right: 4px;
    }
  }

  .vehicle-issues-preview {
    min-width: 128px;
    max-width: 450px;
    max-height: 450px;
    padding: 16px;
    overflow-y: scroll;

    .title {
      font-family: $secondary-font;
      font-weight: 400;
      margin-top: 8px;

      .icon {
        margin-right: 8px;
      }

      .id-tag {
        margin-left: 4px;
      }
    }

    .symptom-row {
      margin-top: 4px;
      margin-left: 24px;

      .icon {
        margin-right: 8px;
        color: $gray-2;
      }

      .text {
        font-family: $secondary-font;
        font-weight: 400;
      }
    }
  }
}

