
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-search-step {
  .header {
    margin-bottom: 24px;

    .title,
    .subtitle {
      font-family: $secondary-font;
      font-weight: 500;
    }

    .title {
      font-size: 38px;
    }

    .subtitle {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      padding-top: 24px;
      margin-top: 24px;
    }

    &.footer {
      .cancel {
        color: $gray-3;

        &:hover {
          color: $orange;
        }
      }

      .icon {
        margin-left: 8px;
      }
    }
  }
}

