
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.deal-state-change-row {
  .top {
    padding-top: 16px;
    border-left: 1px solid $light-gray-3;
    margin-left: 12px;
  }

  .header {
    margin: 8px 0;

    .icon-container {
      width: 24px;
      margin-right: 24px;
    }

    .status {
      font-weight: 400;
      margin-right: 8px;
    }

    .info {
      white-space: pre;
    }

    .deal-link {
      .icon {
        margin-right: 8px;
      }

      .deal {
        font-family: $primary-font;
        font-weight: 300;
      }
    }
  }

  .bottom {
    padding-bottom: 16px;
    border-left: 1px solid $light-gray-3;
    margin-left: 12px;

    .description {
      margin-left: 35px;
      font-size: 14px;
      white-space: pre;
    }
  }
}

