
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-category-tag {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: $dark-gray;

  padding: 2px 4px;
  border-bottom: 1px solid;
  border-radius: 4px;


  // themes
  // ---

  &.operational {
    background: $light-gray-3;
    border-bottom-color: $light-gray-4;
  }

  &.premium {
    background: linear-gradient(180deg, #FFF4D1 40.1%, #ECD172 100%);
    border-bottom-color: #AB8C50;
  }

  &.super_vip {
    background: linear-gradient(180deg, #F6F6F6 0%, #C4CFD9 100%);
    border-bottom-color: #1A4F7D;
  }

  &.vip {
    background: linear-gradient(180deg, #FFEED9 0%, #FFC690 100%);
    border-bottom-color: #AE4D34;
  }

  &.other {
    background: linear-gradient(180deg, #F6F6F6 0%, #D4D4D4 100%);
  }

}

