
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.maintenance-table {
  padding: 24px;

  .header {
    margin-bottom: 24px;
  }

  .table-name {
    font-size: 20px;
    font-weight: 500;
    font-family: $secondary-font;
    word-break: normal;
    overflow-wrap: break-word;
  }

  .icon {
    font-size: 16px;
    margin-right: 8px;
  }

  .description-content {
    .description {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
    }
  }

  .maintenances {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 24px 0;
  }

  .table-content {
    padding-top: 24px;
  }

  .info {
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
  }

  .info-label + .info-label {
    margin-left: 40px;
  }
}

