
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.product-part-icon {
  --color: #{$gray-3};

  .icon {
    height: 100%;
    height: var(--size);
    width: var(--size);
    mask-image: var(--svg-icon);
    background-color: var(--color);
    mask-size: cover;
  }
}

