
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


#viewport {
  width: 100%;
  height: 100vh;
  position: relative;

  &.outdated {
    height: calc(100vh - #{$outdated-banner-height});
  }
}

::v-deep .app-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: $primary;

  & .indeterminate {
    background-color: $light-primary-2;
  }
}

.layout {
  height: 100%;
}

