
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.last-tickets-preview {
  .upper-row,
  .results {
    margin-bottom: 8px;
  }

  .upper-row {
    .title,
    .all {
      font-family: $secondary-font;
      font-weight: 500;
    }

    .title {
      font-size: 18px;
    }

    .all {
      font-size: 14px;
      color: $dark-gray;

      &:hover {
        color: $orange;
      }

      .icon {
        margin-right: 4px;
      }
    }
  }

  .retry {
    margin-left: 4px;
  }

  .results {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
  }

  .table {
    background-color: $light-gray;
    border-radius: 8px;

    .service-order-row + .service-order-row {
      border-top: 1px solid $light-gray-3;
    }
  }
}

