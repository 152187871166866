
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.actions {
  margin-bottom: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.info-label + .info-label {
  margin-top: 10px;
}

.column + .column {
  margin-left: 78px;
}

.section {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

label {
  display: block;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.label-text {
  font-size: 16px;
  display: inline;
  font-weight: normal;
  margin-right: 8px;
}

.section-title {
  margin-bottom: 8px;
  font-family: $secondary-font;
  font-weight: normal;

  .icon {
    margin-right: 16px;
  }
}

.sectors-content {
  padding-left: 33px;
}

.address-content {
  margin-bottom: 12px;
}

