
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.navbar {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  background-color: $white;
  // z-index não funciona com "position: static", ref https://stackoverflow.com/a/8486532/4301486
  position: relative;
  z-index: 1;
  width: 100%;
  top: 0;
}

.stamp {
  position: absolute;
  top: 2px;
  left: 290px;
}

.home-button {
  margin-left: 24px;

  .logo {
    width: 77px;
    height: 23px;
  }
}

.divider {
  height: 16px;
  width: 1px;
  background-color: $light-gray-4;
  margin: 0 24px;
}

.gmf {
  width: 58px;
  height: 18px;
}

.gmf-name {
  margin-top: 2px;
  margin-left: 8px;
  font-family: $secondary-font;
  color: $dark-gray;
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
  text-align: left;
}

