
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.email-kind-select-field {
  .icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-right: 8px;
  }
}

