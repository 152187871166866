
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.drop-zone {
  background-color: $light-gray;
  height: 97px;
  border-radius: 8px;
  border: 2px dashed $light-gray-4;

  .message {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
    color: $gray-3;
  }

  .button {
    font-size: 14px;
    margin-top: 8px;

    .icon {
      margin-right: 4px;
    }
  }

  &.dragover {
    border-color: $primary;
    background: $light-orange;

    .message {
      color: $primary;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}

.input {
  display: none;
}
