
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit {
  .back {
    font-size: 14px;
    margin-bottom: 12px;

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .date {
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    padding: 16px;
    width: 330px;
    height: 95px;
  }

  .pickup-service {
    margin-top: 16px;
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    padding: 8px 16px;

    .icon-container {
      width: 24px;
      height: 24px;
      font-size: 16px;
      margin-right: 8px;
      color: $gray-3;
    }

    .value-text {
      color: $dark-gray;
      font-weight: 400;
    }
  }
}

