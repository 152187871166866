
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.search-form {
  width: 100%;

  .row, .row-bottom {
    align-items: end;
    padding-bottom: 12px;
    display: grid;
    gap: 16px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:nth-child(2) {
      grid-template-columns: 1fr;
    }

    &:nth-child(3) {
      grid-template-columns: 1fr 1fr;
    }

    &:nth-child(4) {
      grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    }

    grid-template-columns: 3fr 2fr;
  }

  .submit {
    min-width: unset;
    padding: 0;
  }

  .hint {
    display: block;
    color: #666;
    margin-top: -12px;

    i {
      margin-right: 10px;
    }
  }
}
