
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.id-tag {
  margin-left: 8px;
}

.info + .info {
  margin-left: 24px;
}

.nullable {
  min-width: 107px;
}

