
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.section {
  padding: 24px;
}

.fields {
  border-bottom: 1px solid $light-gray-3;
}

.ui-row + .ui-row {
  margin-top: 24px;
}

