
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.error {
  margin-top: 8px;
}

.add {
  margin-left: 16px;
}

