
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.header {
  margin-bottom: 24px;
}

.table-name {
  font-size: 20px;
  font-weight: 500;
  font-family: $secondary-font;
  word-break: normal;
  overflow-wrap: break-word;
}

.id-tag {
  margin-left: 16px;
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.description-content {
  .description {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}

.maintenances {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding: 24px 0;
}

.table-content {
  padding-top: 24px;
  margin-bottom: 24px;
}

.info {
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.info-label + .info-label {
  margin-left: 40px;
}

.warning {
  color: $dark-warning;
}

.back {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;

  &::v-deep.link {
    color: $gray-2;

    &:hover {
      color: $gray-3;
    }
  }

  .icon {
    margin-right: 8px;
    font-size: 12px;
  }
}

.tag {
  margin-left: 8px;
}

.footer {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

