
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.phone-fields {
  .action-icons {
    margin-left: 18px;
    width: 60px;
    margin-top: 10px;

    &.first {
      margin-top: 34px;
    }

    .icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }

    .button + .button {
      margin-left: 20px;
    }

    .clear {
      color: $gray-2;

      &:hover {
        color: $black;
      }
    }
  }
}

