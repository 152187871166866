
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form-fields {
  .section {
    padding: 16px 24px;

    & + .section {
      border-top: 1px solid $light-gray-3;
    }

    &.service {
      .vehicle-issue-message {
        margin-top: 16px;
        font-size: 14px;

        .icon {
          color: $warning;
          margin-right: 8px;
        }

        .message {
          font-weight: 400;
          color: $gray-3;
        }
      }
    }
  }
}

