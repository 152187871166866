
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit-products {
  .section-header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .subtitle {
      display: block;
      margin-top: 4px;
    }
  }

  .table {
    margin-top: 16px;

    .count {
      margin-bottom: 8px;
      text-align: right;
    }

    .no-products {
      padding: 24px;
      border: 1px solid $light-gray-3;
      border-radius: 4px;
      color: $gray-3;
      font-size: 14px;

      .icon {
        user-select: none;
        margin-bottom: 8px;
        height: 78px;
        width: 89px;
      }
    }
  }
}

