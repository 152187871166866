
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-row {
  border-top: 1px solid $light-gray-3;

  .service-row-container {
    height: 69px;

    &.destroyed {
      background-color: $light-gray-2;
      font-family: $secondary-font;
      font-weight: 400;

      .undo {
        font-weight: normal;
        margin-left: 8px;
      }
    }

    &:hover {
      background-color: $light-gray;
    }

    &.disabled {
      background-color: $light-gray-2;
      cursor: not-allowed;
    }

    .side-label {
      width: 8px;
      height: 100%;
      background-color: $light-gray-2;

      &.active {
        background-color: $success;
      }
    }

    .spacer {
      width: 48px;
      height: 100%;

      .arrow-button {
        &.movida-common__app-button.link {
          color: $gray-3;

          &:hover {
            color: $orange;
            border-radius: 50%;
            background-color: $light-gray-3;
          }
        }

        .tree-icon {
          cursor: pointer;
          font-weight: 900;
          width: 20px;
          height: 20px;
          font-size: 14px;
          transition: transform .1s ease;

          &.leaf {
            font-size: 16px;
            font-weight: 300;
            cursor: unset;
          }

          &.open {
            transform: rotate(90deg);
          }
        }
      }

      .status-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: $dark-success;
      }
    }

    .content-column {
      overflow: hidden;

      .content {
        overflow: hidden;
        margin-left: 12px;
        margin-right: 24px;

        .service-name {
          font-size: 16px;
          font-weight: normal;
          color: $dark-gray;
          word-break: normal;
          overflow-wrap: break-word;
          margin-bottom: 4px;
          display: block;

          &.active:hover {
            color: $primary;
          }
        }

        .description {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;

          font-size: 12px;
        }
      }
    }

    .right-side {
      .added {
        color: $success;
        font-size: 14px;
        font-weight: 400;

        span {
          font-style: italic;
          margin-left: 4px;
        }
      }

      .actions {
        margin: 0 26px;

        .remove-button {
          font-size: 16px;

          &:hover {
            background-color: $light-gray-3;
            transition: 0s;
          }
        }
      }
    }
  }
}

