
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form {
  .body {
    padding: 16px 24px;
  }

  .footer {
    padding: 16px 24px;
    border-top: 1px solid $light-gray-3;
  }
}

