
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


tr {
  &:first-child {

    td {
      padding-top: 12px;
    }
  }
}

td {
  vertical-align: top;
  padding: 4px;
}

.index {
  margin-top: 8px;
  display: block;
  background-color: $light-gray-3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-family: $secondary-font;
  margin-left: 8px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}

.toggle-field {
  display: inline-block;
  padding-top: 4px;

  ::v-deep .checkbox-input {
    width: 44px;
    height: 32px;
    border-radius: 32px;

    &:not(:checked) {
      border: 1px solid $light-gray-3;
    }
  }
}

.fields-content {
  display: flex;

  .kilometrage-limit {
    width: 110px;
    padding-right: 4px;
  }

  .time-limit {
    width: 105px;
    padding-left: 4px;
  }
}

.error-message {
  display: block;
  color: $error;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.remove-button {
  width: 32px;
  height: 32px;
  border: 1px solid $light-gray-3;
  border-radius: 50%;
  cursor: pointer;
  margin: 4px auto 0;

  .remove-icon {
    color: $error;
  }
}

