
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.index {
  .search-form {
    margin-bottom: 24px;

    .starting-at-field {
      margin-right: 24px;
    }
  }

  .empty {
    padding: 24px 0;
    background-color: $light-gray;
    border-radius: 8px;

    .not-found-icon {
      margin-bottom: 8px;
    }

    .message {
      font-weight: 400;
      font-size: 14px;
      font-style: italic;
      color: $gray-3;
    }
  }

  .suppliers-rows{
    border: 1px solid $light-gray-3;
    border-radius: 8px;

    .supplier-row + .supplier-row {
      border-top: 1px solid $light-gray-3;
    }
  }
}

