
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.money-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $dark-gray;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .input-wrapper {
    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .input-inner {
      font-family: $primary-monospace-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: 4px;
      border: 1px solid $gray;
      color: $gray-3;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: none;
      padding: 1px 13px;
      width: 100%;

      text-align: right;

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
        padding: 0 12px;
      }

      &::placeholder {
        font-family: $primary-monospace-font;
        font-weight: 300;
        color: $gray-2;
        opacity: 1;
      }

      &.disabled {
        background-color: $light-gray-3;
        border-color: $light-gray-4;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $light-gray-3;
        border-color: $light-gray-4;
        color: $gray-3;
        cursor: progress;
      }

      &.readonly {
        background-color: $light-gray-2;
        border-width: 0;
        color: $dark-gray;
        cursor: unset;
      }

      &.error {
        border-color: $red;
      }
    }

    .suffix {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      position: absolute;
      top: 12px;
      right: 0;
      display: block;
      padding: 0 12px;
      user-select: none;
      pointer-events: none;
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    text-align: right;
  }
}

