
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.container {
  background-color: $light-gray-2;
  padding: 24px;
  border: 1px solid;
  border-radius: 4px;
}

.header {
  font-family: $secondary-font;
  font-weight: 500;

  .toggle {
    cursor: pointer;
    user-select: none;

    .icon {
      margin-left: 10px;
      font-size: 12px;
    }
  }
}

.body {
  overflow: hidden;
  transition:height 0.3s ease-out;
  height: 0;
}

.toggle-icon {
  transition: transform .2s ease;

  &.collapsed {
    transform: rotate(180deg);
  }
}

