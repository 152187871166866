
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit {
  .back,
  .cancel {
    .icon {
      margin-right: 4px;
    }
  }

  .back {
    color: $dark-gray;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      color: $orange;
    }

    .icon {
      font-size: 12px;
    }
  }

  .load {
    margin-top: 16px;
  }

  .title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 30px;
    margin-top: 16px;
  }

  .cards {
    margin-top: 16px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 24px;
  }

  .history {
    margin-top: 16px;
  }

  .service-order {
    margin-top: 24px;
  }

  .section {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $light-gray-4;
  }

  .attachments {
    > .label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: "Rubik", Helvetica, Arial, sans-serif;
      color: #333333;
    }

    .error-message {
      color: $dark-red;
      font-size: 12px;
    }
  } // .attachments


  .fields {
    padding-bottom: 30px;

    .form-title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 24px;
    }

    .waves-separator {
      margin-top: 8px;
      background: var(--purple-waves-background);
      height: 16px;
      border-radius: 4px;
    }

    .summary,
    .form-fields,
    .extra {
      margin-top: 40px;
    }

    .summary {
      padding: 16px;
      background: var(--purple-light-waves-background), $light-gray;
      border-radius: 8px;

      .label {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 12px;
      }

      .subtotal + .subtotal {
        margin-left: 24px;
      }

      .price {
        font-family: $primary-monospace-font;
        font-size: 12px;
        font-weight: 400;

        &.total-price {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .extra {
      .section-title {
        margin-bottom: 16px;

        .icon-container {
          width: 16px;
          height: 16px;
          margin-right: 8px;

          .icon {
            font-size: 14px;
          }
        }
      }

      .fields {
        grid-template-columns: repeat(3, minmax(150px, 1fr));
        gap: 8px;

        //- XXX: gtf tem 4 colunas
        &.gtf {
          grid-template-columns: repeat(4, minmax(150px, 1fr));
        }

        .note {
          font-style: italic;
          font-weight: 300;
          font-size: 12px;
          margin-top: 4px;
          text-align: right;
        }
      }
    }
  }

  .footer {
    .button {
      & + .button {
        margin-left: 24px;
      }
    }

    .save {
      font-size: 14px;
      color: $dark-gray;

      &:hover {
        color: $orange;
      }
    }

    .submit {
      .icon {
        margin-left: 8px;
      }
    }
  }
}

