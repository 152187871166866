
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.index {
  .header {
    margin-top: 24px;
    margin-bottom: 32px;

    .title {
      .title-text {
        font-size: 30px;
        font-weight: normal;
        font-weight: 500;
        font-family: $secondary-font;
      }
    }

    .actions {
      .app-button + .app-button {
        margin-left: 8px;
      }

      .fa-link {
        margin-right: 8px;
      }
    }
  }

  .no-resource-registered {
    padding: 24px;
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    color: $gray-3;
    font-size: 14px;

    .no-resource-registered-icon {
      user-select: none;
      margin-bottom: 8px;
      height: 78px;
      width: 89px;
    }
  }

  .deal-info-card + .deal-info-card {
    margin-top: 24px;
  }
}

