
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-plate {
  background-color: $white;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid $light-gray-4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 16px;
  }
}

