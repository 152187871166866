
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vetor-code-tag {
  overflow: hidden;

  &.disabled {
    ::v-deep .app-tag-label {
      color: $gray-2;
      background-color: $light-gray-2;

      .outside {
        fill: $light-gray-4;
      }
    }
  }
}

