
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.initials-tag {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 14px;
  padding: 3.5px 7px;
  border-radius: 80px;
  margin: 0 16px;


  // themes
  // ---

  &.blue {
    background-color: #DDF3FF;
    color: #0F6DC5;
  }

  &.brown {
    background-color: #EFE1DC;
    color: #6A4646;
  }

  &.gray {
    background-color: #E7E7E7;
    color: #464646
  }

  &.orange {
    background-color: #FFF0E2;
    color: #DB6B1A;
  }

  &.pink {
    background-color: #FFDDFC;
    color: #CB36A1;
  }

  &.purple {
    background-color: #ECDDFF;
    color: #8A36CC;
  }

  &.red {
    background-color: #FFEAEA;
    color: #B01A1A;
  }

  &.yellow {
    background-color: #FFF8D0;
    color: #C29800;
  }


}

