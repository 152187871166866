
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title-icon {
  margin-right: 16px;
  font-size: 30px;
}

.title-text {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
}

.search {
  margin-bottom: 28px;
}

.paginator-content {
  padding: 14px;
  margin: 0 auto;
  &.loading {
    max-width: 400px;
  }
}

.list-info {
  padding: 0 4px;
}

.selected-info {
  border: 1px solid $light-purple-2;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.empty-selected {
  color: $gray-2;
  font-style: italic;
}

.list-header {
  padding: 8px;
}

.secondary-button + .secondary-button {
  margin-left: 8px;
}

.secondary-button {
  color: $light-gray-4;
  font-weight: normal;

  &.active {
    color: $dark-gray;
  }

  .secondary-text {
    font-size: 14px;
  }
}

.icon-container {
  width: 12px;
  height: 12px;
  margin-right: 12px;

  .icon {
    font-size: 16px;
  }
}


