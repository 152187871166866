
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.sector-empty {
  text-align: center;
  font-size: 14px;
  padding: 8px 0;
}

.sectors-row {
  border: 1px solid $light-gray-3;
  border-bottom: none;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: normal;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom: 1px solid $light-gray-3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.content {
  width: 100%;
  overflow: hidden;
}

.name {
  display: block;
}

.description {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

.show {
  .sectors-row {
    border: none;
    padding: 0;
    margin-bottom: 16px;
  }

  .name {
    font-weight: 300;
  }
}

.actions-column {
  flex-shrink: 0;
}

