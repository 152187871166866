
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.attachment-file-type {
    font-size: 12px;
    font-family: $secondary-font;
    font-weight: 400;

    .icon {
      font-size: 16px;
      margin-right: 8px;
      color: var(--color);
    }
}

