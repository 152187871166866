
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.mobiauto-version-option {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  &.active {
    background: $light-gray-2;

    & .mobiauto-version-name {
      color: $primary;
    }
  }

  &.focus {
    background: $light-gray-2;

    & .mobiauto-version-name {
      color: $dark-primary-2;
    }

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.mobiauto-version-option + .mobiauto-version-option {
  border-top: 1px solid transparent;
}

.mobiauto-version-name {
  font-size: 18px;
  font-weight: normal;
  color: $gray-3;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;
}

.content-column {
  flex-grow: 1;
}

.actions-column {
  min-width: 42px;
  margin-left: 12px;
}

.remove-icon-content {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50px;
  color: $gray-2;
  transition: all 100ms linear;
}

.diamond-icon {
  font-size: 4px;
  font-weight: 900;
  color: $gray-3;
  width: 4px;
  height: 4px;
  margin: 8px;
}

.year-range {
  font-weight: 300;
  font-size: 12px;
  color: $gray-3;
  margin-left: 8px;
}

