
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-info {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 16px;

  .icon-container {
    margin-right: 8px;
  }

  .light-text {
    font-weight: 300;
    font-size: 14px;
  }

  .diamond-icon {
    font-size: 4px;
    color: $gray-3;
    margin: 0 8px;
  }

  .header {
    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
      margin-right: 8px;
    }

    .icon-container {
      width: 14px;
      height: 14px;

      .icon {
        font-size: 12px;
        color: $purple;
      }
    }

    .light-text + .light-text {
      margin-left: 8px;
    }

    .content-toggle {
      color: $dark-gray;
      font-size: 14px;

      &:hover {
        color: $orange;
      }

      .icon {
        font-size: 12px;
        margin-right: 4px;
      }
    }
  }

  .data-container {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $light-gray-4;

    .data {
      padding: 8px 16px 16px 16px;
      gap: 24px;

      .row {
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        + .row {
          .section {
            padding-top: 24px;
            border-top: 1px solid $light-gray-4;
          }
        }
      }

      .section {
        .section-header {
          margin-bottom: 8px;
        }

        .icon-container {
          width: 16px;
          height: 16px;

          .icon {
            font-size: 14px;
          }
        }

        .section-title {
          font-family: $secondary-font;
          font-weight: 500;
          font-size: 16px;
        }

        .count {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 16px;
          color: $gray-3;
        }

        .empty-section {
          background-color: $light-gray-3;
          padding: 8px;
          border-radius: 4px;
          font-style: italic;
          font-weight: 300;
          font-size: 12px;
          text-align: center;
        }

        .resources-table {
          border: 1px solid $light-gray-4;
          border-radius: 4px;

          .table-row {
            grid-template-columns: 1fr 55px;
            height: 40px;

            & + .table-row {
              border-top: 1px solid $light-gray-4;
            }
          }

          .column-header {
            font-family: $secondary-font;
            font-weight: 400;
            font-size: 12px;
          }

          .column {
            padding: 0 8px;
          }

          .name,
          .part-origin {
            font-family: $secondary-font;
            font-weight: 400;
            font-size: 14px;
          }

          .quantity {
            font-family: $primary-monospace-font;
            font-weight: 400;
            font-size: 14px;
          }

          .diamond-icon {
            margin: 0 4px;
          }

          .part-origin {
            color: $gray-3;
          }
        }

        .service-order-attachments {
          ::v-deep .subsection-title {
            color: $dark-gray;
          }
        }
      }
    }
  }
}

