
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.popover-reload {
  font-size: 14px;

  .icon {
    color: $dark-warning;
  }

  * + * {
    margin-top: 4px;
  }
}

