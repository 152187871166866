
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.confirmation-wizard-section {
  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      margin-top: 24px;
      padding-top: 24px;
    }

    &.header {
      margin-bottom: 8px;

      .id-tag {
        margin: 0 8px;
      }

      .title,
      .subtitle {
        font-family: $secondary-font;
        font-weight: 500;
      }

      .subtitle {
        font-size: 20px;
      }

      .title {
        font-size: 38px;
      }
    }

    .section-header {
      margin-bottom: 16px;

      .section-title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 20px;
      }

      .section-subtitle,
      .edit,
      .edit .link {
        font-size: 14px;
        font-weight: 400;
      }

      .edit {
        .link {
          margin-left: 4px;
        }
      }
    }

    &.store-section,
    &.date-section {
      .icon-container {
        width: 24px;
        height: 24px;
        margin-right: 16px;

        &.store-icon {
          background-color: $purple;
        }

        .icon {
          font-size: 20px;
          color: $purple;
        }
      }
    }

    &.store-section,
    &.supplier-section {
      .text-title {
        margin-bottom: 4px;
      }
    }

    &.description-section {
      .description {
        font-weight: 400;
      }
    }

    .text-title {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 18px;
    }

    .diamond-icon {
      color: $gray-3;
      font-size: 4px;
      margin: 0 4px;
    }

    .empty-section {
      max-height: 238px;

      .illustration {
        --size: 48px;
        width: var(--size);
        height: var(--size);
      }

      .empty-message {
        font-weight: 300;
        font-size: 14px;
        font-style: italic;
        margin-top: 8px;
      }
    }

    .pickup-service {
      margin-top: 16px;

      .icon-container {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .description-label {
        margin-left: 32px;
      }
    }
  }

  .footer {
    background-color: $light-gray;
    padding: 16px 24px;
    border-radius: 8px;
    margin-top: 24px;

    .back {
      margin-right: 24px;

      .icon {
        margin-right: 8px;
      }
    }

    .next .icon {
      margin-left: 8px;
    }
  }
}

.confirmation-modal {
  height: 255px;

  .icon-container {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $light-green;

    &.loading {
      background-color: $light-gray;

      .icon {
        color: $orange;
      }
    }

    .icon {
      font-size: 36px;
      color: $dark-green;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
        line-height: 0.75em;
      }
    }
  }

  .success-title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 38px;
    margin: 16px 0;
  }

  .success-text {
    font-weight: 400;
    font-size: 20px;
  }
}

