
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.state-tag {
  display: flex;
  flex-direction: row;
  align-items: center; // or baseline

  padding: 2px 8px;
  gap: 4px;

  background: $light-gray-3;
  border-radius: 4px;
}

.icon {
  font-size: 14px;
  line-height: 14px;

  width: 20px;
  text-align: center;
}

.text {
  color: $dark-gray;
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

  .state-tag.small & {
    font-size: 12px;
  }
}

