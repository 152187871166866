
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.show.message {
  padding: 24px;

  // > .loading {}

  > .erred {
    .retry {
      margin-left: 4px;
    }
  }


  > .content {

    > .header {
      .title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 24px;
      }

      .actions {
        .btn {
          .icon {
            margin-right: 8px;
          }
        }
      }
    }

    > .body {
      margin: 16px 0 0 0;
    }
  } // .content
}


.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}


