
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.app-list.default {
  border: 1px solid $light-gray-3;
  border-radius: 4px;

  &.validation-error {
    border-color: $error;
  }

  .empty,
  .error {
    text-align: center;
    font-style: italic;
    padding: 32px 0;
    width: 100%;

    color: $gray-3;
  }

  .table-edge {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-height: 8px;
    background: $light-gray;
  }

  .list-content {
    overflow: hidden;
    overflow-y: auto;
  }
}

/* .app-list.none {

} */

