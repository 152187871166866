
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-items-table {
  background-color: $white;
  border-radius: 4px;

  .empty {
    background-color: $light-gray-3;
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    font-style: italic;
  }

  .header,
  .footer {
    height: 32px;
  }

  .header {
    grid-template-columns: minmax(172px, 1fr) 42px 101px 101px;

    .column {
      padding: 0 8px;
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 12px;
      color: $gray-3;
    }
  }

  .footer {
    border-top: 1px solid $light-gray-4;
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
    color: $gray-3;
    padding: 0 8px;

    .diamond-icon {
      color: $gray-3;
      font-size: 4px;
      margin: 0 8px;
    }

    .value {
      font-weight: 500;
      color: $dark-gray;
    }
  }
}

