
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-row {
  display: grid;
  grid-template-columns: 40px 132px 109px 88px 1fr 124px 100px 64px;

  &.loading {
    .column {
      color: $gray-2;

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .vetor-tag {
        ::v-deep .outside {
          fill: $gray-2;
        }
      }

      .app-tag ::v-deep .app-tag-label {
        color: $gray-2;
      }

      .id-tag ::v-deep .text {
        color: $gray-2;

        &:hover {
          color: $orange;
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    &.vetor-column {
      .vetor-tag {
        font-family: $primary-monospace-font;
        font-weight: 400;
        font-size: 14px;
        padding: 0 4px;
        background-color: $light-orange-2;
        border-radius: 4px;
      }
    }

    &.total-column {
      .text {
        font-family: $secondary-font;
      }
    }

    &.supplier-column {
      .supplier {
        width: 100%;

        .address {
          font-size: 12px;

          .icon {
            color: $gray-2;
            margin-right: 4px;
          }
        }
      }
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover,
    .id-tag:hover {
      color: $orange;
    }
  }
}

