
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.total-price-row {
  display: grid;
  grid-template-columns: 1fr;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  .column {
    font-size: 12px;
    padding: 12px;

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $gray-2;
      font-weight: 500;
    }

    .currency {
      margin-left: 24px;
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

