
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.maintenance-package-preview {
  padding: 16px;
  max-width: 450px;

  .title {
    .maintenance-package {
      margin-right: 4px;
      overflow: hidden;
    }
  }

  .products {
    .loading {
      margin-top: 8px;

      ::v-deep .loading-line + .loading-line {
        margin-top: 4px;
      }
    }

    .reload {
      margin-top: 20px;
    }

    .product-row {
      margin-top: 8px;

      .icon {
        margin-right: 16px;
        height: 20px;
      }

      .name {
        font-size: 14px;
        margin-right: 4px;
      }
    }
  }
}

