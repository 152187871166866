
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.paginator {
  background-color: $white;
}

.loading {
  cursor: progress;
}

.disabled {
  cursor: not-allowed;
  color: $light-gray-4;

  .btn {
    cursor: not-allowed;

    &.current {
      background-color: $light-gray-3
    }

    &:not(:disabled) {
      cursor: not-allowed;
    }

    &.ellipsis {
      color: $light-gray-4;
    }
  }
}

.btn {
  $paginator-gutter: 12px;

  border: none;
  font-family: $secondary-font;
  font-size: 14px;
  line-height: 19px;
  color: $dark-gray;
  background-color: $white;
  padding: 4px;
  margin: 0 $paginator-gutter;
  min-width: 25px;
  position: relative;

  &:not(:disabled) {
    cursor: pointer;

    .navigation {
      color: $primary;
    }
  }

  &:disabled:not(.ellipsis) {
    color: $gray-2;
  }

  &:first-child {
    margin: 0 $paginator-gutter 0 0;
  }

  &:last-child {
    margin: 0 0 0 $paginator-gutter;
  }

  &.current {
    border-radius: 4px;
    background-color: #FFF0E2;
    color: $primary;
  }

  &:focus {
    color: $light-primary-2;
  }
}

.navigation {
  .next {
    margin-right: 4px;
  }

  .previous {
    margin-left: 4px;
  }

  .icon {
    font-size: 12px;
    font-weight: 700;
  }
}

