
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

.option {
  min-height: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.create {
  font-size: 14px;
  font-weight: bold;
}

.label {
  display: block;
  font-weight: normal;
}

.description {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder-content{
  .text {
    margin-left: 8px;
    font-family: $secondary-font;
    color: $gray-3;
    font-weight: 300;
  }
}

