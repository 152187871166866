
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.destroy-supplier-service-support-service-vehicle-model-groups {
  .form-body {
    padding: 24px 32px;
  }
}

