
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.wizard-header {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 16px;

  .section {
    font-size: 14px;
    font-family: $secondary-font;

    &:not(:last-of-type) {
      flex-grow: 1;
    }

    .status {
      width: 24px;
      height: 24px;
      border: 1px solid;
      border-radius: 50%;
      font-weight: 500;
      margin-right: 8px;

      .icon {
        font-size: 12px;
        color: $orange;
      }
    }

    .section-name {
      font-weight: 400;
    }

    .step-line {
      height: 2px;
      width: 100%;
      margin-right: 8px;

      &:first-of-type {
        margin-left: 8px;
      }

      &.done {
        background-color: $dark-orange-2;
      }

      &.active {
        background-color: $orange;
      }

      &.inactive {
        background-color: $light-gray-3;
      }
    }

    &.done {
      .status {
        border-color: $orange;
      }

      .section-name {
        color: $dark-gray;
      }
    }

    &.active {
      .status {
        color: $white;
        border-color: $dark-orange;
        background-color: $dark-orange;
      }

      .section-name {
        color: $orange;
      }
    }

    &.inactive {
      .status {
        color: $gray-2;
        border-color: $light-gray-4;
      }

      .section-name {
        color: $gray-2;
      }
    }
  }
}

