
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.target-fields {
  .index {
    width: 24px;
    height: 24px;
    background-color: $light-gray-3;
    border-radius: 12px;
    margin-right: 8px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
  }

  .action-icons {
    margin-left: 18px;
    width: 60px;

    &.first {
      margin-top: 34px;
    }

    .icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }

    .button + .button {
      margin-left: 20px;
    }

    .clear {
      color: $gray-2;

      &:hover {
        color: $black;
      }
    }
  }
}

