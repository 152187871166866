
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.filters-button {
  color: $dark-gray;
  display: block;
}

.icon {
  font-size: 14px;
  margin-right: 4px;
}

.filter-text {
  font-size: 14px;
  font-weight: 400;
}

