
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.field {
  margin-bottom: 24px;
}

.row + .row {
  margin-top: 16px;
}

.service-type-icon {
  width: 32px;
  height: 32px;
  user-select: none;
}

.content-text {
  margin-left: 16px;
  font-family: $secondary-font;
}

.service-name {
  font-weight: 500;
  font-size: 20px;
}

.service-description {
  font-weight: normal;
  font-size: 12px;
}

.type-section {
  padding-bottom: 24px;
}

