
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-package {
  padding-bottom: 24px;

  .header {
    padding: 18px 0 0 0;

    .service-package-name {
      font-size: 30px;
      font-weight: bold;
      word-break: normal;
      overflow-wrap: break-word;
    }

    .id-tag {
      margin: 0 8px;
    }

    .actions {
      .btn + .btn {
        margin-left: 8px;
      }

      .icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }

  .section + .section {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $light-gray-3;
  }

  .description {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }

  .list + .list {
    margin-top: 24px;
  }

  .footer {
    .icon {
      margin-right: 8px;
    }
  }
}

