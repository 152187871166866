
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.tickets-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

