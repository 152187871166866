
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.page-navigator {
  gap: 8px;
}

.action {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 4px;
  color: $dark-gray;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:hover {
    color: $primary;
  }

  &:active {
    color: $dark-primary-2;
  }

  &:focus {
    outline: 2px solid $primary;
    outline-offset: -2px;
  }

  &:disabled {
    color: $gray;
    cursor: not-allowed;
  }
}
