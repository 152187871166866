tr.app-table-row-light {
  td {
    height: 40px;
    padding: 0 16px;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    color: black;
    border-bottom: 1px solid $light-gray-3;
  }

  &:last-child {
    td {
      border-bottom: none;
    }
  }
}
