
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.maintenance-package {
  padding-bottom: 24px;
}

.section {
  margin-top: 24px;
  padding-top: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.header {
  padding-top: 18px;

  .discarded-icon {
    margin-right: 8px;
  }

  .name {
    font-size: 30px;
    font-weight: bold;
    word-break: normal;
    overflow-wrap: break-word;
  }

  .id-tag {
    margin: 0 8px;
  }
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.description-content {
  margin: 8px 0 24px 0;

  .description {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}

.products {
  margin-top: 24px;
  padding-top: 24px;

  .title {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 24px;
  }

  .table {
    margin-bottom: 24px;
  }
}

.info-label + .info-label {
  margin-left: 40px;
}

