
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


@import "@/assets/styles/field";

.icon {
  color: $gray-2;
}

.clear {
  position: absolute;

  height: 100%;
  width: 40px;
  top: 0;
  right: 0;

  font-size: 18px;
  background: transparent;

  outline: 0;
  border: 0;

  cursor: pointer;
  color: $gray-2;

  &:hover {
    color: $gray-3;
  }
}

.input-wrapper.disabled {
  .input-inner {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-3;
    cursor: not-allowed;
  }

  .icon {
    cursor: not-allowed;
  }
}

.initialize-error {
  font-size: 12px;
  margin-top: 4px;

  span {
    margin-right: 4px;
  }
}

