
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.stage-stamp {
  display: inline-block;
  margin: 0 auto;
  padding: 0 12px;
  transform: rotate(-20deg);
  opacity: .7;
  filter: blur(1.3px);
  border-radius: 20px;
  border-width: 6px;
  border-color: $dark-red;
  border-style: solid;
  border-image: var(--stamp-border-image);
  color: $dark-red;
  text-transform: uppercase;
  font-family: 'Courier New', CourierNew, serif;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  height: auto;
  white-space: pre;
}

