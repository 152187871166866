
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.timeline-popover-ongoing {
  padding: 16px;
  width:   400px;

  max-height: 500px;
  overflow-y: auto;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .content {
    .section + .section {
      margin-top: 16px;
    }

    .title {
      font-family: $secondary-font;
      font-size: 16px;
      font-weight: 500;
      color: $dark-gray;
    }

    .info {
      margin-top: 8px;
    }

    > .separator {
      margin-top: 12px;
      border-top: 1px solid $light-gray-3;
      padding-top: 12px;
    }
  }

  .empty {
    .empty-message {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }
  }
}


.icon-container {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  .icon {
    font-size: 12px;
    color: $gray-3;

    &.warning {
      color: $dark-warning;
    }
  }

  &.main {
    width: 24px;
    height: 24px;
    margin-right: 16px;

    .icon {
      font-size: 20px;
      color: $purple;

      &.warning {
        color: $dark-warning;
      }
    }

    &.empty {
      .icon {
        color: $gray-2;
      }
    }
  }
}

.estimated-completed {
  .icon-container {
    .icon {
      color: $gray-4;
    }
  }

  .details {
    display: flex;
    align-items: center;
  }
}
.diamond-icon {
  color: $gray;
  font-size: 4px;
  margin: 0 4px;
}

.postponements {
  .timestamps {
    margin-top: 4px !important;
  }

  .from {
    color: $dark-gray;
    text-decoration: line-through;
  }

  .arrow {
    padding-left: 4px;
    padding-right: 4px;
  }

  .to {
    color: $dark-success;
  }
}

