
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.show.service-order {
  > .navigation {
    margin-bottom: 8px;

    .btn-back {
      font-size: 14px;

      .icon {
        margin-right: 4px;
      }
    }
  }

  > .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 26px;

    > .content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      > .heading {
        display: flex;
        flex-direction: row;
        align-items: baseline; // or center

        .title {
          font-family: $secondary-font;
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 150%;

          color: $dark-gray;
        }
      }

      > .description {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 8px;

        .dot {
          color: $gray;
          font-size: 4px;
          line-height: 4px;
        }

        .service-kind {
          font-family: $secondary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;

          color: $dark-gray;
        }
      }
    } // > .content

    > .toolbar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      gap: 16px;

      .assignment {
        display: flex;
        flex-direction: row;
        gap: 8px;

        > .initials-tag {
          // @override
          margin: 0;
        }

        .label,
        .value {
          font-family: $secondary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: $dark-gray;
        }

        .label {
          font-size: 12px;
        }
      }
    } // .toolbar

  } // > .header


  > .body {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .section {
      position: relative;

      &:not(:first-child)::before {
        border-top: 1px solid $light-gray-3;
        content: ' ';
        margin-left: 40px;
        position: absolute;
        top: -16px;
        width: calc(100% - 40px);
      }
    }
  }
}


.waves-separator {
  background: url("~@/assets/images/waves-orange.png"); // waves-orange-light.png
  border-radius: 4px;
  height: 12px;
  margin-bottom: 16px;
}

.separator,
.h-separator {
  border-bottom: 1px solid $light-gray-3;
}

.v-separator {
  border-right: 1px solid $light-gray-4;
}

// XXX: sobrecarga de cor de título da lista de anexos (reuso de componente)
.attachments-list {
  ::v-deep .subsection-header > .icon-container {
    height: 24px !important;
    margin-right: 16px !important;
    width: 24px !important;
  }

  ::v-deep .subsection-title {
    color: $dark-gray !important;
  }

  ::v-deep .refresh-button {
    align-items: baseline;

    .icon-container {
      margin-right: 4px;

      .icon {
        font-size: 12px;
      }
    }
  }

  ::v-deep .count,
  ::v-deep .refresh-text {
    color: $dark-gray !important;
    font-family: $primary-font !important;
    font-size: 14px !important;
  }
  ::v-deep .refresh-text:hover {
    color: $primary !important;
  }

  ::v-deep .attachments {
    margin-left: 40px;
  }

  ::v-deep .empty {
    margin-left: 40px;
    text-align: initial !important;
  }
}

