
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.new-supplier-service-support-service-vehicle-model-groups {
  .section {
    padding: 16px 24px;
  }

  .services-count {
    padding: 24px;
    border-bottom: 1px solid $light-gray-3;

    .related-services {
      margin: 0 16px;
    }
  }
}

.footer {
  .submit-label {
    margin-right: 8px;
  }
}

