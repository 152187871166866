
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.attach-card {
  --fold-color: #{$gray-2};
  --fold-bg-color: #{$light-gray-3};
  --card-height: 140px;

  border-radius: 8px 24px 8px 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  font-family: $secondary-font;
  height: var(--card-height);
  overflow: hidden;
  width: 212px;

  &.loading {
    border-top-right-radius: 8px;
  }
}

.fold {
  background: var(--fold-bg-color);
  height: 24px;

  &::after {
    background: var(--fold-color);
    border-radius: 0 24px 0 8px;
    content: ' ';
    display: block;
    height: 24px;
    margin-left: auto;
    width: 24px;
  }

  .loading > & {
    display: none;
  }
}

.header {
  background: var(--fold-bg-color);
  padding: 0 16px 16px 16px;

  .loading & {
    display: none;
  }
}

.type-badge {
  align-items: center;
  background: white;
  border-radius: 4px;
  display: flex;
  height: 32px;
  padding: 0 8px;
  width: max-content;

  .icon {
    margin-right: 8px;
  }

  .label {
    color: $gray-3;
    font-size: 12px;
    font-weight: 400;
  }
}

.body {
  background: $light-gray-2;
  padding: 8px 16px 16px 16px;
  transition: transform ease-in-out 200ms;
  transform: translateY(0);
  width: 100%;

  > :not(:last-child) {
    margin-bottom: 8px;
  }

  .attach-name {
    display: -webkit-box;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    .label {
      color: $dark-gray;
      transition: color 100ms;

      &:not(.disabled):hover {
        color: $primary;
      }

      &.disabled {
        cursor: default;
        pointer-events: none;
      }
    }
  }

  .content-length {
    color: $gray-3;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .attach-card:hover & {
    transform: translateY(-48px);
  }

  .attach-card.loading & {
    background: $light-gray;
    height: var(--card-height);
    padding-top: 16px;
    transform: unset;
  }
}

.progress {
  margin: 10px 0;
}

.actions {
  display: flex;
  gap: 8px;
  height: 32px;
}

.action {
  align-items: center;
  background: white;
  border: none;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: $dark-gray;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 32px;
  justify-content: center;
  transition: box-shadow 200ms, color 100ms;
  width: 32px;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    color: $dark-primary-2;
  }
}
