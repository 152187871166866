
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.section {
  padding: 16px 32px;
  border-bottom: 1px solid $light-gray-3;
}

.body {
  min-height: 540px;
  height: 540px;
}

.selected-option {
  padding-right: 0;
}

.global-price {
  margin-right: 24px;
  height: 40px;
  background-color: $light-gray-2;
}

.icon {
  margin-left: 12px;
  margin-right: 16px;
  font-size: 20px;
  color: $gray-2;
}

.text {
  font-family: $secondary-font;
  font-size: 16px;
  color: $dark-gray;
  font-weight: normal;
}

.option {
  border-bottom: 1px solid $light-gray-3;
}

.product-select-field {
  ::v-deep .options-wrapper {
    max-height: 350px;
  }
}

.origin-radio {
  width: 667px;
  margin-top: 16px;

  ::v-deep .movida-common__radio-label {
    font-weight: 400;
    font-size: 14px;
  }
}

