
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.show {
  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      margin-top: 24px;
      padding-top: 24px;
    }

    &.header {
      .left {
        margin-bottom: 8px;

        .id-tag {
          margin: 0 8px;
        }

        .title,
        .subtitle {
          font-family: $secondary-font;
          font-weight: 500;
        }

        .subtitle {
          font-size: 20px;
        }

        .title {
          font-size: 38px;
        }
      }

      .services-badge {
        margin-right: 16px;
      }
    }

    &.content {
      .back {
        font-size: 14px;
        margin-bottom: 12px;

        .icon {
          font-size: 12px;
          margin-right: 4px;
        }
      }

      .date {
        border: 1px solid $light-gray-3;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 16px;
        width: 330px;
        height: 95px;
      }

      .pickup-service {
        margin-top: 16px;
        border: 1px solid $light-gray-3;
        border-radius: 4px;
        padding: 8px 16px;

        ::v-deep {
          .checkbox-input, .checkbox-label {
            cursor: unset;
          }
        }

        .icon-container {
          width: 24px;
          height: 24px;
          font-size: 16px;
          margin-right: 8px;
          color: $gray-3;
        }

        .value-text {
          color: $dark-gray;
          font-weight: 400;
        }
      }
    }

    &.footer {
      .confirm {
        margin-right: 18px;
      }

      .back {
        margin-right: 24px;

        .icon {
          margin-right: 8px;
        }
      }

      .next .icon {
        margin-left: 8px;
      }
    }
  }
}

