
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-show {
  padding-bottom: 24px;
}

.header {
  padding: 18px 0 8px 0;
}

.service-name {
  font-size: 30px;
  font-weight: bold;
  word-break: normal;
  overflow-wrap: break-word;
}

.id-tag {
  margin: 0 8px;
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.description {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.table {
  margin-bottom: 24px;
}

.info-label + .info-label {
  margin-left: 40px;
}

.products {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 24px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.section-title {
  margin-bottom: 24px;

  .title-icon {
    margin-right: 8px;
  }

  .title {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
  }
}

::v-deep.service-package-option{
  border: 1px solid $light-gray-3;
  border-radius: 5px;
  cursor: unset;

  * {
    cursor: unset;
  }

  .actions-column{
    min-width: unset;
  }
}

.movida-common__info-label {
  ::v-deep.movida-common__info-label-icon {
    font-size: 16px;
    color: $black;
  }
}

