
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


::v-deep {
  .menu-button.link,
  .popover-menu-button .neutral,
  .button-menu {
    height: 38px;
    padding: 8px 12px;
  }

  .menu-button.link,
  .popover-menu-button.link,
  .popover-menu-button .neutral {
    display: block;
    color: $dark-gray;
    font-weight: 300;

    .content {
      justify-content: flex-start;
    }

    &:hover {
      background: $light-gray-2;
    }
  }

  .menu-button.link:hover,
  .popover-menu-button.link:hover {
    color: $primary;
  }

  .popover-menu-button {
    min-width: 100%;
  }

  .popover-menu-button .movida-common__app-button {
    min-width: 100%;
  }

  .destroy-button .movida-common__app-button.neutral:hover {
    color: $red;
  }

  .icon-content {
    width: 18px;
    margin-right: 8px;
    text-align: center;
  }

  .popover-menu-button + .popover-menu-button {
    border-top: 1px solid $light-gray-3;
  }
}

