.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column,
.flex-col {
  display: flex;
  flex-direction: column;
}

.inline-flex {
  display: inline-flex;
}

.inline-flex-row {
  display: inline-flex;
  flex-direction: row;
}

.inline-flex-column,
.inline-flex-col {
  display: inline-flex;
  flex-direction: column;
}

.grow {
  flex-grow: 1;
}

.no-shrink {
  flex-shrink: 0;
}


.vertical-align-top,
.vertical-align-start,
.v-align-top,
.v-align-start {
  align-items: flex-start;
}

.vertical-align-middle,
.vertical-align-center,
.v-align-middle,
.v-align-center {
  align-items: center;
}

.vertical-align-baseline,
.v-align-baseline {
  align-items: baseline;
}

.vertical-align-bottom,
.vertical-align-end,
.v-align-bottom,
.v-align-end {
  align-items: flex-end;
}



.horizontal-align-left,
.horizontal-align-start,
.h-align-left,
.h-align-start {
  justify-content: flex-start;
}

.horizotal-align-center,
.h-align-center {
  justify-content: center;
  // TODO: required?
  // text-align: initial;
}

.horizontal-align-right,
.horizontal-align-end,
.h-align-right,
.h-align-end {
  justify-content: flex-end;
}


// &.space-between {
//   justify-content: space-between;
// }

// &.space-around {
//   justify-content: space-around;
// }
