
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.spreadsheet-download-modal {
  padding: 16px 32px;

  .button + .button {
    margin-left: 24px;
  }
}

