
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit-product {
  padding-bottom: 24px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.product-id {
  font-size: 30px;
  font-weight: 400;
  font-family: $secondary-font;
  color: $gray-3;
  padding-left: 8px;
}

.type-section {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;
  font-family: $secondary-font;
  font-weight: 500;
}

.form-section {
  padding-top: 24px;
}

.empty {
  display: block;
}

.fields-content {
  margin-bottom: 24px;
}

.sap-input {
  width: 250px;
}

.id-tag {
  margin: 0 8px;
}

