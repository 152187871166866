
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.quote-service-row {
  grid-template-columns: minmax(296px, 1fr) 40px 40px 50px 113px 122px 40px;
  gap: 8px;
  min-height: 40px;

  &.discarded {
    background-color: $light-gray-2;

    .name {
      color: $gray-2;
      text-decoration-line: line-through;
    }
  }

  .column {
    padding: 0 8px;
    height: 40px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .indicator.submitting {
    cursor: not-allowed;

    ::v-deep {
      .icon {
        color: $gray-3;
      }

      .data-indicator {
        background-color: $light-gray-4;
      }
    }
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
  }

  .action {
    height: 40px;

    &.submitting {
      .action-button {
        color: $gray-3;
        cursor: not-allowed;

        &.icon-container:hover {
          color: $gray-3;
          background-color: unset;
          cursor: not-allowed;
        }
      }
    }

    .action-button {
      height: 100%;
      width: 100%;

      &.icon-container:hover {
        width: 100%;
        background-color: $light-gray-2;
        border-radius: 50%;
        color: $orange;
        cursor: pointer;
      }
    }
  }
}

