
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.actions {
  .header-button {
    z-index: 402;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 32px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: $dark-gray;
    background-color: transparent;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $black;
    }

    &.edit {
      right: 69px;
    }

    &.destroy {
      right: calc(110px);
    }
  }
}

.info-label {
  + .info-label {
    margin-top: 28px;
  }

  ::v-deep .movida-common__info-label-content {
    align-items: flex-start;
  }

  .icon {
    color: $dark-gray;
    font-size: 20px;
    width: 20px;
    text-align: center;
    margin-right: 16px;
  }

  .diamond-icon {
    margin: 0 8px;
    font-size: 4px;
    color: $gray-3;
  }

  .info {
    font-weight: 400;
  }

  .kind {
    font-size: 14px;
    color: $gray-3;
  }

  .copy-icon {
    margin-left: 8px;
    font-size: 14px;
    color: $gray-2;
  }

  .contact-value + .contact-value {
    margin-top: 16px;
  }
}

header.header {
  padding-top: 16px;
  padding-bottom: 16px;

  .title {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 20px;
    max-width: 540px;

    &.edit {
      max-width: 470px;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .avatar {
    margin-right: 16px;
  }
}

