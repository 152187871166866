
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-history {
  background-color: $light-gray;
  padding: 16px;
  border-radius: 8px;

  .title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 18px;
  }

  .action {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      color: $orange;
      cursor: pointer;
    }

    .open-icon {
      font-size: 12px;
      margin-right: 4px;
    }

    .icon-container {
      width: 24px;
      height: 24px;

      .close-icon {
        font-size: 16px;
      }
    }
  }

  .content {
    .interactions {
      margin: 16px 0;

      .tabs {
        border-bottom: 1px solid $light-gray-3;
        width: 480px;

        .tab {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 16px;
          height: 40px;
          line-height: 40px;
          color: $gray-3;
          cursor: pointer;
          user-select: none;

          &.selected {
            color: $orange;
            box-shadow: inset 0px -4px 0px $orange;
          }

          & + .tab {
            margin-left: 16px;
          }
        }
      }

      .search {
        width: 432px;
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 40px;

        .search-button {
          min-width: revert;
        }
      }
    }

    .table {
      > .header,
      .row {
        grid-template-columns: minmax(389px, 1fr) minmax(389px, 1fr) 125px 125px;
      }

      > .erred,
      > .empty {
        background-color: $white;
        border-radius: 4px;
        padding: 8px;
      }

      > .erred {
        .retry {
          margin-left: 4px;
        }
      }

      > .empty {
        font-size: 12px;
        font-style: italic;

        min-height: 130px;

        .icon {
          font-size: 32px;
          color: $gray;

          background: $light-gray-2;
          border-radius: 64px;
        }
      }

      .header {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 12px;
        color: $gray-3;

        .column {
          padding: 4px 8px;
        }
      }

      .rows {
        background-color: $white;
        border-radius: 4px;

        .row {
          font-weight: 400;
          font-size: 12px;

          & + .row {
            border-top: 1px solid $light-gray-3;
          }

          .column {
            padding: 8px;

            .text {
              font-family: $secondary-font;
            }

            .value {
              font-family: $primary-monospace-font;
            }
          }
        }
      }
    }

    .paginator-content {
      padding-top: 12px;

      &.loading {
        max-width: 400px;
        margin: 0 auto;
      }

      ::v-deep .paginator {
        background-color: transparent;

        .content .btn:not(.current) {
          background-color: transparent;
        }
      }
    }
  }
}

