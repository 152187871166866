
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.header {
  margin-bottom: 24px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  font-family: $secondary-font;
}

.actions {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.tag {
  margin-left: 8px;
}

.back {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;

  &::v-deep.link {
    color: $gray-2;

    &:hover {
      color: $gray-3;
    }
  }

  .icon {
    margin-right: 8px;
    font-size: 12px;
  }
}

