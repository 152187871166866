
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit-service {
  padding-bottom: 24px;
}

.header {
  margin-bottom: 32px;
  padding: 18px 0 24px 0;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.id-tag {
  margin: 0 8px;
}

