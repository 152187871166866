
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.show {

  .issue-solution {
    position: relative;
    // padding: 16px 24px;
    border-bottom: 1px solid $light-gray-3;

    .edit-button {
      position: absolute;
      top: 8px;
      right: 24px;
    }
  }

  .error,
  .loading,
  .section {
    padding: 16px 24px;
  }

  .error {
    .icon {
      font-size: 18px;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
      }
    }

    .message {
      font-style: italic;
      margin: 8px 0;
    }

    .reload {
      font-size: 14px;
    }
  }

  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
    }

    .empty-section {
      height: 238px;

      .illustration {
        --size: 48px;
        width: var(--size);
        height: var(--size);
      }

      .empty-message {
        font-weight: 300;
        font-size: 14px;
        font-style: italic;
        margin-top: 8px;
      }
    }
  }
}

