
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-card {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 16px;

  .manufacturer {
    margin-right: 4px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 16px;

    .fragment + .fragment {
      margin-left: 8px;
    }
  }

  .version {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    color: $gray-3;
  }
}

