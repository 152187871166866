
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-models-select-list{
  .search {
    margin-bottom: 12px;
  }

  .filters {
    padding-bottom: 12px;

    .results-indicator {
      margin-left: auto;
    }
  }

  .paginator-content {
    margin: 16px auto;

    &.loading {
      max-width: 400px;
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-bottom: 4px;
  }
}

