
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.popover-menu-button {
  ::v-deep .button-menu {
    height: 30px;
  }
}

