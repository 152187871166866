
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.section {
  padding: 16px 24px;

  + .section {
    border-top: 1px solid $light-gray-3;
  }
}
