
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-option {
  display: grid;
  grid-template-columns: 75px 48px 1fr 48px;
  height: 58px;

  &.active,
  &.focus {
    background-color: $light-gray-2;
  }

  &.focus {
    cursor: pointer;

    .column {
      &.actions-column {
        .remove-icon-content {
          background: $light-gray-3;
          color: $primary;
        }
      }

      .name {
        color: $orange;
      }
    }
  }

  .column {
    padding: 12px;
    overflow: hidden;
    font-weight: 400;

    .name {
      font-family: $secondary-font;
      font-size: 14px;
    }

    .description {
      font-size: 12px;
      color: $gray-3;
    }

    &.actions-column {
      .remove-icon-content {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 50px;
        color: $gray-2;
        transition: all 100ms linear;
      }
    }
  }
}

