
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.products {
  border: 1px solid $light-gray-3;
}

::v-deep .tag {
  margin-left: 8px;
}

