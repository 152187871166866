
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.business-unit-tag {
  .tag {
    padding: 2px 4px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    border-radius: 4px;

    // - default/unknown
    background-color: $light-gray-3;

    &.rac,
    &.rac_eventual,
    &.rac_mensal,
    &.mensal_flex {
      background-color: $light-orange-2;
    }

    &.zkm {
      background-color: $light-purple;
    }

    &.gtf {
      background-color: $light-info;
    }

    &.seminovos {
      // roxo do site https://www.seminovosmovida.com.br
      background-color: #61296D;
      color: $white;
    }

    // sizes
    // ---
    &.big,
    &.large {
      font-size: 18px;
      // height: 26px;
    }

    &.normal,
    &.medium {
      font-size: 14px;
      // height: 19px;
    }

    &.small {
      font-size: 12px;
      // height: 16px;
    }
  }
}

