
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.actions {
  margin-bottom: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.info {
  ::v-deep .movida-common__info-label-label {
    font-family: $secondary-font;
    font-weight: 500;
  }
}

.info {
  margin: 24px 24px 0 0;
}

.amendment-label {
  margin-bottom: 4px;

  .icon,
  .from {
    color: $dark-gray;
  }

  .from {
    font-weight: normal;
    margin-right: 4px;
  }
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;

  &.history-section {
    padding-top: 0;
  }
}

.title {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 24px;
}

.row + .row {
  padding-top: 24px;
}

.info-section {
  margin-top: -24px;
}

.error {
  margin-top: 4px;
}

