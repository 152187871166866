
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-info-card {
  padding: 24px;
  background: $light-gray;
  border-radius: 8px;
  width: 802px;

  .icon {
    color: $gray-3;
    font-size: 16px;
    line-height: 16px;
  }

  .diamond-icon {
    --size: 4px;
    font-weight: 900;
    font-size: var(--size);
    color: $gray-3;
    width: var(--size);
    height: var(--size);
    margin: 10px;
  }

  .info-header {
    .best-supplier-badge {
      margin-bottom: 12px;
    }

    .trading-name {
      font-family: $secondary-font;
      font-size: 38px;
      font-weight: 500;
      line-height: 35px;
    }

    .address-and-cnpj {
      margin-top: 8px;

      .city {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }

      .cnpj {
        font-family: $primary-monospace-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }

  .info-body {
    margin-top: 24px;

    .icon-container {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }

    .description {
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
    }

    .description-label {
      margin-left: 32px;
    }

    .pickup-service,
    .scheduling-notice {
      margin-top: 24px;
    }
  }
}

