
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-issues {
  .header {
    margin-bottom: 16px;

    .title-icon {
      margin-right: 14px;
    }

    .title-text {
      font-size: 20px;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .filters {
    margin: 12px 0;

    .results-indicator {
      margin-left: auto;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .content-wrapper {
    min-height: 64px;

    .list {
      border: 1px solid $light-gray-3;
      border-radius: 8px;
      max-height: 560px;
      overflow-y: scroll;
    }

    .empty {
      .empty-icon {
        width: 64px;
        height: 64px;
      }

      .empty-message {
        margin-top: 8px;
        font-style: italic;
      }
    }
  }
}

