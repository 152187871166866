
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.footer {
  height: 40px;
  border: 1px solid $light-gray-3;
  background: $light-gray;

  .row {
    flex-grow: 1;
    padding: 0 16px;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    color: $dark-gray;
    text-transform: uppercase;
    height: 100%;

    .icon {
      margin-right: 8px;
    }
  }

  .total {
    width: 150px;
    border-left: 1px solid $light-gray-3;
    padding: 0 16px;
    font-size: 16px;
    font-weight: bold;
    color: $green;
    text-align: right;
    height: 100%;
  }
}

