
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.discarded-icon {
  display: inline-block;
  color: $dark-error;

  &.small {
    font-size: 14px;
  }

  &.normal {
    font-size: 20px;
  }

  &.big {
    font-size: 26px;
  }
}

