
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-card {
  .photo {
    margin-right: 16px;
  }

  .content {
    overflow: hidden;

    .diamond-icon {
      margin: 0 8px;
      font-size: 4px;
      color: $gray-3;
    }

    .top-info {
      margin-bottom: 4px;
      font-size: 20px;
      font-weight: normal;
      font-family: $secondary-font;
    }

    .middle-info {
      margin-bottom: 16px;
      color: $dark-gray;
      font-size: 14px;
    }

    .bottom-info {
      .value {
        color: $gray-4;
        font-weight: 400;
        overflow: hidden;

        .movida-common__vehicle-plate-tag {
          padding: 2px 8px;
        }

        .icon {
          margin-right: 12px;
          color: $gray-2;
        }

        & + .value {
          margin-left: 24px;
        }
      }
    }
  }
}

