
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  .column {
    padding: 12px;
    border-bottom: 1px solid $light-gray-3;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    .empty {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
      color: $gray-2;
    }

    &.service-column {
      font-weight: 400;

      .name {
        font-family: $secondary-font;
        font-size: 14px;
        display: inline;
      }

      .description {
        font-size: 12px;
        color: $gray-3;
      }
    }

    &.attachments-column {
      .icon {
        color: $gray-2;
        margin-right: 4px;
      }
    }

    &.attachments-column,
    &.quantity-column {
      font-weight: 400;
      font-size: 14px;
      font-family: $primary-monospace-font;
    }

    &.parts-column {
      .issue {
        line-height: 14px;

        .part,
        .symptom {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 14px;
        }

        .diamond-icon {
          position: relative;
          bottom: 3px;
        }
      }

      .location,
      .position {
        font-size: 12px;
        color: $gray-3;
        font-weight: 400;
      }

      .diamond-icon {
        font-size: 4px;
        font-weight: 900;
        color: $gray-3;
        width: 4px;
        height: 4px;
        margin: 4px;
      }
    }
  }
}

