
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.timeline-popover-vehicle-stopped {
  padding: 16px;
  width:   400px;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .content {
    font-size: 14px;
    line-height: 150%;
    font-family: $secondary-font;

    .title {
      font-size: 16px;
      font-weight: 500;
      color: $dark-gray;
    }

    .subtitle {
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 300;
      color: $dark-gray;
    }

    .info {
      margin-top: 8px;
    }
  }

  .empty {
    .empty-message {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }
  }
}


.icon-container {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  .icon {
    font-size: 12px;
    color: $gray-3;

    &.warning {
      color: $dark-warning;
    }
  }

  &.main {
    width: 24px;
    height: 24px;
    margin-right: 16px;

    .icon {
      font-size: 20px;
      color: $purple;
    }

    &.empty {
      .icon {
        color: $gray-2;
      }
    }
  }
}

