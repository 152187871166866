
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.section {
  padding: 16px 32px;
  border-bottom: 1px solid $light-gray-3;
}

.header {
  font-family: $secondary-font;
  font-size: 20px;
  border-bottom: 1px solid $light-gray-3;
}

.body {
  border-bottom: unset;
}

.select-field + .select-field {
  margin-top: 24px;
}

.icon {
  margin-right: 16px;
  font-size: 20px;
  color: $gray-2;
}

.icon-edit {
  font-size: 18px;
  color: $dark-gray;
}

.text {
  font-size: 16px;
  color: $dark-gray;
  font-weight: normal;
}

::v-deep .product-row:last-child {
  border: unset;

  .deal-product-row:last-child {
    .content-deal-product {
      border: unset;
    }
  }
}

.edit-button {
  margin-right: 40px;
}

