
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-state-icon {
  display: inline-block;
  color: $gray-2;
  text-align: center;

  &.small {
    font-size: 12px;
    width: 16px;
    height: 16px;
    line-height: 16px;
  }

  &.normal {
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
  }

  &.big {
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .icon {
    &.open {
      color: $gray-3;
    }

    &.ongoing {
      color: $orange;
    }

    &.executed {
      color: $success;
    }

    &.canceled {
      color: $error;
    }
  }
}

