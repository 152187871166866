
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.totals {
  align-items: stretch;
  background: $light-gray-2;
  border-radius: 4px;
  display: flex;
  gap: 48px;
  justify-content: flex-end;
  padding: 16px;
}

.label {
  font-family: $secondary-font;

  .total:not(.grand-total) & {
    margin-bottom: 4px;
  }
}

.value {
  font-family: $primary-monospace-font;
  font-size: 16px;

  .grand-total & {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }
}

.separator {
  border-left: 1px solid $light-gray-4;
}
