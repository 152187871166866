
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


svg {
  &.medium {
    width: 24px;
    height: 11px;
  }
}

.inside {
  fill: var(--gmf-icon-fill);
}

