
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.label {
  display: block;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 4px;;
}

.fields {
  border-bottom: 1px solid $light-gray-3;
  padding-bottom: 24px;
}

.product-preview  {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.product {
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}

.ui-row + .ui-row {
  margin-top: 24px;
}

