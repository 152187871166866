
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.attachment-card-header {
  height: 72px;
  background-color: var(--background);
  border-radius: 8px 24px 0 0;
  overflow: hidden;

  .corner {
    background-color: var(--accent);
    height: 24px;
    width: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 8px;
  }

  .type {
    background-color: $white;
    margin-left: 16px;
    padding: 8px;
    border-radius: 4px;
  }
}

