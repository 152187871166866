
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.quote-item {
  .section {
    padding: 16px 24px;

    & + .section {
      border-top: 1px solid $light-gray-3;
    }
  }

  .selector {
    background-color: $light-gray;
    height: 97px;
    border-radius: 8px;
    border: 2px dashed $light-gray-4;

    .message {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
      color: $gray-3;
    }

    .file-selector {
      margin-top: 8px;
    }

    .button {
      font-size: 14px;

      .icon {
        margin-right: 4px;
      }
    }
  }

  .attachments {
    margin-top: 16px;
    gap: 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

