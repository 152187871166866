
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-kind-icon {
  --size: 14px;
  color: $gray-3;
  font-size: var(--size);
  line-height: var(--size);
  font-weight: 900;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

