
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


::v-deep .app-tag-label {
  font-family: $primary-monospace-font;
  font-weight: 400;
}

