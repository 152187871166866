
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form {
  overflow: auto;
}


.modal-header {
  color: $primary;
  margin-top: 0;
  margin-bottom: 24px;
}

.heading {
  font-size: 18px;
  font-weight: 500;
  color: $dark-gray;
  height: 32px;
  line-height: 32px;
  font-family: $secondary-font;
  margin-left: 24px;
  margin-top: 24px;

  .icon {
    margin-right: 8px;
  }
}

.subtitle {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
  color: $dark-gray;
  font-family: $secondary-font;

  &.error {
    color: $error;
  }
}

.header-divider {
  height: 1px;
  background: $light-gray-3;
  margin-top: 16px;
}

.section {
  padding: 0 24px;
}

.actions {
  border-top: 1px solid $light-gray-3;
  padding: 24px;
}

.group-field {
  border-bottom: 1px solid $light-gray-3;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.error-message {
  display: block;
  color: $error;
  font-size: 14px;
  line-height: 1;
  padding-top: 4px;
  margin-bottom: 24px;
}

.maintenance-table {
  margin-top: 24px;
}

.maintenance-table-kind {
  border-top: 1px solid $light-gray-3;
  padding: 16px 24px;
}

.empty {
  text-align: center;
  font-style: italic;
  padding: 32px 0;
  width: 100%;

  color: $gray-3;
}

