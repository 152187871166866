
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.row + .row {
  margin-top: 24px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.type {
  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
  }

  .supplier-icon {
    margin-right: 8px;
  }
}

.hierarchy-radio {
  height: 100%;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 4px;
  font-family: $secondary-font;
  font-weight: 500;
}

.subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.sector-multiple-select-field {
  margin-bottom: 24px;
}

