
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.repair-kind-radio-field {
  .radio-option {
    width: 271px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid $light-gray-3;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &.checked {
      background-color: $light-orange;
      border-color: $orange;
    }

    &.error {
      border-color: $error;
    }

    .label {
      margin-left: 16px;
      font-family: $secondary-font;
      font-weight: 400;
    }
  }
}

