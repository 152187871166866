
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.container {
  border: 1px solid $light-gray-3;
  border-left-width: 6px;
  border-radius: 4px;

  .details-content {
    padding: 16px;
  }

  &.ongoing {
    border-color: $green;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  }

  &.canceled {
    border-left-color: $light-gray-4;
  }

  &.done,
  &.amended {
    border-left-color: $gray-2;
  }

  &.draft {
    border-left-color: $dark-yellow;
  }
}

.deal-ongoing-identification {
  padding: 8px;
  border-bottom: 1px solid $light-gray-2;
}

.info + .info {
  margin-left: 24px;
}

.deal-state:not(.ongoing-state) {
  margin-bottom: 4px;
}

.ongoing-state {
  margin-right: 16px;
}

.block {
  display: block;
}

.inheritance-content {
  background: #FFF6D9;
  padding: 10px 16px;

  .fa-link {
    margin-right: 8px;
  }

  .follow {
    font-size: 16px;
    font-family: $secondary-font;
    font-weight: 500;
  }
}

.actions-column {
  margin-right: 8px;
}

