
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-info-banner {
  border-radius: 8px;
  padding: 12px;
  background-color: $light-gray;

  .avatar {
    margin-right: 14px;
  }

  .row {
    margin-bottom: 4px;
    font-size: 14px;

    .title {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 16px;
    }

    .icon {
      color: $gray-2;
      margin-right: 7px;
    }


    .copy-icon {
      margin-left: 4px;
    }
  }
}

