
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.loading-lines {
  .shine-loading {
    background: #efefef;
    background-image: linear-gradient(to right, #efefef 5%, #f9f9f9 15%, #efefef 40%, #efefef 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    border-radius: 6px;

    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  .loading-line + .loading-line {
    margin-top: 8px;
  }

  .loading-line {
    display: flex;
  }

  .icon-load {
    width: 26px;
    margin-right: 12px;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -500px 0;
    }

    100% {
      background-position: 500px 0;
    }
  }
}

