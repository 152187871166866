
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.movida-card {
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

