
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.actions {
  margin-bottom: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.info-label + .info-label {
  margin-top: 10px;
}

.address-content {
  margin-bottom: 12px;
}

