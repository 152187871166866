
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.item-show {
  .section {
    padding: 16px 24px;

    & + .section {
      border-top: 1px solid $light-gray-4;
    }

    .section-header {
      margin-bottom: 8px;

      .icon-container {
        width: 16px;
        height: 16px;
        margin-right: 8px;

        .icon {
          font-size: 14px;
        }
      }

      .section-title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 16px;
      }

      .diamond-icon {
        font-size: 4px;
        color: $gray-3;
        margin: 0 8px;
      }

      .count {
        font-family: $secondary-font;
        font-weight: 400;
        color: $gray-3;
      }
    }

    .attachments {
      grid-template-columns: minmax(322px, 1fr) minmax(322px, 1fr);
      gap: 8px 24px;
    }

    .empty {
      background-color: $light-gray-2;
      height: 32px;
      border-radius: 4px;

      .message {
        font-size: 12px;
        font-style: italic;
      }
    }

    .description {
      font-size: 14px;
    }
  }
}

