
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-issue-selected-option {
  .icon-container {
    --size: 16px;
    --icon-color: #{$gray-2};
    height: var(--size);
    width: var(--size);
    margin: 0 8px 0 12px;

    .icon {
      font-size: 14px;
      color: var(--icon-color);
    }

    .vehicle-part-icon {
      mask-image: var(--svg-icon);
      mask-repeat: no-repeat;
      mask-size: cover;
      height: 14px;
      width: 14px;
      background-color: var(--icon-color);
    }
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }
}

