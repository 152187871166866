
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.maintenance-package {
  .header {
    padding-bottom: 24px;

    .discarded-icon {
      margin-right: 8px;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 4px;
    }

    .description {
      font-size: 14px;
    }
  }

  .table-header {
    margin-bottom: 8px;

    .text {
      font-family: $secondary-font;
      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
    }

    .count {
      font-size: 14px;
    }
  }
}

