
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.modal-body {
  padding: 24px;

  .text {
    white-space: pre-line;
  }
}

