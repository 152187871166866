
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.product-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  .column {
    padding: 12px;
    border-bottom: 1px solid $light-gray-3;
    overflow: hidden;

    &.total-price-column {
      flex-direction: column;
      align-items: flex-end;
    }

    .empty {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
      color: $gray-2;
    }

    .label {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
    }

    .sap-id,
    .currency {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
    }

    .discount {
      display: block;
      font-size: 12px;
      color: $gray-3;
      line-height: 16px;
    }
  }
}

