
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.search-field {
  .input {
    position: relative;
    font-size: 14px;

    &.disabled {
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }
    }

    .input-inner {
      appearance: none;
      border-radius: 80px;
      border: 1px solid $light-gray-4;
      font-size: inherit;
      font-weight: 300;
      padding: 16px 24px 16px 52px;
      width: 100%;

      &.error {
        border-color: $error;
      }

      &:hover {
        border-color: $orange;
      }

      &:focus {
        border: 2px solid $orange;
        padding: 15px 23px 15px 51px;
      }
    }

    .icon {
      position: absolute;
      user-select: none;
      left: 27px;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
      }
    }
  }

  .error-message {
    color: $error;
    margin-top: 4px;
    font-size: 12px;
  }
}

