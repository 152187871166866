
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      &.supplier-column {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    &.supplier-column {
      text-align: initial;
      font-weight: 400;

      .supplier-name {
        font-family: $secondary-font;
        font-size: 14px;
      }

      .address {
        font-size: 12px;
      }
    }

    .text,
    .link {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link:hover {
      color: $orange;
    }

    .unknown {
      font-style: italic;
    }
  }
}

