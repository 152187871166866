
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

@keyframes indeterminate {
  from {
    margin-left: -16px;
  }

  to {
    margin-left: 100%;
  }
}

.progress-bar {
  --progress: 0;

  background: $light-gray-4;
  border-radius: 4px;
  height: 8px;
  overflow: hidden;
  width: 100%;

  &::before {
    background: $primary;
    content: ' ';
    display: block;
    height: 8px;
    transition: width 250ms;
    width: var(--progress);
  }

  &.indeterminate::before {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: indeterminate;
    animation-timing-function: ease-in-out;
    width: 16px;
  }
}
