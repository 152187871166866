
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.link {
  font-family: $secondary-font;
  font-weight: 400;
  color: $gray-2;
  font-size: 14px;

  &:last-child {
    color: $dark-gray;
  }

  &:hover {
    color: $primary;
  }
}

.link:hover {
  color: $primary;
}

.breadcrumb {
  display: inline;
}

.separator {
  user-select: none;
  color: $gray-2;
  font-size: 12px;
  font-weight: 900;
  margin: 0 16px;
}

