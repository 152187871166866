
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.deal-state {
  display: inline-block;
  border: 1px solid $dark-yellow;
  padding: 2px 8px;
  border-radius: 32px;
  font-family: $secondary-font;
  height: 24px;
  font-weight: 500;
}

.ongoing {
  border-color: $green;
  color: $white;
  background-color: $green;
}

.canceled {
  border-color: $light-gray-4;
}

.done,
.amended {
  border-color: $gray-2;
}

.draft {
  border-color: $dark-yellow;
}

