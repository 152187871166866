
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form-fields {
  .row + .row {
    margin-top: 24px;
  }

  .radio-label {
    padding-left: 8px;
    margin-right: 8px;
    cursor: pointer;

    .radio-icon {
      margin-left: 8px;
    }
  }

  .maintenances {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $light-gray-3;
  }
}

