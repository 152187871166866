//
// Classes utilitárias comuns a toda a aplicação
//

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.hidden {
  visibility: hidden;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.whitespace {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

// XXX: utility class to help finding nodes
.debug {
  border: 1px solid red !important;
}
