
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.service-orders-table-toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .filters {
    gap: 8px;
  }

  .actions {
    gap: 8px;

    .separator {
      background: $light-gray-4;
      flex-grow: 0;
      flex-shrink: 0;
      height: 16px;
      width: 1px;
    }

    .assignment {
      font-size: 14px;
      font-weight: 400;
      padding: 8px 24px;
    }

    .results-indicator {
      color: $dark-gray;
      margin-left: auto;
    }
  }
}
.indice-key-timeframe{
  width: 110px;

}
