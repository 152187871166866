
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.new-changes-request {
  padding: 18px 24px;

  .ui-row {
    padding-top: 16px;
  }

  .actions {
    padding-top: 16px;
  }
}

::v-deep .options-wrapper {
  height: 120px;
  overflow-y: scroll;
}

.confirmation-modal {
  height: 255px;

  .icon-container {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $light-green;

    &.loading {
      background-color: $light-gray;

      .icon {
        color: $orange;
      }
    }

    .icon {
      font-size: 36px;
      color: $dark-green;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
        line-height: 0.75em;
      }
    }
  }

  .text {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 38px;
    margin: 16px 0;
  }
}

