
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.ticket-row-skeleton {
  display: grid;
  grid-template-columns: 70px 40px 0.6fr 120px 1fr 100px 180px 64px;

  .column + .column {
    margin-left: 24px
  }
}

