
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.services-selected-badge {
  background-color: $light-gray;
  height: 54px;
  width: 54px;
  border-radius: 8px;

  .count {
    font-family: $secondary-font;
    font-size: 16px;
    color: $dark-gray;
    font-weight: 400;
  }

  .icon {
    color: $gray-3;
    margin-right: 4px;
    font-size: 14px;
  }
}

