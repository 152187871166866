
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.products-table {
  border: 1px solid $light-gray-3;
  border-radius: 4px;
}

.empty,
.error {
  text-align: center;
  font-style: italic;
  padding: 32px 0;
  width: 100%;

  color: $gray-3;
}

.link {
  margin-left: 4px;
}

.table-edge {
  height: 8px;
  background: $light-gray;
}

