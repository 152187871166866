
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-issue-option {
  display: grid;
  grid-template-columns: 75px 1fr 1fr 48px;
  height: 60px;

  &.active,
  &.focus {
    background-color: $light-gray-2;
  }

  &.focus {
    cursor: pointer;

    .column {
      &.actions-column {
        .remove-icon-content {
          background: $light-gray-3;
          color: $primary;
        }
      }

      .content {
        .name {
          color: $orange;
        }
      }
    }
  }

  .column {
    padding: 12px;
    overflow: hidden;

    .icon-container {
      --size: 20px;
      height: var(--size);
      width: var(--size);
      margin-right: 8px;
      --icon-color: #{$gray-2};

      .icon {
        color: var(--icon-color);
      }

      .vehicle-part-icon {
        mask-image: var(--svg-icon);
        mask-repeat: no-repeat;
        height: 16px;
        width: 16px;
        background-color: var(--icon-color);
      }
    }

    .content {
      overflow: hidden;
      font-weight: 400;

      .name {
        font-family: $secondary-font;
        font-size: 14px;
      }

      .description {
        font-size: 12px;
        color: $gray-3;
      }
    }

    &.vehicle-part-column {
      .content {
        .id {
          margin-left: 4px;
        }
      }
    }

    &.actions-column {
      .remove-icon-content {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 50px;
        color: $gray-2;
        transition: all 100ms linear;
      }
    }
  }
}

