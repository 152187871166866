
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-show {
  padding-bottom: 24px;
}

.supplier-info-card {
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  height: 115px;
}

.page-content {
  margin-top: 24px;
}

.status-card {
  margin-bottom: 24px;
}

