
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.movida-list {
  padding: 24px;
}

.main-icon {
  margin-right: 24px;
}

.title {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 18px;
  color: $dark-gray;

  &:hover {
    color: $primary;
  }
}

.actions {
  margin: 0 24px;
}

.description {
  font-size: 14px;
}

