
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.issue-solution-card {
  .error {
    .icon {
      font-size: 18px;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
      }
    }

    .message {
      font-style: italic;
      margin: 8px 0;
    }

    .reload {
      font-size: 14px;
    }
  }

  .card {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 16px;

    .type-icon {
      margin-right: 16px;
    }

    .top {
      .name {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 20px;
        margin-right: 8px;
      }

      .description,
      .issues {
        font-size: 14px;
        color: $gray-3;
      }

      .issues {
        margin: 16px 0;
      }
    }

    .issue-row {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 18px;

      & + .issue-row {
        margin-top: 16px;
      }

      .index {
        width: 24px;
        height: 24px;
        font-size: 14px;
        font-family: $secondary-font;
        font-weight: 500;
        color: $gray-3;
        background-color: $white;
        border: 1px solid $light-gray-4;
        border-radius: 12px;
        margin-right: 16px;
      }

      .icon-container {
        --size: 24px;
        height: var(--size);
        width: var(--size);
        margin-right: 4px;
        --icon-color: #{$gray-2};

        &.symptom-icon {
          margin-left: 8px;
        }

        &.arrow-icon {
          margin: 0 8px;

          .icon {
            color: $dark-blue;
          }
        }

        .icon {
          color: var(--icon-color);
        }

        .vehicle-part-icon {
          mask-image: var(--svg-icon);
          mask-repeat: no-repeat;
          height: 16px;
          width: 16px;
          background-color: var(--icon-color);
        }
      }

      .diamond-icon {
        font-size: 4px;
        color: $gray-2;
        margin: 0 4px;
      }
    }
  }
}

