
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.row {
  margin-bottom: 24px;
}

.title {
  font-size: 18px;
  font-weight: normal;
  color: $black;
  margin: 24px 0;
}

