
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      &.store-column .initials,
      &.vehicle-column .vehicle-info .bottom .text,
      &.license-plate-column ::v-deep .value,
      &.service-order-column ::v-deep .app-span,
      &.service-order-column .service-order .icon-container .icon,
      &.actions-column ::v-deep .movida-common__app-button,
      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button:hover {
          color: $orange;
        }
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 64px;
    overflow: hidden;

    .text {
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .calendar {
      .icon,
      .text {
        font-size: 12px;
      }

      .text {
        font-family: $primary-font;
      }

      .icon {
        color: $gray-2;
        margin-right: 6px;
      }
    }

    .diamond-icon {
      margin: 0 4px 0 4px;
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
    }

    &.vehicle-column {
      font-family: $secondary-font;

      .manufacturer-icon {
        margin-right: 8px;
      }

      .vehicle-info {
        .bottom .text {
          color: $gray-3;
        }

        &:hover {
          cursor: pointer;

          .row .text-container * {
            color: $orange;
          }
        }
      }
    }

    &.store-column {
      .initials {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 14px;
        color: $gray-3;
      }
    }

    &.service-order-column {
      .empty {
        font-size: 14px;
        font-style: italic;
        color: $gray-2;
      }

      .service-order {
        font-family: $secondary-font;
        font-weight: 400;

        .state {
          .unknown {
            font-style: italic;
          }
        }

        .supplier {
          font-size: 12px;
          color: $gray-3;
        }

        .icon {
          color: $gray-3;
        }

        .icon-container {
          height: 16px;
          width: 16px;

          .icon {
            font-size: 12px;
          }
        }

        .service-order-state,
        .icon-container {
          margin-right: 4px;
        }
      }
    }

    .state,
    .vehicle-info,
    .service-order,
    .text-container {
      overflow: hidden;
    }
  }
}

