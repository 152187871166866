
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.label {
  font-family: $secondary-font;
}

.summary {
  align-items: stretch;
  display: flex;
  gap: 40px;
}

.summary-item {
  flex-grow: 1;
}

.separator {
  border-left: 1px solid $light-gray-3;
}

.value {
  em {
    font-weight: 300;
  }
}
