
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.messages-modal {
  --messages-body-height:   510px;
  --messages-header-height: 73px;
  --messages-index-width:   330px;
  --message-form-width:     400px;

  .body {
    height: var(--messages-body-height);
    position: relative;

    .index {
      width: var(--messages-index-width);
      overflow-x: hidden;
      overflow-y: auto;

      border-right: 1px solid $light-gray-3;
    }

    .show {
      background-color: $light-gray;
      width: 100%;

      &.overlayed {
        width: calc(1156px - var(--messages-index-width) - var(--message-form-width));
        overflow-x: auto;
      }

      &.empty {
        position: relative;

        > .message {
          position: absolute;
          top: 20%;
          left: 50%; /* position the left edge of the element at the middle of the parent */

          transform: translateX(-50%);
          font-style: italic;
        }
      }
    }

    .new {
      width: var(--message-form-width);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      background-color: $white;
      height: calc(var(--messages-header-height) + var(--messages-body-height));
      position: absolute;
      right: 0;
      top: calc(0px - var(--messages-header-height));
    }
  }
}

