
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.message-row {
  cursor: pointer;
  border-bottom: 1px solid $light-gray-3;

  &:hover,
  &.selected {
    background-color: $light-gray;
  }

  &:active,
  &:focus {
    background-color: $light-gray-2;
  }

  .initials {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    padding: 3.5px 7px;
    background-color: $light-purple;
    border-radius: 80px;
    color: $purple;
    margin: 0 16px;
  }

  .message {
    overflow: hidden;
    padding: 16px 12px 16px 0;
  }

  .header {
    .motive {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
    }

    .timestamp {
      margin-left: 8px;
      font-weight: 400;
      font-size: 11px;
    }
  }

  .body {
    font-size: 12px;
    font-weight: 300;
    margin-top: 4px;
  }
}

