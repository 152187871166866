
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.narrow-attachment-card {
  .container {
    background-color: $white;
    border-radius: 4px;
    overflow: hidden;
    height: 32px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: relative;

    &.uploaded {
      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

        .name {
          color: $orange;
        }

        .actions {
          visibility: visible;
        }
      }
    }

    .actions {
      height: 24px;
      background: $light-gray-2;
      padding: 4px 8px;
      position: absolute;
      right: 8px;
      top: 4px;
      border-radius: 4px;
      visibility: hidden;
      cursor: default;
      z-index: 1;

      .action {
        cursor: pointer;

        &:hover {
          color: $orange;
        }

        & + .action {
          margin-left: 16px;
        }

        &.downloading {
          cursor: progress;
          color: $gray-2;
        }
      }
    }

    .content {
      height: 32px;
      padding: 0 8px;
      font-family: $secondary-font;
      font-weight: 400;

      .icon-container {
        width: 20px;
        height: 20px;
        margin-right: 8px;

        .icon {
          color: var(--attachment-color);
        }
      }

      .content-container {
        height: 32px;
        position: relative;
        width: 100%;
        overflow: hidden;

        .info-container {
          height: 32px;
          width: 100%;

          .name {
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
          }

          .checking {
            width: calc(100% - 8px);
          }

          .progress-bar {
            width: calc(100% - 8px);
            height: 8px;
            background-color: $light-gray-4;
            border-radius: 4px;
            overflow: hidden;
            margin-top: 4px;

            .progress {
              width: var(--progress);
              height: 100%;
              background-color: $orange;
            }
          }
        }
      }
    }
  }
}

