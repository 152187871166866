
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.quick-filter-button {
  --radius: 4px;
  align-items: center;
  background: transparent;
  border: 1px solid $light-gray-4;
  color: $dark-gray;
  cursor: pointer;
  display: flex;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 1.5;
  padding: 10px 18px;

  &:not(:first-child) {
    border-left: none;
  }

  &:first-child {
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }

  &:last-child {
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
  }

  &:focus,
  &:hover,
  &:active {
    border-color: $primary;
    color: $primary;
    outline: 1px solid $primary;
    outline-offset: -1px;
  }

  &:active {
    color: $dark-primary-2;
  }

  &:disabled {
    border-color: transparent;
    color: $gray;
    cursor: not-allowed;
  }

  .icon {
    font-size: 16px;
    margin-right: 8px;
  }

  .quick-filter-tooltip {
    margin-left: 8px;
  }
}
