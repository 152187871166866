
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-model-row {
  padding: 12px 0;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;

  &.loading {
    background: $light-gray-2;
  }
}

.vehicle-model-row + .vehicle-model-row {
  border-top: 1px solid transparent;
}

.icon-column {
  min-width: 72px;
}

.upper-row {
  margin-bottom: 4px;
}

.vehicle-model-name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  word-break: normal;
  overflow-wrap: break-word;

  &.link {
    &:hover {
      color: $primary;
    }
  }
}

.info-column {
  font-size: 14px;
  font-weight: normal;
  padding-right: 24px;

  .schedule-icon {
    font-size: 18px;
    margin-right: 8px;
    color: $gray-2;
  }
}

.content-column {
  flex-grow: 1;
  padding-right: 12px;
  margin-left: 16px;
}

.year-tag {
  margin-left: 8px;
}

.year-range {
  font-weight: 300;
  font-size: 12px;
  color: $gray-3;
  margin-left: 8px;
}

.incomplete {
  font-weight: 500;
  font-family: $secondary-font;

  .icon {
    font-weight: 900;
    color: $dark-warning;
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
}

.details > span {
  font-size: 14px;
}

.schedule-name {
  word-break: normal;
  overflow-wrap: break-word;
  max-width: 270px;
  cursor: auto;
}

.diamond-icon {
  font-size: 4px;
  font-weight: 900;
  color: $gray-3;
  width: 4px;
  height: 4px;
  margin: 8px;
}

.checkbox-select {
  padding-left: 16px
}
