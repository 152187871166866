
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.deal-preview-icon {
  .missing {
    margin-left: 8px
  }

  .icon {
    cursor: pointer;
  }
}

.popover {
  padding: 16px;
}

