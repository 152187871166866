
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.info-icon {
  cursor: help;
  font-size: 12px;

  :disabled & {
    visibility: hidden;
  }
}

.tooltip-container {
  color: $dark-gray;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 16px;

  .heading {
    display: flex;
    gap: 8px;
  }
}


.title {
  flex-grow: 1;
  font-family: $secondary-font;
  font-weight: 500;
}

.date {
  font-family: $primary-font;
}

.description {
  font-family: $primary-font;
  font-size: 12px;
}

.filters-container {
  background: $light-gray-2;
  border-radius: 8px;
  margin-top: 16px;
  padding: 8px;

  .heading {
    font-family: $secondary-font;
    font-size: 12px;
  }
}

.filters {
  list-style: none;
  margin: 0;
  padding: 0;
}

.filter-name {
  color: $gray-3;
  font-family: $secondary-font;
}

.filter-desc {
  font-family: $primary-font;
}
