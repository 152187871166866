
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.index {
  .loading,
  .erred {
    padding: 24px;
  }

  .count {
    padding: 4px 16px;
    font-weight: 400;
    font-size: 12px;
    background-color: $light-gray;
    border-bottom: 1px solid $light-gray-3;
  }
}

