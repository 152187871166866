
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.new-product {
  padding-bottom: 24px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;
  font-family: $secondary-font;
  font-weight: 500;
}

.type-section {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.form-section {
  padding-top: 24px;
}

.empty-section {
  > .subtitle {
    margin-bottom: 4px;
  }
}

.empty {
  display: block;
  font-style: italic;
}

.fields-content {
  margin-bottom: 24px;
}

.type-select,
.sap-input {
  width: 250px;
}

.submit-actions {
  .button:last-child {
    margin-left: 16px;
  }
}

