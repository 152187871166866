
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-issue-row {
  display: grid;
  grid-template-columns: 70px 1fr 1fr;

  &.loading {
    .column {
      color: $gray-2;

      ::v-deep.id-tag {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    &.content-column {
      overflow: hidden;

      .title {
        font-family: $secondary-font;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .id-tag {
        margin-left: 4px;
        font-size: 14px;
      }

      .description {
        .label {
          font-weight: 400;
          font-size: 12px;
          color: $gray-3;
        }
      }

      .icon {
        margin-right: 8px;
      }

      .vehicle-part-icon {
        mask-image: var(--svg-icon);
        mask-repeat: no-repeat;
        height: 16px;
        width: 16px;
        background-color: $dark-gray;

        &.loading {
          background-color: $gray-2;
        }
      }
    }
  }
}

