
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-package-row {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  height: 70px;

  & + .service-package-row {
    border-top: 1px solid transparent;
  }

  .id-column {
    width: 60px;
    margin-right: 24px;
  }

  .content-column {
    overflow: hidden;

    .name,
    .description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      .link {
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: 400;
        color: $dark-gray;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .counts-column {
    margin-left: 24px;
    margin-right: 16px;

    .count {
      & + .count {
        margin-left: 24px;
      }

      .count-icon {
        margin-right: 4px;
      }
    }
  }
}

