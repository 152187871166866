
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.product-header {
  .icon {
    margin-right: 16px;
    color: $gray-3;
    width: 16px;
    text-align: center;
  }

  .discarded-icon {
    margin-right: 4px;
  }

  .tag {
    margin-left: 4px;
  }

  .name {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
  }

  .deal-product-content {
    margin-top: 8px;

    .arrow {
      color: $light-gray-4;
    }
  }
}

