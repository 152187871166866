
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


table {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 1040px;
}

th, td {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
}

th {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  font-family: $secondary-font;
  color: $dark-gray;
  height: 48px;
  background: $light-gray-2;
}

.maintenance-column {
  width: 208px;
}

.empty,
.table-error {
  text-align: center;
  font-style: italic;
  padding: 32px 0;
  width: 100%;

  color: $gray-3;
}

.order-column {
  width: 44px;
}

.kilometrage-limit-column {
  width: 114px;
}

.time-limit-column {
  width: 109px;
}

.target-execution-time-column {
  width: 66px;
}

.free-charge-column,
.forbidden-in-internal-facilities-column,
.dealership-only-column {
  width: 52px;
}

.delete-column {
  width: 58px;
}

.order-column,
.free-charge-column,
.forbidden-in-internal-facilities-column,
.dealership-only-column {
  text-align: center;
}

.popover-content {
  padding: 8px;
}

.icon {
  font-size: 16px;
  color: $gray-2;
}

.maintenance-package {
  padding-left: 8px;
}

.svg-icon {
  background-color: $gray-2;
  height: 24px;
  width: 24px;
  mask-repeat: no-repeat;
  mask-position: center;

  &.free-of-charge {
    mask-image: var(--free-of-charge-icon);
  }

  &.forbidden-in-internal-facilities {
    mask-image: var(--forbidden-in-internal-facilities-icon);
  }

  &.dealership-only {
    mask-image: var(--dealership-only-icon);
  }
}

.diamond-icon {
  font-size: 8px;
  font-weight: 900;
  color: $gray-3;
  width: 8px;
  height: 8px;
}

