
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.targets-nested-fields {
  .label {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    margin-left: 32px;
    margin-bottom: 8px;
  }

  .fields + .fields {
    margin-top: 4px;
  }
}

