
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.count {
  margin-bottom: 24px;

  .icon-container {
    width: 16px;
    height: 16px;
    margin-right: 4px;

    .icon {
      color: $gray-3;
      font-size: 14px;
    }
  }
}

.assignee.open {
  margin-bottom: 275px;
}

.toggle {
  margin-top: 24px;

  .icon-container {
    width: 24px;
    height: 24px;

    .icon {
      font-size: 14px;
    }
  }
}

