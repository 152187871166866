
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.popover-preview-loading{
  width: var(--width);
  max-width: 100vw;

  .content {
    margin-top: 12px;

    ::v-deep .loading-line + .loading-line {
      margin-top: 4px;
    }
  }
}

