
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.maintenance-packages {
  .search {
    margin-bottom: 32px;
  }

  .buttons {
    margin-bottom: 16px;

    .action-popover-loader {
      width: 200px;

      + .action-popover-loader {
        margin-left: 24px;
      }
    }

    ::v-deep .movida-common__app-button{
      margin-left: 40px;
    }
  }

  .button {
    font-size: 14px;

    &.maintenance-package {
      .icon {
        margin-right: 4px;
        font-size: 14px;
      }
    }

    &.spreadsheets {
      .icon {
        margin-left: 4px;
        font-size: 12px;
      }
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

