
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.checkbox-field {
  .field-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $dark-gray;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $error;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;

    .checkbox-input {
      flex-shrink: 0;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: $light-gray;
      border: 1px solid $gray;
      margin: 4px 0;
      transition: all .1s;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        border-color: $orange;
      }

      &:active {
        background-color: $light-orange-2;
      }

      &.disabled,
      &.loading {
        background-color: $light-gray;
        border-color: $gray-3;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.loading {
        cursor: progress;
      }

      &:checked,
      &.indeterminate {
        background-color: $orange;
        border-color: $orange;
        position: relative;

        &:after {
          font-size: 14px;
          line-height: 14px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $white;
        }

        &:focus,
        &:active {
          border-color: $dark-orange-2;
        }

        &:active {
          background-color: $dark-orange-2;
        }

        &.disabled,
        &.loading {
          background-color: $light-gray-3;
          border-color: $light-gray-4;
        }
      }

      &:checked:not(.indeterminate) {
        &:after {
          font-family: 'Font Awesome 6 Pro';
          font-weight: 400;
          content: '\f00c';
        }
      }

      &.indeterminate {
        &:after {
          font-weight: 900;
          content: '—';
        }
      }
    }

    .checkbox-label {
      padding-left: 12px;
      margin-right: 12px;
      font-family: $secondary-font;
      font-weight: 300;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }

      &.loading {
        cursor: progress;
      }

      &.disabled,
      &.loading {
        color: $gray-2;
      }

      &.error {
        color: $error;
      }
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

.loading-lines {
  width: 20px;
  height: 20px;
}

