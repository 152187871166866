
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-select-field {
  .error {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;

    .link {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
    }
  }

  .empty {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;
  }

  ::v-deep .listed-option + .listed-option {
    border-top: 1px solid $light-gray-3;
  }
}

