
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.sortable-table-header-column {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0 12px;

  &:hover {
    background-color: $light-gray-3;
  }

  .sort-icon {
    font-size: 18px;
    color: $orange;
    margin-left: 8px;
  }
}

