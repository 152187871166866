
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.block-supplier .block-button,
.unblock-supplier .unblock-button {
  border-top: 1px solid $light-gray-3;
}

