
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.search-form {
  width: 100%;

  .row {
    align-items: end;
    display: grid;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    // Columns
    &:nth-child(1) {
      grid-template-columns: 3fr 2fr;
    }
    &:nth-child(2) {
      grid-template-columns: minmax(0, 5fr) repeat(3, minmax(0, 2fr)) 40px;
    }
  }

  .submit {
    min-width: unset;
    padding: 0;
    width: 40px;
  }
}
