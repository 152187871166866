
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.sidebar {
  $sidebar-width: 76px;
  width: $sidebar-width;
  min-width: $sidebar-width;
  white-space: nowrap;
  background-color: $light-gray;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid $light-gray-4;
  padding: 20px 0;
  transition: width .1s ease-in-out, min-width .1s ease-in-out;

  &.expanded {
    $sidebar-width: 236px;
    width: $sidebar-width;
    min-width: $sidebar-width;
  }

  .control,
  .separator-icon {
    height: 40px;
  }

  .control{
    cursor: pointer;
    color: $gray-2;

    &:hover {
      color: $orange;

      .gmf {
        ::v-deep .inside {
          fill: $orange;
        }
      }
    }

    .collapse-icon {
      margin: 0 8px 0 28px;
    }

    .gmf {
      width: 38px;

      ::v-deep .inside {
        fill: $gray-3;
      }
    }
  }

  .separator-icon {
    .icon-container {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 28px;

      .icon {
        font-size: 12px;
        color: $light-gray-4;
      }
    }
  }

  .sections {
    height: 100%;

    .separator {
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      color: $gray-2;
      margin-left: 28px;
      height: 40px;
    }
  }
}

