
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-block-ongoing-preview {
  width: 261px;
  max-height: 212px;

  .header {
    .icon {
      font-size: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: $primary-font;
      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .content {
    margin-top: 12px;

    .text {
      font-family: $primary-font;
      color: $dark-gray;
      font-size: 14px;
      font-weight: 300;
    }

    .description {
      margin-top: 8px;

      .text,
      .link ::v-deep .app-span {
        &.crop {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          white-space: normal;
          text-overflow: unset;
        }
      }

      .no-description {
        font-family: $primary-font;
        color: $gray-2;
        font-style: italic;
      }
    }

    .action {
      margin-top: 8px;
    }
  }
}

