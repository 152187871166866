
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 4px;
  font-family: $secondary-font;
  font-weight: 500;
}

.subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.header {
  margin-bottom: 24px;
}

.checkbox-field + .checkbox-field {
  margin-left: 42px;
}

.city-option {
  min-height: 50px;
  padding: 8px;
  border: 1px solid $light-gray-3;
  border-bottom: none;

  &.disabled {
    background: $light-gray-3;
    color: $gray-3;

    .icon {
      color: $gray-3;
    }
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 1px solid $light-gray-3;
  }

  .icon {
    font-size: 16px;
    color: $gray-2;
    margin-right: 12px;
  }
}

.nationwide-support-field {
  margin-bottom: 24px;
}

.supplier {
  margin-bottom: 24px;
}

.city-section {
  margin-bottom: 24px;
}

.city-subtitle {
  display: block;
  font-family: $secondary-font;
  font-weight: normal;
}

.supported-subtitle {
  margin-bottom: 16px;
}

.facilities-tag {
  margin-left: 8px;
}

.flag-icon {
  margin-right: 8px;
}

.company-section {
  .row + .row {
    margin-top: 24px;
  }

  .checkbox-field {
    width: 165px;
    flex-shrink: 0;
  }
}

.business-hours-actions {
  margin-bottom: 16px;
}

.shift-container {
  margin-top: 32px;
  margin-left: 124px;
  margin-bottom: 8px;

  .shift {
    min-width: 191px;
    font-weight: 500;
    font-size: 12px;
    font-family: $secondary-font;
  }

  .shift + .shift {
    margin-left: 24px;
  }
}

.info-icon {
  margin-left: 8px;
}

.scheduling-notice {
  width: 150px;
}

.extra-section {
  min-height: 56px;
}

.default-business-hour-btn {
  margin-left: 16px;

  .fa-info-circle {
    margin-left: 8px;
  }
}

