
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.new.postponement {
  .section {
    padding: 16px 24px;

    & + .section {
      border-top: 1px solid $light-gray-3;
    }
  }

  .row {
    padding: 4px;
  }

  .diamond-icon {
    color: $gray-2;
    font-size: 6px;
    padding: 0 8px;
    vertical-align: middle;
  }

  .icon {
    margin-right: 12px;
    line-height: 24px;
  }

  .check-square {
    margin-top: 7px;
  }

  .capital {
    text-transform: capitalize;
  }

  .estimative {
    margin-bottom: 25px;
    border-bottom: 1px solid $light-gray-3;
  }

  .final {
     margin-bottom: 12px;
  }

  .description {
    margin-top: 16px;

    ::v-deep .input-inner {
      height: 110px;
    }
  }
}

