
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.table-kind-tab-group {
  background-color: $light-gray-3;
  border-radius: 32px;
  padding: 8px;

  .option {
    padding: 8px 16px;
    border-radius: 32px;
    color: $gray-4;
    font-weight: 400;
    cursor: pointer;

    + .option {
      margin-left: 4px;
    }

    &.selected {
      background-color: $white;
      color: $dark-gray;
    }

    .icon {
      margin-right: 8px;
      font-size: 14px;
    }
  }
}

