
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.timeline-popover-quote-issued {
  padding: 16px;
  width:   400px;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .content {
    font-size: 14px;
    line-height: 150%;
    font-family: $secondary-font;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      font-family: $secondary-font;
      font-size: 16px;
      font-weight: 500;
      color: $dark-gray;

      > .state.badge {
        padding: 4px 12px;
        border-radius: 24px;

        font-family: $secondary-font;
        font-size: 12px;
        line-height: 150%;

        &.draft {
          background: $light-gray-3;
        }

        &.reviewed {
          background: $light-purple;
        }
      }
    } // .title

    .subtitle {
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 300;
      color: $dark-gray;
    }

    .info {
      margin-top: 8px;
    }
  }

  .empty {
    .empty-message {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }
  }
}


.icon-container {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  .icon {
    font-size: 12px;
    color: $gray-3;

    &.warning {
      color: $dark-warning;
    }
  }

  &.main {
    width: 24px;
    height: 24px;
    margin-right: 16px;

    .icon {
      font-size: 20px;
      color: $purple;
    }

    &.empty {
      .icon {
        color: $gray-2;
      }
    }
  }
}

