
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.maintenance-table-kind-tag {
  font-family: $secondary-font;
  font-size: 12px;
  font-weight: 500;

  &.multiservice {
    color: $dark-info;
  }

  &.dealership {
    color: $purple;
  }

  &.muted {
    color: $gray-2;
  }

  .icon {
    margin-right: 4px;
    font-size: 12px;
  }
}

