
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-support-card {
  width: 231px;
  height: 145px;
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  padding: 24px;

  &.active {
    border-color: $dark-green;
  }

  .top {
    margin-bottom: 24px;

    ::v-deep .menu-button {
      height: 24px;
      padding: 4px;
    }

    .status-loading {
      width: 74px;
    }

    .action-loading {
      width: 24px;
    }
  }

  .bottom {
    .service-type-icon {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .service-name {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
      overflow-wrap: anywhere;
    }
  }
}

