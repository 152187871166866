
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.intro {
  display: flex;
  gap: 16px;
}

.section {
  background: $light-gray-2;
  border-radius: 8px;
  flex-shrink: 0;

  &.tree {
    flex-grow: 1;
  }

  &.timeline {
    padding: 16px;
  }
}
