
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.messages-modal-header {
  border-bottom: 1px solid $light-gray-3;
  display: flex;
  height: var(--messages-header-height);
  justify-content: space-between;
  padding: 16px 24px;
}

.info-container {
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
}

.info {
  display: flex;

  & + .info {
    border-left: 1px solid $light-gray-3;
    margin-left: 24px;
    padding-left: 24px;
  }

  .icon-container {
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 8px;
    width: 16px;

    .icon {
      font-size: 14px;
      color: $gray-3;
    }
  }

  .text,
  .address,
  .cost-center {
    font-weight: 400;
    font-size: 12px;
  }

  .text {
    font-family: $secondary-font;
    margin-bottom: 4px;
  }

  .address,
  .cost-center {
    color: $gray-3;
  }

  .diamond-icon {
    font-size: 4px;
    margin: 0 4px;
    color: $gray-3;
  }

  .state {
    margin-top: 4px;
    width: 94px;
  }
}

.popover-trigger-area {
  cursor: help;
}
