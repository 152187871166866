
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.menu-wrapper {
  position: relative;
}

.menu-content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  margin: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid $gray;
  border-top: none;
  z-index: 1000;

  padding: 0;
}

li {
  list-style: none;

  .option {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray-3;
    cursor: pointer;

    &.active {
      color: $primary;
      background: $light-gray-2;
    }

    &.focus {
      background: $light-gray-2;
      color: $dark-primary-2;
    }
  }
}

.input-wrapper {
  cursor: pointer;
  position: relative;
  appearance: none;
  background-color: $white;
  background-image: none;
  border-radius: 4px;
  border: 1px solid $gray;
  border-style:solid;
  box-sizing: border-box;
  color: $gray-3;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 12px;
  transition: border-color 0.2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
  text-align: left;

  input {
    width: 100%;
    border: none;
    background: transparent;
  }

  &:hover {
    border-color: $orange;
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:focus,
  &.focus {
    border-style:solid;
    outline: none;
    border-color: $orange;
    border-width: 1px;
  }

  &.disabled {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-3;
    cursor: not-allowed;

    .value {
      color: $gray-2;
    }
  }
}

.placeholder {
  font-weight: 300;
  color: $gray;
}

.arrow-icon {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform .2s ease;

  &.open {
    transform: rotate(180deg);
  }

  &:before {
    position: relative;
    right: 0;
    top: 24%;
    color: $gray-2;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: $gray-3 transparent transparent;
    content: "";
  }
}

