
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.index {
  .header {
    margin-bottom: 16px;
  }

  .add {
    font-size: 14px;

    .icon {
      margin-right: 4px;
    }
  }

  .retry {
    margin-left: 4px;
  }

  .empty {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 24px 0;

    .illustration {
      --size: 48px;
      width: var(--size);
      height: var(--size);
    }

    .empty-message {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      margin: 8px 0;
      color: $gray-3;
    }
  }

  .attachments {
    margin: -24px 0 0 -24px;

    .attachment-card,
    .empty-card {
      margin-left: 24px;
      margin-top: 24px;
    }
  }
}

