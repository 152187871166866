
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.product-type-icon {
  --icon-color: #{$gray-3};

  color: var(--icon-color);
  font-size: var(--size);
  text-align: center;

  .icon {
    font-weight: var(--weight);
  }

  ::v-deep .product-part-icon .icon {
    --color: var(--icon-color);
  }
}

