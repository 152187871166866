
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.row + .row {
  margin-top: 24px;
}

.labor-cost-linked {
  margin-top: 25px;
  border: 1px solid $light-gray-4;
  border-radius: 4px;
  padding: 9px;

  .indicator {
    margin-left: 16px;
  }
}

.section {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.type {
  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
  }

  .service-icon {
    margin-right: 8px;
  }
}

.radio-label {
  padding-left: 12px;
  font-size: 14px;
  font-family: $secondary-font;
  font-weight: 500;
  margin-right: 8px;

  .info-icon {
    padding-left: 4px;
  }
}

.radio-icon {
  margin-left: 12px;
}

:not(.disabled-option):not(.disabled) {
  > .label-container > * {
    cursor: pointer;
    user-select: none;
  }
}

.movida-common__radio-field.movida-common__horizontal {
  ::v-deep.movida-common__radio-field-input {
    border: 1px solid $light-gray-4;
    border-radius: 4px;
    padding: 5px;
  }
}

.section-title {
  margin-bottom: 16px;

  .title-icon {
    margin-right: 8px;
    font-size: 16px;
  }

  .title {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
  }
}

.subtitle {
  font-weight: 300;
  font-size: 16px;
}

.issues-list {
  margin-top: 16px;
}

.vehicle-issues {
  ::v-deep.rows {
    max-height: 520px;
    overflow-y: scroll;
  }
}

.spacer {
  margin-bottom: 900px;
}

