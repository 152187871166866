
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-package-product-row {
  height: 70px;

  &:hover {
    background-color: $light-gray;
  }

  &.destroyed {
    height: 69px;
    background-color: $light-gray-2;
    font-family: $secondary-font;
    font-weight: 400;

    .undo {
      font-weight: normal;
      margin-left: 8px;
    }
  }

  &.disabled {
    background-color: $light-gray-2;
    cursor: not-allowed;
  }

  .added {
    color: $success;
    font-size: 14px;
    font-weight: 400;

    span {
      font-style: italic;
      margin-left: 4px;
    }
  }

  .actions {
    margin: 0 26px;

    .remove-button {
      font-size: 16px;

      &:hover {
        background-color: $light-gray-3;
        transition: 0s;
      }
    }
  }
}

