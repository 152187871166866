
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.app-list-header {
  padding-top: 8px;
  padding-bottom: 8px;
}

.results-indicator {
  text-align: right;
  flex-grow: 1;
}

