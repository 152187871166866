
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.attachments-carousel {
  .navigation {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $orange;
    color: $white;
    font-size: 16px;
    cursor: pointer;

    &.disabled {
      background-color: $light-gray-3;
      border: 1px solid $gray;
      color: $gray-3;
      cursor: not-allowed;
    }
  }

  .preview {
    background-color: $white;
    max-width: 1156px;
    border-radius: 8px;
    margin: 16px 32px 24px;
    height: 672px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);

    ::v-deep .preview-container,
    ::v-deep .data {
      height: 672px;
    }
  }

  .thumbnail {
    & + .thumbnail {
      margin-left: 16px;
    }
  }

  .pages {
    margin-top: 24px;

    .page {
      width: 23px;
      height: 24px;
      border-radius: 4px;
      background-color: $light-gray-3;
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;

      & + .page {
        margin-left: 16px;
      }

      &.selected {
        background-color: $light-orange;
        color: $orange;
      }
    }
  }
}

