
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.modal-body {
  .header {
    border-bottom: 1px solid $light-gray-3;
    display: grid;
    font-family: $secondary-font;
    gap: 8px 24px;
    padding: 16px 24px;
    grid-template-columns: repeat(2, 1fr);

    .info {
      align-items: baseline;
      display: flex;
      font-size: 14px;

      .icon {
        color: $gray-3;
        margin-right: 8px;
      }

      .text {
        color: $dark-gray;
        font-weight: 400;
        line-height: 1.5;
      }

      .number {
        font-family: $primary-monospace-font;
      }

      .separator {
        align-self: center;
        color: $gray;
        font-size: 4px;
        margin: 4px;
      }

      .license-plate {
        align-self: center;
      }
    }
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px 24px;

    .messages {
      list-style: none;
      margin: 0;
      padding: 0;

      .terminator {
        .icon-wrapper {
          align-items: center;
          display: inline-flex;
          justify-content: center;
          height: 24px;
          width: 24px;

          .icon {
            color: $gray;
            font-size: 12px;
          }
        }

        .label {
          color: $gray-3;
          font-size: 14px;
          margin-left: 16px;
        }
      }

      &.loading {
        > .row {
          display: flex;
          gap: 32px;
          padding-left: 56px;

          .column {
            &:first-child {
              width: 74px;
            }

            &:last-child {
              flex-grow: 1;
            }
          }

          &:not(:last-child) {
            margin-bottom: 60px;
          }
        }
      }
    }

    .empty {
      font-style: italic;
      padding: 32px;
      text-align: center;
    }
  }
}


