
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-row-skeleton {
  display: grid;
  grid-template-columns: 132px 109px 92px 1fr 124px 100px 64px;

  .column + .column {
    margin-left: 24px
  }
}

