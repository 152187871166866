
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


svg {
  width: 24px;
  height: 11px;
  fill: $gray-3;

  &.big {
    width: 40px;
    height: 15px;
  }
}

.outside {
  fill: $dark-blue;
}

.inside {
  fill: $white;
}

