
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-manufacturer-icon {
  width: var(--size);
  height: var(--size);
  text-align: center;
}

