
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit-supplier-service-score-settings {
  .header {
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .form {
    .fields {
      .field {
        &.coefficient {
          width: 180px;
        }

        &.strategy {
          width: 380px;
        }

        + .field {
          margin-top: 16px;
        }

        .checkbox-label {
          margin-right: 4px;
        }
      }
    }

    .footer {
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid $light-gray-3;
    }
  }
}

