
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-status-tag {
  background: $gray-3;
  padding: 2px 8px;
  border-radius: 32px;
  font-family: $secondary-font;
  color: $white;
  height: 24px;
  font-weight: 500;
  user-select: none;

  &.active {
    background: $green;

    &.accent {
      background: $dark-green-2;
    }
  }

  .icon {
    margin-right: 4px;
  }
}

