
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.show {
  padding-bottom: 24px;
}

.header {
  padding: 24px 0;
  border-bottom: 1px solid $light-gray-3;
  margin-bottom: 24px;
}

.vehicle-model-name {
  font-family: $secondary-font;
  font-size: 30px;
  font-weight: 500;
  word-break: normal;
  overflow-wrap: break-word;
}

.id-tag {
  margin: 0 8px;
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.section-title {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 24px;
}

.info-label + .info-label {
  margin-left: 40px;
}

.subtitle {
  white-space: normal;
  text-align: left;
}

.subsection {
  margin-bottom: 24px;

  .subtitle {
    display: block;
    margin-bottom: 8px;
  }
}

