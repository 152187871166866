
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.license-plate {
  width: 75px;
  height: 24px;
  background-color: $light-gray;
  border-radius: 4px;
  border: 1px solid $light-gray-4;
  overflow: hidden;

  &.small {
    width: 59px;
    height: 18px;

    .top {
      height: 2px;
    }

    .value {
      font-size: 12px;
      padding: 0 4px;
      line-height: 13px;
    }
  }

  &.loading {
    color: $light-gray-4;

    .top {
      background-color: $light-blue;
    }
  }

  .top {
    background-color: $dark-blue;
    height: 4px;
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0 8px;
  }
}

