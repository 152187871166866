
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.new-supplier-deal-products-data-load {
  .body {
    .form {
      .replace-icon {
        width: 56px;
        height: 56px;
        margin-bottom: 16px;
      }

      .fields {
        margin-top: 24px;

        .checkbox-label {
          margin-right: 4px;

          .replace-all {
            font-family: $secondary-font;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }

    .alert {
      .icon {
        font-size: 14px;
      }
    }
  }
}

