
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-service-row {
  grid-template-columns: minmax(172px, 1fr) 42px 101px 101px;
  min-height: 32px;
  border-top: 1px solid $light-gray-4;
  padding: 8px 0;

  .column {
    padding: 0 8px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
  }
}

