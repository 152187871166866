
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.attachment-preview-data {
  overflow-x: hidden;

  .preview-container {
    width: 826px;
    height: 760px;

    background-color: $light-gray-3;
    transition: background-color .1s ease-in;

    &.preview-error {
      .message {
        font-style: italic;
        margin: 8px 0;
      }

      .reload {
        font-size: 14px;
      }
    }

    &.preview-error,
    &.preview-loading {
      .icon {
        font-size: 32px;

        &.spin {
          animation: fa-spin 0.6s infinite linear;
          cursor: wait;
        }
      }
    }

    &.preview {
      background-color: $dark-gray;
      overflow: hidden;

      .image-preview {
        max-width: 826px;
        max-height: 760px;
      }

      .video-preview,
      .audio-preview {
        width: 100%;
        height: 100%;
      }

      .pdf-preview {
        width: 826px;
        height: 760px;
      }
    }

    &.no-preview {
      .message {
        font-style: italic;
      }
    }

    .file-icon {
      color: var(--icon-color);
      font-size: 32px;
      margin-bottom: 8px;

      &.audio-player-icon {
        cursor: pointer;
      }
    }
  }

  .data {
    width: 330px;
    height: 760px;
    overflow: hidden;

    .top {
      padding: 24px;

      .actions {
        .action {
          width: 24px;
          height: 24px;

          & + .action {
            margin-left: 16px;
          }

          .icon {
            cursor: pointer;
          }
        }
      }

      .header {
        margin-top: 24px;

        .name-field {
          ::v-deep .input-inner {
            max-height: 300px;
          }
        }

        .name {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 8px;
          word-break: break-all;
        }

        .type {
          ::v-deep {
            .icon {
              margin-right: 4px;
            }

            .type {
              color: $gray-3;
            }
          }
        }

        .diamond-icon {
          font-size: 4px;
          color: $gray-2;
          margin: 0 8px;
        }

        .size {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 12px;
          color: $gray-3;
        }
      }
    }

    .description {
      overflow: auto;
      padding: 0 24px;

      &.overflowing {
        box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.1);
      }

      .empty {
        font-weight: 400;
        color: $gray-3;
        font-style: italic;
      }

      .description-field {
        height: 100%;

        ::v-deep .input-wrapper {
          height: 100%;

          .input-inner {
            resize: none;
            height: calc(100% - 2px);
          }
        }
      }

      .description-content {
        white-space: break-spaces;
      }
    }

    .footer {
      margin: 24px;
    }
  }
}

