
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.ticket-types {
  padding-bottom: 24px;
}

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title-icon {
  margin-right: 16px;
  font-size: 30px;
}

.title-text {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
}

.ticket-type-rows {
  border: 1px solid $light-gray-3;
  border-radius: 4px;

  .ticket-type-row + .ticket-type-row {
    border-top: 1px solid $light-gray-3;
  }
}

