
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.dot {
  font-size: 4px;
  color: $gray;
}

.list {
  background: white;
  border-radius: 4px;

  .row {
    display: grid;
    align-items: center;  // vertical align

    // name, quantity, price, totalPrice
    grid-template-columns: 1fr 42px 100px 100px;

    border-bottom: 1px solid $light-gray-3;

    .column {
      display: flex;
      align-items: center;  // vertical align
      flex-grow: 1;

      padding: 8px;
      min-height: 32px;
    }
  }

  > .header {
    background: $light-gray-2;
    border-radius: 4px 4px 0 0;

    .column {
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      color: $gray-3;
    }
  }

  > .body::after {
    background: $light-gray-2;
    border-radius: 0 0 4px 4px;
    content: ' ';
    display: block;
    height: 3px;
  }

  > .footer {
    .total {
      gap: 8px;
      padding: 0 8px;
      min-height: 32px;

      .label {
        font-family: $primary-monospace-font;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $gray-3;
      }
    }
  }
}


.text {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $dark-gray;
}

.subtext {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: $gray-3;
}

.number {
  font-family: $primary-monospace-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $dark-gray;
}

