
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


td {
  vertical-align: top;
  padding: 10px 4px;
  font-weight: normal;
}

.order {
  background-color: $light-gray-3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-family: $secondary-font;
  margin-left: 8px;
  font-weight: 500;
}

.empty {
  color: $gray-3;
}

.free-of-charge,
.forbidden-in-internal-facilities,
.dealership-only {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.free-of-charge {
  background-color: $light-success-2;
}

.forbidden-in-internal-facilities {
  background-color: $light-orange;
}

.dealership-only {
  background-color: $light-orange;
}

.maintenance-package {
  cursor: pointer;
}

.discarded-icon {
  margin-right: 4px;
}

