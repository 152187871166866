
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.login {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  max-width: unset!important;
}

.card {
  padding: 32px;
  background: $white;
  width: 400px;
  border-radius: 4px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
  position: relative;

  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .stamp {
    position: absolute;
    top: -80px;
    left: 200px;
  }
}

.logo {
  top: -95px;
  width: 112px;
}

.welcome {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 38px;
  color: $dark-gray;
  top: -43px;
  text-align: center;
  width: 500px;
}

.form {
  width: 100%;
}

.username {
  margin-bottom: 16px;
}

.icon {
  margin-right: 8px;
}

.reset {
  margin-top: 16px;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $gray-3;
}

.arrow-up {
  $border-length: 19px;
  top: 0;
  width: 0;
  height: 0;
  border-left: $border-length solid transparent;
  border-right: $border-length solid transparent;
  border-bottom: $border-length solid $white;
}

.vertical-absolute {
  position: absolute;
  transform: translateY(-100%);
}

.error-message {
  font-size: 14px;
  color: $error;
  margin-top: 20px;
  font-weight: normal;
  text-align: center;
  background: #ffefef;
  padding: 8px;
  border-radius: 4px;
}

.fields {
  margin-bottom: 40px;

  &.error {
    margin-bottom: 20px;
  }
}

