
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.app-tag {
  display: inline-block;
}

.app-tag-label {
  padding: 1px 8px;
  border-radius: 50px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;

  border: 1px solid transparent;

  // colors
  // ---
  &.purple {
    background: #F9E8FF;
    color: $purple;
  }

  &.green {
    background: $light-success;
    color: $dark-success-2;
  }

  &.aqua {
    background: $light-aqua;
    color: $dark-aqua;
  }

  &.blue {
    background: #E5F3FF;
    color: #1E71B8;
  }

  &.gray {
    background: $light-gray-3;
    color: $dark-gray;
  }

  &.simple-gray {
    color: $dark-gray;
  }

  &.white {
    background: $light-gray;
    color: $dark-gray;
    border: 1px solid $light-gray-4;
    font-weight: 300;
  }

  &.orange {
    background: $primary;
    color: $white;
  }

  &.orange-light {
    background: $light-orange;
    color: #333333;
    border: 1px solid $light-orange;
    font-weight: 300;
  }

  // states
  // ---
  &.disabled {
    background: $white;
    color: $light-gray-4;
    text-decoration-line: line-through;
    border: 1px solid $light-gray-4;
  }

  // sizes
  // ---
  &.big,
  &.large {
    font-size: 20px;
    height: 26px;
  }

  &.normal,
  &.medium {
    font-size: 14px;
    height: 19px;
  }

  &.small {
    font-size: 12px;
    height: 16px;
  }
}

