
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.label {
  color: $dark-gray;
  display: block;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 8px;

  .daterange-field:focus-within & {
    color: $primary;
  }

  .has-error & {
    color: $error;
  }
}

.info-icon {
  cursor: help;
  font-size: 14px;
  margin-left: 4px;
}

.error-message {
  color: $error;
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}
