
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.section-title{
  padding-bottom: 24px;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.title-icon {
  margin-right: 8px;
  font-size: 24px;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  font-family: $secondary-font;
  margin-bottom: 8px;
  display: block;
}

.row {
  margin-bottom: 8px;
}

.column {
  padding: 16px 0px;
}

.location{
  margin-top: 42px;
}

.list {
  margin-top: 24px;
}

.section{
  margin-bottom: 24px;
  border-top: 1px solid $light-gray-3;
}

