
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.sidebar-item {
  .disabled-item {
    cursor: not-allowed;
    color: $gray-3;
  }

  .icon {
    margin: 0 8px 0 24px;
  }

  .text {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    color: $gray-4;
  }

  .item {
    padding: 10px 0;
    border-left: 4px solid $light-gray-3;
    border-radius: 0;
    width: 100%;
    font-size: 16px;
    user-select: none;

    &.link {
      color: $gray-3;
      position: relative;
      height: 40px;

      &:hover {
        color: $orange;

        & ::v-deep .custom-icon {
          background-color: $orange;
        }

        .text {
          color: $orange;
        }
      }

      &.active {
        color: $orange;
        background-color: $light-orange-2;

        & ::v-deep .custom-icon {
          background-color: $orange;
        }

        .text {
          color: $orange;
        }
      }

      &.active::before {
        content: '';
        height: 40px;
        position: absolute;
        left: -4px;
        border-right: 4px solid $orange;
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

