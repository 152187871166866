
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.heading {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 400;
}

.supplier-deal-products-data-loads {
  max-height: 600px;

  .empty,
  .error-message {
    font-style: italic;
  }

  .data-load {
    height: 55px;

    & + .data-load {
      border-top: 1px solid $light-gray-3;
    }

    .file-icon {
      width: 24px;
      height: 24px;
      margin-right: 24px;
      margin-left: 12px;

      .icon {
        font-size: 24px;
      }
    }

    .content {
      height: 100%;
      overflow: hidden;

      .info {
        font-size: 14px;
        overflow: hidden;

        .name-link {
          max-width: 100%;

          .name {
            font-family: $secondary-font;
            font-size: 14px;
            font-weight: 400;
            color: $dark-gray;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              color: $orange;
            }
          }
        }

        .secondary {
          .diamond-icon {
            margin: 0 8px;
            font-size: 4px;
            color: $gray-3;
          }
        }
      }

      .status {
        height: 24px;
        width: 24px;
        margin-right: 12px;
        margin-left: 12px;

        .icon {
          font-size: 16px;

          &.success {
            color: $dark-success;
          }

          &.error {
            color: $error;
          }
        }
      }
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

