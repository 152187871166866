
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.actions {
  padding: 24px;
  border-top: 1px solid $light-gray-3;
}

