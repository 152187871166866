
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.service-order-associations-section {
  align-items: stretch;
  display: grid;
  gap: 80px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  .section {
    position: relative;

    &:not(:first-child)::before {
      border-left: 1px solid $light-gray-3;
      content: ' ';
      height: 100%;
      left: -40px;
      position: absolute;
    }

    ::v-deep .header {
      margin-bottom: 0 !important;
    }
  }
}
