
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.vehicle-issue-row {
  display: grid;
  grid-template-columns: 1fr 70px;

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    &.content-column {
      overflow: hidden;

      .vehicle-part,
      .symptom {
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        margin-right: 8px;
      }

      .diamond-icon {
        margin: 0 8px;
        font-size: 4px;
        color: $gray-3;
      }
    }
  }
}

