
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.show {
  .back {
    color: $dark-gray;
    font-size: 14px;
    margin-bottom: 8px;

    &:hover {
      color: $orange;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .header {
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 30px;
      margin-bottom: 8px;

      * + * {
        margin-left: 8px;
      }
    }

    .info {
      * + * {
        margin-left: 8px;
      }

      .version {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 20px;
        color: $gray-3;
      }

      .icon-container {
        height: 20px;
        width: 20px;

        .icon {
          font-size: 12px;
        }
      }

      .id,
      .fipe {
        font-family: $primary-monospace-font;
        font-weight: 400;
        font-size: 18px;
        color: $gray-2;
      }
    }

    .info-row {
      margin-top: 24px;
      padding: 16px 0;
      border: 1px solid $light-gray-3;
      border-radius: 8px;

      .info-row-item {
        flex: 1 1 0;
        padding: 0 16px;

        + .info-row-item {
          border-left: 1px solid $light-gray-3;
        }

        .info-icon {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 18px;

          .icon-container {
            width: 20px;
            height: 20px;
            margin-right: 16px;

            .icon {
              color: $purple;
              font-size: 16px;
            }
          }
        }

        .composite-info {
          font-family: $secondary-font;
          font-weight: 400;

          .icon-container {
            width: 24px;
            height: 24px;
            margin-right: 16px;

            &.store-icon {
              mask-size: cover;
              background-color: $gray-3;
            }

            .icon {
              color: $gray-3;
            }
          }

          .gmf {
            height: 8px;
          }

          .state-origin {
            margin-left: 4px;
          }

          .unknown,
          .icon-container .icon.unknown {
            color: $gray-2;
          }
        }
      }
    }
  }

  .tickets-title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 20px;
  }
}

.modal {
  padding: 16px 24px;
}

