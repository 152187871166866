// XXX: carregar depois de _colors.scss

// text colors
$text:        $dark-gray;
$placeholder: $light-gray-4;


// fonts
$primary-font:   "Open Sans", Helvetica, Arial, sans-serif;
$secondary-font: "Rubik", Helvetica, Arial, sans-serif;

$primary-monospace-font: "Roboto Mono", "Lucida Console", Monaco, monospace;
