
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.header {
  margin-top: 24px;
  padding-bottom: 20px;
}

.title-text {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
  margin-right: 8px;
}

.icon {
  margin-right: 8px;
}

.back-btn {
  font-size: 18px;
  font-weight: 300;
  color: $gray-3;

  &:hover {
    color: $gray-2;
  }
}

.state-info,
.breadcrumbs {
  margin-bottom: 14px;
}

.page-content {
  margin-top: 24px;
}

.cancel-btn {
  margin-right: 24px;
}

.app-button.amend-btn {
  color: $primary;
}

.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.amendment-info {
  .message,
  .link {
    font-size: 14px;
    font-weight: 400;
  }

  .message {
    font-weight: 400;
    margin-right: 4px;
  }
}

