
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.index-filter-tab {
  .loading {
    width: 130px;
    margin-right: 12px;

    ::v-deep .shine-loading {
      border-radius: 32px;
    }
  }

  .button {
    padding: 8px 12px;
    border: 1px solid $light-gray-3;
    border-radius: 32px;
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 500;
    color: $dark-gray;
    cursor: pointer;

    &.active {
      background-color: $light-gray-2;
      border-color: $light-gray-2;
      color: $orange;
    }

    &.disabled {
      cursor: not-allowed;
      color: $gray-2;
    }

    &:hover:not(.disabled) {
      color: $orange;
    }

    .icon {
      margin-right: 8px;
    }
  }
}

