
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.sidebar-icon {
  width: 24px;
  height: 24px;
  font-size: 16px;

  .custom-icon {
    mask-size: cover;
    background-color: $gray-3;

    &.stores {
      width: 24px;
      height: 24px;
    }

    &.vehicle-parts{
      width: 16px;
      height: 16px;
    }
  }
}

