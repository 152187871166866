
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.section {
  padding: 16px 32px;

  + .section {
    border-top: 1px solid $light-gray-3;
  }

  &.loading {
    min-height: 151px;
  }
}

.global-price {
  margin-right: 24px;
  height: 40px;
  background-color: $light-gray-2;
}

.icon {
  margin-left: 12px;
  margin-right: 16px;
  font-size: 20px;
  color: $gray-2;
}

.text {
  font-family: $secondary-font;
  font-size: 16px;
  color: $dark-gray;
  font-weight: normal;
}

