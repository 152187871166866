
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.complementary-state {
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  padding: 4px 8px;

  .icon {
    color: $gray-3;
    margin-right: 6px;
  }

  .label {
    color: $dark-gray;
    font-family: $secondary-font;
    font-size: 12px;
    line-height: 1.5;
  }
}
