
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.mobiauto-version-row {
  padding: 12px 0;
  min-height: 70px;
}

.mobiauto-version-row + .mobiauto-version-row {
  border-top: 1px solid $light-gray-3;
}

.icon-column {
  padding-left: 24px;
  width: 72px;
}

.content-column {
  display: block;
  min-width: 500px;
  flex-grow: 1;
}

.price-column {
  display: block;
  min-width: 100px;
}

