
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.ticket-row {
  display: grid;
  grid-template-columns: 70px 40px 0.6fr 120px 1fr 180px 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .icon-model {
      margin-right: 8px;
    }

    .diamond-icon {
      margin: 0 4px 0 4px;
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
    }

    .button {
      overflow: hidden;
    }

    .plate {
      background-color: $light-gray;
      border: 1px solid $light-gray-4;
      font-family: $primary-monospace-font;
      font-size: 14px;
      color: $dark-gray;
    }

    .initials {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
      color: $gray-3;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .tag {
      margin-left: 4px;
    }
  }
}

.ticket-row {
  .column.ticket-column {
    .link {
      &:hover {
        .text {
          color: $orange;
        }
      }
    }
  }
}

