
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        

.button-content {
  width: 56px;
  text-align: right;
}

.tire-size-fields {
  margin-bottom: 24px;
}

.full {
  flex-grow: 1;
}
