
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-selected-option {
  .type-icon {
    margin: 0 8px 0 12px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }
}

