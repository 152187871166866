
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.upload-area {
  .file-info {
    height: 56px;
    background-color: $white;
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    position: relative;

    .file-icon {
      font-size: 24px;
      width: 24px;
      margin-right: 24px;
    }

    .progress {
      height: 100%;
      background-color: $light-gray;
      width: var(--progress);
      transition: width .1s;
    }

    .content {
      position: absolute;
      height: 54px;
      padding: 0 12px;
      font-size: 14px;
      overflow: hidden;

      .info {
        overflow: hidden;

        .name {
          font-family: $secondary-font;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .action {
        width: 24px;
        margin-left: 12px;
        font-size: 16px;

        .remove-button {
          color: $dark-gray;

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }

  .file-selection {
    border: 1px dashed $light-orange-2;
    border-radius: 4px;
    padding: 16px;

    &.error {
      border-color: $error;
    }

    .icon {
      font-size: 20px;
      color: $orange;
    }

    * + * {
      margin-top: 8px;
    }
  }

  .errors {
    color: $error;

    .error{
      display: block;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
    }
  }
}

