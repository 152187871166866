
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.new-maintenance-package {
  padding-bottom: 24px;
}

.btn {
  margin-bottom: 12px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.fields-content {
  margin-bottom: 24px;
}

