
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.tab-content {
  line-height: 58px;
  margin-bottom: 10px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;

  .tab {
    $border-width: 4px;

    position: relative;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 16px;
    color: $gray-4;
    border: $border-width solid transparent;
    padding: 16px 0;
    cursor: pointer;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      left: -$border-width;
      bottom: -$border-width;
      width: calc(100% + #{$border-width * 2});
      display: block;
      height: $border-width;
      border-radius: 4px 4px 0 0;
    }

    &:hover {
      color: $primary;
    }

    &.router-link-active,
    &.active {
      color: $primary;

      &::after {
        background-color: $primary;
      }
    }
  }

  .tab + .tab {
    margin-left: 32px;
  }
}

.header {
  padding-top: 14px;
  margin-bottom: 30px;
}

.title {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
  margin-right: 8px;
}

.route-switch-message {
  margin-bottom: 20px;
}

