
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


// .supplier-hierarchy-icon {}

.icon {
  color: $gray-3;
  margin-right: 4px;
  font-size: 16px;
}

