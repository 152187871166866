
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.edit-vehicle-model-group {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title {
      font-size: 30px;
      font-weight: 500;
      font-family: $secondary-font;
    }

    .tag {
      margin-left: 8px;
    }
  }

  .fields {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
  }
}

