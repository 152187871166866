
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-available-date {
  font-family: $secondary-font;
  font-weight: 400;

  .header {
    .header-icon {
      color: $purple;
      margin-right: 8px;
    }

    .header-label {
      font-size: 14px;
      line-height: 17px;
      color: $gray-3;
    }
  }

  .weekday-and-time {
    .weekday,
    .datetime {
      display: block;
      margin-top: 4px;
      font-size: 16px;
      line-height: 19px;
      color: $dark-gray;
    }
  }
}

