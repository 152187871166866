
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.results-indicator {
  font-size: 14px;
  font-weight: 300;
  color: $gray-3;
}

.loading {
  width: 200px
}

