
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.service-order-execution-modal {
  .section {
    padding: 16px 24px;
  }

  .field {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .description {
    ::v-deep .input-inner {
      height: 110px;
    }
  }
  .text-form-campo{
    ::v-deep .input-inner {
      height: 110px;
    }
  }
}

.includes-vehicle-withdrawal {
  ::v-deep .checkbox-label {
    font-weight: 400!important;
    line-height: 1.5;
  }
}

.border {
  border-top: 1px solid $light-gray-3;
}

