
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        
@import "assets/styles/tooltip";
@import "assets/styles/utils";
@import "assets/styles/table";
@import "assets/styles/flex";
@import "assets/styles/google-autocomplete";
@import "assets/styles/flatpickr";
@import "assets/styles/router-tab";
@import "assets/styles/movida-common";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  outline: 0;
}

body {
  color: $text;
  background-color: white;
  margin: 0;
  font-family: $primary-font;
  font-weight: 300;
}

.material-icons-outlined {
  vertical-align: middle;
  line-height: unset;
}

a {
  text-decoration: none;
  color: $orange;
  cursor: pointer;

  &:hover {
    color: $dark-orange-2;
  }
}

h1 {
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}

h2 {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

h1, h2, h3 {
  color: $dark-gray;
}

strong {
  font-weight: 500;
}

.center {
  text-align: center;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex,
.inline-flex {
  &.column-direction {
    flex-direction: column;
  }

  & > .grow {
    flex-grow: 1;
  }

  &.vertical-center {
    align-items: center;
  }

  &.vertical-bottom {
    align-items: flex-end;
  }

  &.vertical-baseline {
    align-items: baseline;
  }

  &.vertical-start {
    align-items: flex-start;
  }

  &.center {
    justify-content: center;
    text-align: initial;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  & > .no-shrink {
    flex-shrink: 0;
  }
}

.grid {
  display: grid;

  &.vertical-center {
    align-items: center;
  }

  &.vertical-bottom {
    align-items: flex-end;
  }

  &.vertical-baseline {
    align-items: baseline;
  }

  &.vertical-start {
    align-items: flex-start;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.global-empty-color {
  color: $dark-gray;
}

.global-empty-text {
  color: $gray;
  font-style: italic;
  font-weight: 300;
}

.g-font {
  &-secondary {
    font-family: $secondary-font;
  }

  &-normal {
    font-weight: normal;
  }

  &-light {
    font-weight: 300;
  }
  &-bold {
    font-weight: bold;
  }
}
