
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.loading {
  width: 172px;
}

.app-button.action {
  color: $dark-warning;
  font-size: 14px;
}

.icon {
  margin-right: 8px;
}

// .modal-header {
//   color: $primary;
//   margin-top: 0;
//   padding: 24px;
//   border-bottom: 1px solid $light-gray-3;
// }

.heading-container {
  color: $dark-gray;

  .heading {
    font-size: 18px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-family: $secondary-font;
  }

  .icon {
    font-size: 16px;
  }
}

.body {
  height: 100%;
  overflow: auto;

  .section + .section,
  .scheduled-block + .scheduled-block {
    border-top: 1px solid $light-gray-3;
  }

  .supplier-section,
  .scheduled-block {
    padding: 16px 24px;
  }
}

.schedule {
  height: 100%;
  overflow: auto;
}

.row + .row {
  margin-top: 16px;
}

.info:not(:last-of-type) {
  margin-right: 72px;
}

.destroy {
  font-size: 14px;
}

::v-deep .description .message {
  line-height: unset;
}

.message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action {
  overflow: hidden;
}

