// Extraido de https://github.com/Akryum/v-tooltip#sass--less
// e modificando alguns estilos especificos

.tooltip {
  display: block !important;
  z-index: 10000;
  max-width: 1200px;

  .tooltip-inner {
    background: $gray-4;
    color: $white;

    font-size: 13px;
    font-weight: 400;

    padding: 4px 12px;
    border-radius: 4px;

    // XXX: isso causa "espaço em branco" no fim do popover.
    // - vamos redefinir a regra na classe .popover-inner, mais abaixo nesse arquivo
    white-space: pre;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 6px;
    border-color: $gray-4;
    z-index: 1;
  }

  &[x-placement^="top"] {
    padding-bottom: 6px;

    .tooltip-arrow {
      border-width: 6px 6px 0 6px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: 1px; // 0 XXX: ajuste de borda
      left: calc(50% - 6px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    padding-top: 6px;

    .tooltip-arrow {
      border-width: 0 6px 6px 6px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: 1px; // 0 - XXX: ajuste de borda
      left: calc(50% - 6px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    padding-left: 6px;

    .tooltip-arrow {
      border-width: 6px 6px 6px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: 1px; // XXX: ajuste de borda
      top: calc(50% - 6px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    padding-right: 6px;

    .tooltip-arrow {
      border-width: 6px 0 6px 6px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: 1px; // XXX: ajuste de borda
      top: calc(50% - 6px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: white;

    .popover-inner {
      background: $color;
      color: $gray-4;
      padding: 0;
      border: 1px solid $light-gray-3;
      border-radius: 6px;
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);

      // XXX: removendo regra, que foi definida como "pre" na classe .tooltip-inner (mais acima
      // nesse arquivo), evitando que popovers fiquem com um "espaço em branco" no fim por
      // conta de quebra-de-linhas do "pug" (template engine)
      white-space: unset;
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}


// Tema light
.light-theme-tooltip {
  .tooltip-inner {
    background: white;
    box-shadow: 0px 4px 32px rgba(134, 134, 134, 0.31);
    color: #333333;
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    padding: 12px;
    text-align: center;
  }

  .tooltip-arrow {
    border-color: white;
  }
}

.small-tooltip {
  .tooltip-inner {
    max-width: 250px;
  }
}
