
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.sap-sync-button {
  height: 40px;

  .retry,
  .wait {
    border-radius: 4px;
    height: 100%;
    padding: 0 16px;
  }

  .message {
    font-family: $secondary-font;
    font-weight: 500;

    .icon {
      margin-right: 8px;
    }
  }

  .button {
    .icon {
      margin-left: 8px;
    }
  }

  .retry {
    background-color: $light-warning;
  }

  .wait {
    background-color: $light-gray-2;
  }
}

