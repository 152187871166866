
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.attachment-thumbnail {
  background-color: $light-gray-4;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  &.selected {
    border: 2px solid $orange;
  }

  .spin {
    animation: fa-spin 2s infinite linear;
  }

  .image-preview {
    width: 72px;
    height: 72px;
    object-fit: cover;
  }

  .file-icon {
    color: var(--icon-color)
  }
}

