
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-family: $secondary-font;
  color: $dark-gray;
}

.icon {
  margin-right: 4px;
}

