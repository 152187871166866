
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.product-row {
  padding: 12px 0;
  min-height: 70px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
}

.product-row + .product-row {
  border-top: 1px solid transparent;
}

.icon-column {
  min-width: 72px;
}

.product-name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;

  &.link {
    &:hover {
      color: $primary;
    }
  }
}

.id-tag {
  margin-right: 8px;
}

.brand {
  font-size: 14px;
  font-weight: normal;
  color: $dark-gray;
}

.price-column {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: normal;
  color: $dark-gray;
  height: 100%;

  padding-right: 24px;

  .target-icon {
    font-size: 18px;
    margin-right: 8px;
    color: $success;
  }
}

.content-column {
  padding-right: 12px;

  .discarded-icon {
    margin-right: 4px;
  }
}

