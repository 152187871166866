
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.day-row {
  padding: 8px;
  min-height: 60px;
  border: 1px solid $light-gray-3;
  border-bottom-width: 0px;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-width: 1px;
  }
}

.day-label {
  min-width: 124px;
}

.business-hour {
  min-width: 191px;
}
.button-icon {
  font-size: 20px;
}

.day-checkbox {
  margin-right: 8px;
}

.business-hour + .business-hour {
  margin-left: 24px;
}

.business-hour + .add-btn {
  margin-left: 24px;
}

.btn-label {
  display: block;
  margin-left: 4px;
}

