
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.form-fields {
  .icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  .clear {
    color: $gray-2;
  }

  .row-icon {
    margin-top: 32px;
  }

  .field + .field {
    margin-top: 24px;
  }

  .action-icons {
    margin-left: 16px;
  }

  .row {
    margin-right: 78px;

    &:first-of-type {
      .action-icons {
        margin-top: 25px;
      }
    }
  }

  .row + .row {
    margin-top: 12px;
  }

  .section {
    padding: 24px 34px;

    &:not(:last-of-type) {
      padding-bottom: 0px;
    }

    + .section {
      margin-top:24px;
      border-top: 1px solid $light-gray-3;
    }
  }
}

