
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


.supplier-service-score-settings {
  .header {
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 20px;
      font-weight: 500;
    }

    .actions {
      .icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }

  .content {
    .info {
      ::v-deep .movida-common__info-label-loading {
        width: 300px;
      }

      + .info {
        margin-top: 16px;
      }
    }
  }
}

