
          @import "utils.vue/dist/assets/styles/index.scss";
          @import "movida-common.vue/dist/assets/styles/variables.scss";
          @import "@/assets/styles/variables.scss";
        


$height: 40px;

.integer-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $dark-gray;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .input-wrapper {
    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .controls {
      position: absolute;
      top: 8px;
      right: 12px;

      &:not(.disabled):not(.loading) {
        &:hover + .input-inner {
          border-color: $orange;
        }

        &:focus + .input-inner {
          outline: none;
          border-color: $orange;
          border-width: 2px;
          padding: 0 calc(var(--controls-width) + var(--prefix-width) + 12px) 0 12px;
        }
      }

      &.disabled {
        cursor: not-allowed;

        .control {
          cursor: not-allowed;
          background-color: $light-gray-4;
        }
      }

      &.loading {
        cursor: progress;

        .control {
          cursor: progress;
          background-color: $light-gray-4;
        }
      }

      .control {
        width: 32px;
        height: 24px;
        background-color: $light-orange;
        border-radius: 4px;
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        user-select: none;

        & + .control {
          margin-left: 8px;
        }
      }
    }

    .input-inner {
      font-family: $primary-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: 4px;
      border: 1px solid $gray;
      color: $gray-3;
      display: inline-block;
      font-size: inherit;
      height: $height;
      line-height: $height;
      outline: none;
      padding: 1px calc(var(--controls-width) + var(--prefix-width) + 13px) 1px 13px;
      width: 100%;

      text-align: right;

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
        padding: 0 calc(var(--controls-width) + var(--prefix-width) + 12px) 0 12px;
      }

      &::placeholder {
        font-weight: 300;
        color: $gray-2;
        opacity: 1;
      }

      &.disabled {
        background-color: $light-gray-3;
        border-color: $light-gray-4;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $light-gray-3;
        border-color: $light-gray-4;
        color: $gray-3;
        cursor: progress;
      }

      &.error {
        border-color: $red;
      }
    }

    .suffix {
      position: absolute;
      font-size: 14px;
      font-weight: normal;
      color: $gray-3;
      top: 12px;
      right: calc(var(--controls-width) + 12px);
      display: block;
      user-select: none;
      pointer-events: none;
    }

    .icon {
      position: absolute;
      font-size: 16px;
      top: calc(#{$height} / 2);
      transform: translateY(-50%);
      color: $gray-4;

      &.right {
        right: 12px;
      }

      &.left {
        left: 12px;
      }

      &.clickable {
        cursor: pointer;
      }
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;

    text-align: right;
  }
}

